/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable operator-linebreak */
import nanoid from 'nanoid';
import React from 'react';
import PropTypes from 'prop-types';
import MiniSpinner from '../misc/MiniSpinner';

/**
 *
 * @param {Component} Icon: icon to be displayed on the button
 * @param {string} text: text to display on the button
 * @param {string} textColor: color of the text (overrides all the default colors in all versions!)
 * @param {string} textColorHover: color of the text when hovered (overrides all the default colors in all versions!)
 * @param {string} size (xs, sm, md, lg, xl)
 * @param {formatting} pass css utility classes; CAUTION: the spinner uses bg-inherit, so if it shows up on top of text, set bg- in 'formatting' prop
 *
 * @returns React component
 */
export default function Button({ text, textColor, textColorHover, Icon, onClick, size, withAccent, noFrame, noFrameNoArrow, enabled, spinnerOn, onDarkBackground, formatting, type, id, name }) {
  const renderFrame = noFrame || noFrameNoArrow;

  let sizeUtility;
  switch (size) {
    case 'lg':
      sizeUtility = renderFrame ? ' py-2' : 'px-3 py-2';
      break;
    case 'xl':
      sizeUtility = renderFrame ? 'py-2.5' : 'px-3.5 py-2.5';
      break;
    case 'sm':
      sizeUtility = renderFrame ? ' py-1' : 'px-2 py-1';
      break;
    case 'xs':
      sizeUtility = renderFrame ? ' py-1' : 'px-2 py-1';
      break;
    default: // 'md'
      sizeUtility = renderFrame ? 'py-1.5' : 'px-3 py-1.5';
  }

  let format;
  let formatSpinnerOverlay;
  // return blue clickable link
  if (withAccent && renderFrame) {
    format = `${textColor || 'text-brandBlue-500'} hover:${
      textColorHover || 'text-brandBlue-600'
    } focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brandBlue-500`;
    formatSpinnerOverlay = 'text-brandBlue-500';
  }
  // return gray clickable link
  if (!withAccent && renderFrame) {
    format = `${
      onDarkBackground ? `${textColor || 'text-white'} hover:${textColorHover || 'text-gray-50'}` : `${textColor || 'text-gray-800'} hover:${textColorHover || 'text-gray-900'}`
    } focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-800`;
  }

  // return blue background
  if (withAccent && !renderFrame) {
    format = `bg-brandBlue-500 ${
      textColor || 'text-white'
    } shadow-sm hover:bg-brandBlue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brandBlue-500`;
  }

  // return white button with gray frame (not used in the new tailwind ui version anymore)
  if (!withAccent && !renderFrame) {
    format = `${
      onDarkBackground ? `${textColor || 'text-white'} hover:${textColorHover || 'text-gray-50'}` : `${textColor || 'text-gray-800'} hover:${textColorHover || 'text-gray-900'}`
    } bg-white shadow-sm hover:bg-gray-50 ring-1 ring-inset ring-gray-300 `;
  }

  const calculatedText = noFrame && !noFrameNoArrow ? `${text}→` : text;

  return (
    <button
      type={type === 'submit' ? 'submit' : 'button'}
      disabled={!enabled}
      {...(id && { id })}
      {...(name && { name })}
      className={`relative rounded-md ${sizeUtility} text-xs xs:text-sm font-semibold ${
        withAccent ? `${textColor || 'text-white'}` : `${textColor || 'text-gray-700'}`
      } ${format} flex items-center justify-center ${formatting}`}
      onClick={onClick}
    >
      {/* when spinnerOn is true, the div should completely cover icon and text, preventing it from changing shape  */}
      {spinnerOn && (
        <div className="absolute inset-0 bg-inherit opacity-100 rounded-md flex items-center justify-center">
          <MiniSpinner className={`${size === 'xl' ? 'w-5 h-5' : 'w-4 h-4'} ${withAccent ? 'text-white' : 'text-gray-400'} animate-spin`} />
        </div>
      )}
      {Icon && <Icon className="text-inherit h-5 w-5" />}
      {text && <span className={`text-inherit ${Icon ? 'ml-1.5' : ''} font-semibold leading-4`}>{calculatedText}</span>}
    </button>
  );
}
Button.propTypes = {
  text: PropTypes.string,
  textColor: PropTypes.string,
  textColorHover: PropTypes.string,
  Icon: PropTypes.func,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'xs']),
  withAccent: PropTypes.bool,
  enabled: PropTypes.bool,
  spinnerOn: PropTypes.bool,
  noFrame: PropTypes.bool,
  noFrameNoArrow: PropTypes.bool,
  onDarkBackground: PropTypes.bool,
  formatting: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']),
  id: PropTypes.string,
  name: PropTypes.string,
};
Button.defaultProps = {
  text: '',
  textColor: undefined,
  textColorHover: undefined,
  onClick: () => {},
  Icon: undefined,
  size: 'lg',
  withAccent: false,
  enabled: true,
  spinnerOn: false,
  noFrame: false,
  noFrameNoArrow: false,
  onDarkBackground: false,
  formatting: '',
  type: 'button',
  id: undefined,
  name: undefined,
};
