/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-param-reassign */
import dayjs from 'dayjs';
import { createSelector } from '@reduxjs/toolkit';
import { arrayToObject, objectToArray } from '../../../misc/arrayHelpers';
import processQuotesPerAssetId from './processQuotes';
// import { getQuotes } from '../unlistedMock';

const duration = require('dayjs/plugin/duration');
const utc = require('dayjs/plugin/utc');

dayjs.extend(duration);
dayjs.extend(utc);

const debugLevel = 0;

function compareObjects(obj1, obj2, parentKey = '') {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  const allKeys = new Set([...keys1, ...keys2]);

  allKeys.forEach((key) => {
    const qualifiedKey = parentKey ? `${parentKey}.${key}` : key;
    const val1 = obj1[key];
    const val2 = obj2[key];

    if (typeof val1 === 'object' && val1 !== null && typeof val2 === 'object' && val2 !== null) {
      // Recursive call for nested objects
      compareObjects(val1, val2, qualifiedKey);
    } else if (val1 !== val2) {
      console.log(`Difference at "${qualifiedKey}":`, val1, 'vs', val2);
    }
  });
}

const logSelectorChanges = (name, previousInputs, currentInputs) => {
  previousInputs.forEach((previousInput, index) => {
    const currentInput = currentInputs[index];

    if (previousInput !== currentInput) {
      console.log(`${name} > Input selector ${index + 1} has changed.`);
      console.log('Diffs:', compareObjects(previousInput, currentInput));
    }
  });
};

// GLOBAL REUSABLE SELECTORS
// all objects in this module can directly access state without having to use useSelector

// eslint-disable-next-line import/prefer-default-export

export function getMonthdates(start, end, interval = 1) {
  // returns an array with 1st days of every month at 0:00 UTC between start and end

  // if interval > 1, reset to beginning of next quarter, half-year or year
  start = dayjs.utc(start).startOf('month').valueOf();
  while (dayjs.utc(start).month() % interval !== 0) {
    start = dayjs.utc(start).add(1, 'month').valueOf();
  }

  const output = [];
  while (+start <= +end) {
    output.push(dayjs.utc(start).valueOf());
    start = dayjs.utc(start).add(interval, 'month');
  }
  return output;
}
// for usage below
// export function getIsolatedAttributePerProjectId(state, projectId) {
//   // null / undefined / false return false, true returns true
//   return !!(projects(state).find((project) => project.id === projectId)?.settings?.isIsolated);
// }
export const getIsolatedAttributePerProjectId = createSelector(
  (state) => state.projects,
  (_projects) => (projectId) => !!_projects.find((project) => project.id === projectId)?.settings?.isIsolated,
);

// for usage in globalTransactionView selectors (to filter out isolated transactions from global view)
export const eliminateIsolatedTransactions = createSelector(
  getIsolatedAttributePerProjectId,
  ($getIsolatedAttributePerProjectId) => ($arr) => ($arr || []).filter((item) => !$getIsolatedAttributePerProjectId(item.projectId)),
);

// let previousInputs = null;

// export const eliminateIsolatedTransactions = (state) => {
//   const currentInputs = eliminateIsolatedTransactionsSource.dependencies.map((selector) => selector(state));

//   if (previousInputs) {
//     logSelectorChanges('eliminateIsolatedTransactions', previousInputs, currentInputs);
//   }

//   previousInputs = currentInputs;

//   return eliminateIsolatedTransactionsSource(state);
// };

// CAUTION: this is a complex selector which may cause undesired re-renders
// read: https://monestry.atlassian.net/wiki/spaces/TECH/pages/62521455/React#Complex-selectors-cause-unnecessary-re-renders
export const globalAccountsView = createSelector(
  // keeping those separate and granular, as we do not want this to recompute when transactions & co. are updated
  (state) => state.data.deposits.accounts,
  (state) => state.data.stocks.accounts,
  (state) => state.data.realEstate.accounts,
  (state) => state.data.loans.accounts,
  (state) => state.data.pension.accounts,
  (state) => state.data.objectsOfValue.accounts,
  (state) => state.data.metals.accounts,
  (state) => state.data.unlistedShares.accounts,
  function globalAccountsViewCallback(deposits, stocks, realEstate, loans, pension, oov, metals, unlisted) {
    return deposits.concat(stocks, realEstate, loans, pension, oov, metals, unlisted);
  },
);

// let previousInputsACC = null;

// export const globalAccountsView = (state) => {
//   const currentInputs = globalAccountsViewSource.dependencies.map((selector) => selector(state));

//   if (previousInputs) {
//     logSelectorChanges('globalAccountsView', previousInputsACC, currentInputs);
//   }

//   previousInputsACC = currentInputs;

//   return globalAccountsViewSource(state);
// };

export function currentSliderMax(state) {
  return state.user.profile?.settings?.sliderEndDate;
}

export function unpackRecurringTransactionsCore(inflationRate, baseDate, sliderMax, transaction, projectMode = false) {
  // if project mode is off, and the transaction is already beyond the sliderDate, return [], as there is no need to unpack it
  if (!projectMode && transaction.date > baseDate) return [];

  const tags = typeof transaction.tags === 'string' ? JSON.parse(transaction.tags) : transaction.tags;

  const recurring = tags?.recurring;
  if (!recurring?.activated) return transaction;
  const recurringPeriod = recurring.periodType.id;

  let annualIndexingRate = 1; // no indexing as default
  const indexing = tags?.indexing;
  // calculate annual indexing rate for indexed transactions
  let indexingRate = 0; // indexingRate as the user inputs it in %, like 3.04%
  if (indexing?.activated) {
    // get indexing mode from indexed object
    if (indexing.mode === 'indexedByInflation') indexingRate = inflationRate;
    if (indexing.mode === 'indexedByCustomRate') indexingRate = indexing.customRateInput;
    // vv validate the correctness of usage of recurring.numberOfPeriods
    annualIndexingRate = 1 + (recurringPeriod === 'month' ? ((indexingRate / 12) * (recurring.numberOfPeriods || 1)) / 100 : (indexingRate * (recurring.numberOfPeriods || 1)) / 100);
  }

  // if it is recurring, generate the transactions and return them as array of arrays (to be flattened later on)
  let maxDate = dayjs.utc('2100-01-01').startOf('month').valueOf();
  let maxIndex = 10000;
  if (recurring.end === 'recurringNever') maxDate = sliderMax;
  if (recurring.end === 'recurringByDate') maxDate = recurring.endAfterDate;
  if (recurring.end === 'recurringByOccurrence') maxIndex = Number(recurring.endAfterOccurrences);

  const unpackedTransactions = [transaction]; // push original transaction first
  // if the recurring mode is 'year', the first transaction is in 12 months; otherwise in 1 month
  let date = dayjs
    .utc(transaction.date)
    .startOf('month')
    .add(recurringPeriod === 'month' ? 1 * (recurring.numberOfPeriods || 1) : 12 * (recurring.numberOfPeriods || 1), 'month')
    .valueOf();
  // generate transactions until the first limit has been hit
  let counter = 1;
  while (date < maxDate && (projectMode ? true : date < baseDate) && unpackedTransactions.length <= maxIndex) {
    const newTransaction = {
      ...transaction,
      id: transaction.id + date.valueOf(), // TODO: this is not a good idea (id should be 21 chars long), but it works for now, within this component
      date: date.valueOf(),
      recurringParentId: transaction.id, // used to determine (1) that this is a recurring child transaction and (2) which parent it belongs to
      projectId: transaction.projectId,
      linkedTransactions: [...transaction.linkedTransactions, { id: transaction.id, category: 'deposits', tags: { type: 'recurringParent' } }],
      tags: JSON.stringify({ ...tags, recurring: { activated: false, type: 'recurringChild' } }),
      sortingOrderWithinMonth: transaction.sortingOrderWithinMonth ? transaction.sortingOrderWithinMonth : 0,
    };
    // if deposits increase price, increase their quantity
    if (transaction.category === 'deposits') {
      newTransaction.amount *= annualIndexingRate ** counter;
      newTransaction.accountCurrencyAmount *= annualIndexingRate ** counter;
      newTransaction.fxAmount *= annualIndexingRate ** counter;
      newTransaction.quantity *= annualIndexingRate ** counter;
    } else {
      // increase their prices
      // ↓↓ to maintain backward compatiblity after standardising fields
      if (transaction.category === 'realEstate') newTransaction.quote *= annualIndexingRate ** counter; // amount is 1 or 0
      // ↓↓ to maintain backward compatiblity after standardising fields
      if (transaction.category === 'stocks') {
        newTransaction.rebasedPrice *= annualIndexingRate ** counter;
        newTransaction.rebasedOriginalPrice *= annualIndexingRate ** counter;
        newTransaction.transactionPrice *= annualIndexingRate ** counter;
        newTransaction.transactionOriginalPrice *= annualIndexingRate ** counter;
      }
      newTransaction.upac *= annualIndexingRate ** counter; // FIX 240516 added as it seemed to be missing
      newTransaction.upbc *= annualIndexingRate ** counter;
      newTransaction.uptc *= annualIndexingRate ** counter;
    }

    unpackedTransactions.push(newTransaction);
    // increase the date of next transaction by 1 month or 12 months
    date = dayjs
      .utc(date)
      .startOf('month')
      .add(recurringPeriod === 'month' ? 1 * (recurring.numberOfPeriods || 1) : 12 * (recurring.numberOfPeriods || 1), 'month')
      .valueOf();
    counter += 1;
  }
  return unpackedTransactions;
}

// designed to work in a map function
// requires state as context argument in the map function ('this')
// the outer function "wraps" the inner function and injects selectors as arguments (while memoizing them)
// the outer function returns the inner function, which accepts the transaction as argument (and is used in array.map as callback)
export const unpackRecurringTransactions = createSelector(
  (state) => state.user?.profile.settings.inflationRate,
  (state) => state.simulation?.baseDate,
  currentSliderMax,
  function unpackRecurringTransactionCallbackOuter(inflationRate, baseDate, sliderMax) {
    return function unpackRecurringTransactionCallbackInner(transaction, projectMode = false) {
      return unpackRecurringTransactionsCore(inflationRate, baseDate, sliderMax, transaction, projectMode);
    };
  },
);

export const testGlobalView = createSelector(
  (state) => Object.keys(state.data),
  (state) => state.data,
  (state, withIsolatedTransactions = false) => withIsolatedTransactions,
  eliminateIsolatedTransactions,
  unpackRecurringTransactions,
  (categories, data, $withIsolatedTransactions, $eliminateIsolatedTransactions, $unpackRecurringTransactions) => {
    const transactions = categories
      .map((category) => ($withIsolatedTransactions ? data[category].simulatedTransactions : $eliminateIsolatedTransactions(data[category].simulatedTransactions) || [])
        .map((transaction) => ({ ...transaction, category }))
      // unpack recurring transactions by creating transactions for every recurring transaction (never = until the end of slider)
        .flatMap((t) => $unpackRecurringTransactions))
      .flat();
    return transactions;
  },
);

// let previousInputs2 = null;

// export const globalTransactionViewNoTimeLimit = (state) => {
//   const currentInputs = globalTransactionViewNoTimeLimitSource.dependencies.map((selector) => selector(state));

//   // if (previousInputs2) {
//   //   logSelectorChanges('globalTransactionViewNoTimeLimit', previousInputs2, currentInputs);
//   // }

//   // previousInputs2 = currentInputs;

//   return globalTransactionViewNoTimeLimitSource(state);
// };

// in dashboard mode: use today as the underlying date; in project mode: use the sliderDate ("baseDate")
// in projects mode take the distance between today and baseline and apply it to slider date
// so that the selectors in project mode return the correct data
// if the location is a different one, return baseline
// returns: { baseline, referenceBaseline }
export const globalBaselineView = createSelector(
  (state) => state.user?.profile?.settings,
  (state) => state.simulation,
  function globalBaselineViewCallback(settings, simulation) {
    const baselinePeriodLengthInYears = dayjs
      .utc()
      .startOf('day')
      .diff(dayjs.utc(settings?.baselineDate || 0), 'year');
    const namedBaseline = settings?.namedBaseline;

    if (simulation?.dashboardMode === 'projects') {
      // check if the current baseline setting is a "namedBaseline" setting
      // if so, keep the original intention of the setting
      // values tested here are initially defined in elements/SumTileExpandable.jsx > BaselineSelector
      // sameTimeLastYear | currentYearBegin | previousYearBegin
      const sliderDate = dayjs.utc(simulation.baseDate).startOf('day');
      const current = sliderDate.valueOf();

      if (namedBaseline === 'sameTimeLastYear') {
        return {
          baseline: sliderDate.subtract(1, 'year').startOf('day').valueOf(),
          referenceBaseline: sliderDate.subtract(2, 'year').startOf('day').valueOf(),
          current,
        };
      }

      if (namedBaseline === 'currentYearBegin') {
        const baseline = sliderDate.startOf('year').valueOf();
        const referenceBaseline = dayjs.utc(baseline).subtract(baselinePeriodLengthInYears, 'year').valueOf();

        return { baseline, referenceBaseline, current };
      }

      if (namedBaseline === 'previousYearBegin') {
        const baseline = sliderDate.subtract(1, 'year').startOf('year').valueOf();
        const referenceBaseline = dayjs.utc(baseline).subtract(baselinePeriodLengthInYears, 'year').valueOf();

        return { baseline, referenceBaseline, current };
      }

      return {
        baseline: sliderDate.subtract(baselinePeriodLengthInYears, 'year').valueOf(),
        referenceBaseline: sliderDate.subtract(2 * baselinePeriodLengthInYears, 'year').valueOf(),
        current,
      };
    }
    // under normal circumstances, return baseline as it is and subtract baselinePeriodLength from it to arrive at referenceBaseline
    return {
      baseline: settings?.baselineDate?.valueOf(),
      // just to handle the empty state put baselinePeriodLength in there
      referenceBaseline: dayjs.utc(settings?.baselineDate).subtract(baselinePeriodLengthInYears, 'year').valueOf(),
      current: dayjs.utc().startOf('day').valueOf(),
    };
  },
);

export const baseline = (state) => globalBaselineView(state).baseline;
export const referenceBaseline = (state) => globalBaselineView(state).referenceBaseline;

// used to identify category based on assetId
// used in globalQuotesView as it does not require access to quotes (and assetBalanaces does) -- risk of circular dependency
// returns an array with all existing category-accountId-assetId combinations { category, accountId, assetId }
export const assetHierarchyDnorm = createSelector(
  (state) => state.data,
  function assetHierarchyDnormCallback(data) {
    // we cannot use the allTransactions view here, because it uses simulatedDividendTransactions, which uses output of globalQuotesView (because dividends are a % of price)
    // we do not risk much, because transactions added before allTransactions do not have any new assetIds
    const transactions = [];
    Object.keys(data).forEach((category) => {
      transactions.push(...(data[category].transactions || []));
      transactions.push(...(data[category].simulatedTransactions || []));
    });
    const result = transactions.reduce((acc, transaction) => {
      const { category, accountId, assetId } = transaction;
      if (!acc[category]) acc[category] = {};
      if (!acc[category][accountId]) acc[category][accountId] = [assetId];
      else if (!acc[category][accountId].includes(assetId)) acc[category][accountId].push(assetId);
      return acc;
    }, {});
    const output = [];

    for (const category in result) {
      if (Object.prototype.hasOwnProperty.call(result, category)) {
        const accounts = result[category];
        for (const accountId in accounts) {
          if (Object.prototype.hasOwnProperty.call(accounts, accountId)) {
            const assetIds = accounts[accountId];
            assetIds.forEach((assetId) => output.push({ category, accountId, assetId }));
          }
        }
      }
    }
    return output;
  },
);

// returns category-level settings from all categories in one place (as an object [category]: {settings})
export const settingsByCategoriesView = createSelector(
  (state) => state.data,
  (data) => {
    const categories = Object.keys(data || []);
    const settings = categories.map((category) => ({ [category]: data[category].settings })).reduce((acc, val) => ({ ...acc, ...val }), {});
    return settings;
  },
);

// this is used everywhere in the tool (KPIs, assets, reports, not only for projects)
// i.e. if there is a project that has here an asset with a growth rate, is it supposed to be used everywhere or only for project transactions?
// should that not work like project quotes? how do project quotes work?

// returns growthRate defined for a given asset or account (fallback - category-level) as a fraction
export function getMonthlyGrowthRateForAsset(assetId, thisCategorySettings, accounts, $projects, isolatedProjectId = undefined) {
  let growthRate; // default value

  // for isolated project check if there are project-specific growth rates
  if (isolatedProjectId) {
    const isolatedProject = $projects.find((project) => project.id === isolatedProjectId);
    growthRate = isolatedProject?.settings?.growthRates.find((x) => x.symbol.id === assetId)?.value;
    if (!growthRate && growthRate !== 0) {
      // accept 0, but not null, undefined
      // if not existing, return category-level settings
      growthRate = thisCategorySettings?.growthRate;
    }
    return growthRate / 12 / 100 || 0;
  }

  // first, is this an asset which is an account? (like realEstate)? then return the account growth rate or category-level rate as fallback
  const isAssetAnAccount = !!accounts.find((account) => account.id === assetId); // if this asset is an account, it is like realEstate (one asset per account)
  if (isAssetAnAccount) {
    growthRate = accounts.find((account) => account.id === assetId)?.tags?.growthRate; // so get growthRate from account
    if (!growthRate && growthRate !== 0) {
      // accept 0, but not null, undefined
      // if not existing, return category-level settings
      growthRate = thisCategorySettings?.growthRate;
    }
  } else {
    // if asset is not account, then this is a category with assets, so get the asset growth rate or category asset growth rate as fallback
    const assetLevelRate = thisCategorySettings?.growthRates?.find((x) => x.symbol.id === assetId)?.value || thisCategorySettings?.growthRate || 0;
    growthRate = assetLevelRate;
  }
  return Number(growthRate) / 12 / 100 || 0;
}

// helper function used to prepare global and isolated quotes before applying project-mode calculations and returning them as globalQuotesArray
// used for:
// - handling specialQuotes for pensions (get the right specialQuote out of quote.specialQuotes based on the account setting)
//   NOTE: this can only work if assetId === accountId (currently enabled for pensions only)
//   (if there are specialQuotes, get useSpecialQuotes from the account that the quote belongs to and use that quote in the root quote object)
// - applying spread "in minus" as per account settings to the quotes of metals
function prepareQuotes(quotesArray, accounts, keys) {
  const specialQuotesAccounts = accounts.filter((a) => a.category === 'pension');
  const metalsAccounts = accounts.filter((a) => a.category === 'metals');

  if (process.env.REACT_APP_SELECTOR_DEBUG) console.log(`prepareQuotes on ${quotesArray.length} quotes`);

  if (!quotesArray || quotesArray.length === 0) return {};

  return arrayToObject(
    quotesArray.map((q) => {
      const pensionAccount = specialQuotesAccounts.find((a) => a.id === q.assetId);
      const metalsAccount = metalsAccounts.find((a) => a.id === q.assetId);

      if (pensionAccount) {
        try {
          const { useSpecialQuote } = pensionAccount; // this has the quote type that we are to use for this product
          const specialQuoteObject = (q.specialQuotes || []).find((sq) => sq.type === useSpecialQuote);
          return { ...q, quote: specialQuoteObject?.quote || 0, quoteBaseCurrency: specialQuoteObject?.quoteBaseCurrency || 0 };
        } catch (err) {
          console.error('Error in prepareQuotes', err);
          console.error(`Parameters passed are q: ${JSON.stringify(q, null, 2)}, getPensionAccount: ${JSON.stringify(pensionAccount, null, 2)}`);
          return q;
        }
      }

      if (metalsAccount) {
        try {
          const { spread } = metalsAccount.tags;
          const calculatedSpread = 1 - (spread || 0) / 100;
          return { ...q, quote: q.quote * calculatedSpread, quoteBaseCurrency: q.quoteBaseCurrency * calculatedSpread };
        } catch (err) {
          console.error('Error in prepareQuotes', err);
          console.error(`Parameters passed are q: ${JSON.stringify(q, null, 2)}, getMetalsAccount: ${JSON.stringify(metalsAccount, null, 2)}`);
          return q;
        }
      }

      // if this is not a special case, just return it as is
      return q;
    }),
    keys,
  );
}

// ---------------------------------------------------
// FUNCTION CALCULATING GLOBAL_QUOTES_VIEW
// ---------------------------------------------------

// used to run all quotes calculations within globalQuotesViewGeneric and globalQuotesViewIsolatedProjects
export function runQuotesCalculations(sliderEnd, $dashboardMode, selectQuotes, accounts, $baselineView, $projects, $assetHierarchy, $settingsByCategories, $isolatedProjectId = undefined) {
  // if (debugLevel > 2) console.log('assetHierarchy', $assetHierarchy);
  const timer = dayjs().valueOf();
  if (process.env.REACT_APP_SELECTOR_DEBUG) console.log('runQuotesCalculations start');

  // selectQuotes is { global: [], isolated: [] }

  // handle specialQuotes for pensions and spread for metals
  const quotes = {
    global: prepareQuotes(selectQuotes.global, accounts, ['assetId', 'date']),
    isolated: prepareQuotes(selectQuotes.isolated, accounts, ['projectId', 'assetId', 'date']),
  };

  if ($dashboardMode === 'projects') {
    // in project mode generate simulated future quotes for each asset for all months between today and slider end date
    // prepare input quotes, get growthRate for each asset, and run processQuotes (smoothing wherever applicable + create future simulated quotes with applied growth rate) for each asset
    // processQuotes needs a current quote and all manual quotes for the future

    // select base quotes (either global or isolated for one project)
    console.log('DEBUG', quotes, $isolatedProjectId, quotes.isolated);
    const isolatedQuotesForProjectId = quotes.isolated[$isolatedProjectId] || {};
    const simulated = Object.entries(($isolatedProjectId ? isolatedQuotesForProjectId : quotes.global) || []).reduce((accAssets, [assetId, inputDatesAndQuotesForAssetIdObject]) => {
      // find out category of the asset (assetHierarchy is an array of all existing category-accountId-assetId)
      const category = $assetHierarchy.find((x) => x.assetId === assetId)?.category;

      // get growth rate for this asset (or category-level rate if asset-/account-level not available)
      const thisCategorySettings = $settingsByCategories?.[category] || {};
      const growthRate = getMonthlyGrowthRateForAsset(assetId, thisCategorySettings, accounts, $projects, $isolatedProjectId);

      const namedTimePeriods = ['current', 'baseline', 'referenceBaseline'];

      // convert input quotes for this assetId to an array of quote objects
      const inputQuotesArray = Object.values(inputDatesAndQuotesForAssetIdObject).filter((quoteObject) => !namedTimePeriods.includes(quoteObject.date)); // remove old named quotes from the list

      // take datesWithQuotesArray and extract only the quotes which change something (and not those which just "carry over" from previous month) -- this is required by processQuotes
      // if the first manual quote is in the future (e.g. the user does not own this stock yet), get rid of current quote by filtering out all quotes which have null as value
      // (Quotes API is always sending _most_recent_ value, event if that value is 3 years old)
      // pass all manual quotes to processQuotes, it will sort out the most recent date
      const uniqueCurrentAndFutureInputQuotesArray = inputQuotesArray.reduce((acc, quote) => {
        // if there is a quote for this quoteDate already, do not add it again
        if (acc.filter((q) => q.quoteDate === quote.quoteDate).length > 0) return acc;
        acc.push({ ...quote, date: quote.quoteDate || quote.date }); // 'date' is no longer relevant here, as we have created a list of unique quoteDates
        return acc;
      }, []);

      // if (debugLevel > 2) console.log('prep monthDates', dayjs.utc().startOf('day').valueOf(), sliderEnd);

      const months = getMonthdates(dayjs.utc().startOf('day').valueOf(), sliderEnd); // this needs to generate quotes up until sliderEnd, as this is used in ProjectDetails
      const monthsIncludingNamed = months.concat([$baselineView.referenceBaseline, $baselineView.baseline, $baselineView.current]);

      // if (debugLevel > 2) console.log('monthsIncludingNamed', monthsIncludingNamed);

      let generatedQuotesArray;
      try {
        generatedQuotesArray = processQuotesPerAssetId({
          growthRate,
          quotes: uniqueCurrentAndFutureInputQuotesArray,
          requestedDates: monthsIncludingNamed,
          dashboardMode: 'projects',
        });
        if (debugLevel > 2) console.log('generatedQuotesArray', assetId, category, 'growthRate', growthRate, 'settings', thisCategorySettings, generatedQuotesArray);
      } catch (e) {
        console.error('Error in processQuotesPerAssetId', e);
        console.error(`Parameters passed are assetId: ${assetId}, growthRate: ${growthRate}, inputQuotes: ${uniqueCurrentAndFutureInputQuotesArray}, requestedDates: ${monthsIncludingNamed}`);
        throw new Error(e);
      }

      const mergedQuotes = inputQuotesArray
        // .filter((quoteObject) => !namedTimePeriods.includes(quoteObject.date)) // remove old named quotes from the list --> FIX 240522 moved up to inputQuoteArray
        .concat(generatedQuotesArray)
        .reduce((acc, curr) => {
          if (curr.date === $baselineView.referenceBaseline) return acc.concat({ ...curr, date: 'referenceBaseline' }).concat(curr);
          if (curr.date === $baselineView.baseline) return acc.concat({ ...curr, date: 'baseline' }).concat(curr);
          if (curr.date === $baselineView.current) return acc.concat({ ...curr, date: 'current' }).concat(curr);
          return acc.concat(curr);
        }, [])
        .reduce((acc, quoteObject) => ({ ...acc, [quoteObject.date]: quoteObject }), {});

      return {
        ...accAssets,
        [assetId]: mergedQuotes,
      }; // reduce
    }, {});

    if (process.env.REACT_APP_SELECTOR_DEBUG) console.log('runQuotesCalculations finish', dayjs().valueOf() - timer);
    return simulated;
  }
  if (process.env.REACT_APP_SELECTOR_DEBUG) console.log('runQuotesCalculations finish', dayjs().valueOf() - timer);
  return quotes.global;
}

// in dashboard mode it just returns the quote.global object
// in project mode it takes each existing quote, derives its growth rate and applies it for each month of the simulation
// if isolatedProjectId is provided as second argument, it only uses isolated quotes for that particular project only
// output object is { assetId: { date: { quote, quoteBaseCurrency, currency, source, importFlag } } }
export const globalQuotesViewGeneric = createSelector(
  (state) => state.user.profile?.settings.sliderEndDate,
  (state) => state.simulation?.dashboardMode,
  (state) => state.quotes,
  globalAccountsView,
  globalBaselineView,
  (state) => state.projects,
  assetHierarchyDnorm,
  settingsByCategoriesView,
  function globalQuotesViewGenericCallback(sliderEnd, dashboardMode, quotes, accounts, baselineView, projects, assetHierarchy, settingsByCategories) {
    return runQuotesCalculations(sliderEnd, dashboardMode, quotes, accounts, baselineView, projects, assetHierarchy, settingsByCategories);
  },
);

// let previousInputs = null;

// export const globalQuotesViewGeneric = (state) => {
//   const currentInputs = globalQuotesViewGenericSource.dependencies.map((selector) => selector(state));

//   if (previousInputs) {
//     logSelectorChanges('globalQuotesView', previousInputs, currentInputs);
//   }

//   previousInputs = currentInputs;

//   return globalQuotesViewGenericSource(state);
// };

// no memoisation, as the projectId will change every time
export function globalQuotesViewIsolatedProjects(state, isolatedProjectId) {
  return runQuotesCalculations(
    state.user.profile?.settings.sliderEndDate,
    state.simulation?.dashboardMode,
    state.quotes,
    globalAccountsView(state),
    globalBaselineView(state),
    state.projects,
    assetHierarchyDnorm(state),
    settingsByCategoriesView(state),
    isolatedProjectId,
  );
}

// in order to avoid recalculating globalQuotesView when going to and back from isolated project, we need to split it into two separate paths
// if it is called without isolatedProjectId as parameter, return the memoized quotes from global quotes
// if it is called with isolatedProjectId as parameter, return the recalculated live quotes from quotes.isolated[isolatedProjectId]
export function globalQuotesView(state, isolatedProjectId) {
  // we see calls to globalQuotesView which do have a second argument, but not a projectId (probable bug);
  // to weed them out (there is performance impact), we need to check if the second argument is a nanoid string
  if (/^[A-Za-z0-9_-]{21}$/.test(isolatedProjectId)) {
    return globalQuotesViewIsolatedProjects(state, isolatedProjectId);
  }
  return globalQuotesViewGeneric(state);
}

// isolatedProjectId is optional, if provided, it only returns quotes for that particular project
export function globalQuotesArrayView(state, isolatedProjectId) {
  if (!isolatedProjectId) return objectToArray(globalQuotesViewGeneric(state), ['assetId', 'date']);
  return objectToArray(globalQuotesViewIsolatedProjects(state, isolatedProjectId), ['assetId', 'date']);
}
