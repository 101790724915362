/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { Auth } from 'aws-amplify';
import { confirmUser, resendConfirmationEmail } from '../../redux/actions/user';
import AlertModal from '../../elements/AlertModal';
import MiniSpinner from '../../misc/MiniSpinner';
import Button from '../../elements/Button';

function WelcomeMessage({ handleSubmit, localConfirmationCode, setLocalConfirmationCode, version, identityProvider, codeError, loading }) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation('site', { keyPrefix: 'register.paymentSuccessful' });

  // version determines if to show the verification code input field + instruction (for internal user sign up) or just the welcome message with the login button
  return (
    <>
      <h1 id="registrationSuccessfulTitle">
        <span className="block text-sm font-semibold uppercase tracking-wide text-brandBlue-500 sm:text-base lg:text-sm xl:text-base">{t('registrationSuccessful')}</span>
        <span className="mt-1 block text-4xl tracking-tight font-black sm:text-6xl">
          <span className="block text-gray-900">{t('welcome')}</span>
        </span>
      </h1>
      <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">{t('thanksForOpening')}</p>
      {version === 'userNotConfirmed' ? (
        <>
          <p className="mt-3 text-base text-gray-500 sm:mt-5">{t('weSentYouAnEmail')}</p>
          <p className="mt-3 text-base text-gray-500 sm:mt-5">{t('enterCode')}</p>
          <form onSubmit={handleSubmit}>
            <div className="my-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="col-span-6 md:col-span-4">
                <label htmlFor="username" className="block text-sm font-medium text-gray-500">
                  {t('verificationCode')}
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <input
                    type="number"
                    name="verificationCode"
                    id="verification_code"
                    placeholder="123456"
                    autoComplete="off"
                    onChange={(e) => setLocalConfirmationCode(e.target.value)}
                    value={localConfirmationCode}
                    className="flex-1 focus:ring-brandBlue-400 focus:border-brandBlue-400 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 placeholder:text-gray-400"
                  />
                </div>
                {codeError === 'CodeMismatchException' && <p className="mt-1 text-sm text-brandRed-500">{t('codeError')}</p>}
                {codeError && codeError !== 'CodeMismatchException' && <p className="mt-1 text-sm text-brandRed-500">{t('genericError')}</p>}
              </div>
            </div>
            <Button text={t('confirmAccount')} spinnerOn={loading} withAccent size="xl" enabled={!loading} type="submit" />
          </form>
        </>
      ) : (
        <>
          {/* amazon is called "LoginWithAmazon" */}
          <p className="mt-3 text-base text-gray-500 sm:mt-5">{t('federated.accountReady', { provider: identityProvider.replace('LoginWith', '') })}</p>
          <p className="mt-3 text-base text-gray-500 sm:mt-5 mb-5">{t('federated.addPasswordLater')}</p>
          <Button text={t('federated.proceedToDashboard')} spinnerOn={loading} withAccent size="xl" enabled={!loading} onClick={() => navigate(`/${i18n.language}/app/getready`)} />
        </>
      )}
    </>
  );
}
WelcomeMessage.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  localConfirmationCode: PropTypes.string,
  setLocalConfirmationCode: PropTypes.func.isRequired,
  version: PropTypes.string,
  identityProvider: PropTypes.string,
  codeError: PropTypes.bool,
  loading: PropTypes.bool,
};
WelcomeMessage.defaultProps = {
  localConfirmationCode: '',
  version: 'userNotConfirmed',
  identityProvider: undefined,
  codeError: false,
  loading: false,
};

function ErrorMessage() {
  const { t } = useTranslation('site', { keyPrefix: 'register.paymentSuccessful.errorMessage' });
  return (
    <>
      <h1>
        <span className="block text-sm font-semibold uppercase tracking-wide text-brandBlue-500 sm:text-base lg:text-sm xl:text-base">{t('registrationFailed')}</span>
        <span className="mt-1 block text-4xl tracking-tight font-black sm:text-6xl">
          <span className="block text-gray-900">{t('error')}</span>
        </span>
      </h1>
      <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">{t('thankYouForOpening')}</p>
      <p className="mt-3 text-base text-gray-500 sm:mt-5">{t('problemWithPayment')}</p>
    </>
  );
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

// needs to be wrapped in Elements to work (see below)
export function PaymentSuccessfulCore({ clientSecret, payLater, isLoading, setIsLoading }) {
  const { t, i18n } = useTranslation(['site']);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stripe = useStripe();

  const { userId } = useParams();

  const [localConfirmationCode, setLocalConfirmationCode] = useState(undefined);
  const [user, _] = useState(JSON.parse(localStorage.getItem('signUpObject')));
  const [displayError, setDisplayError] = useState(false);
  const [codeError, setCodeError] = useState(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [url, setUrl] = useState(null); // only initiate in LOST SESSION CASE (see below)

  const terminateWebSocketConnection = useCallback(() => setUrl(null), []);

  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(url, {
    onOpen: () => console.log('SignUp3 > websocket connection opened'),
    onMessage: (message) => {
      console.log('SignUp3 > websocket message received: ', message);
    },
    onError: (error) => console.error('SignUp3 > websocket error: ', error),
  });

  useEffect(() => {
    if (user) localStorage.removeItem('signUpObject');
  }, [user]);

  useEffect(() => {
    function handleError() {
      // set store.user.payment.status to 'error'
      dispatch({ type: 'PAYMENT_ERROR' });
      // redirect your user back to your payment page to attempt collecting
      // payment again (SignUp component will handle this)
      navigate(`/${i18n.language}/register`);
    }
    // PAY NOW CASE: IF there IS a clientSecret, then this component is loaded in the same session as the payment
    // so check the SetupIntent status with clientSecret, check Cognito user status and - if the latter is UNCONFIRMED - display confirmation code input field
    // PAY LATER CASE: IF there IS NO clientSecret, we need to figure out if this is a LOST SESSION or a PAY LATER case
    // to that end we need to look for payLater flag in the user object in the store (passed to this component from parent), which has been put there by hubListener during handling callback
    // LOST SESSION CASE: IF there IS NO clientSecret, the user has opened a new session basing on a link from the email (not implemented yet)
    // in that case find customer in Stripe and get a subscription status from Stripe by calling the backend (see the next useEffect)

    // handle PAY NOW case (user gets redirected here after payment completed with Stripe)
    if (clientSecret && stripe) {
      // stripe loads async, need to wait a while for it to do so
      stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
        // Inspect the SetupIntent `status` to indicate the status of the payment to your customer.
        //
        // Some payment methods will immediately succeed or fail upon confirmation, while others will first enter a `processing` state.
        // see: https://stripe.com/docs/payments/payment-methods#payment-notification
        switch (setupIntent.status) {
          case 'succeeded':
            setIsLoading(false);
            break;

          case 'processing':
            // do nothing, the Stripe webhook handler will receive something and act on it
            // eslint-disable-next-line no-alert
            alert('Payment processing takes unusually long. Please reach out to us for help at support@monestry.de');
            break;

          case 'requires_payment_method':
            console.log('Payment failed. Please try another payment method.');
            handleError();
            break;

          default:
            console.log('Unknown error while processing payment.');
            handleError();
            break;
        }
      });
    }
  });

  // handle LOST SESSION CASE
  useEffect(() => {
    // initialise websocket connection
    if (stripe && !clientSecret && !payLater && userId) {
      setUrl(`${process.env.REACT_APP_WSS_URL}customer-websocket?clientAuthParam=${nanoid()}`); // this will start the connection
    }
    // when the status changes to 'connectionOpen', send the message to the backend
    if (readyState === ReadyState.OPEN && !lastMessage) {
      console.log('sent message');
      sendJsonMessage({
        action: 'getCustomerSubscriptionStatus', // API GW uses that to route the message to the right lambda
        userId,
      });
    }
    if (lastMessage && JSON.parse(lastMessage.data).stripeSubscriptionStatus) {
      // if the above is ok, resend email from Cognito
      console.log('message received ', lastMessage);
      terminateWebSocketConnection();
      const subscriptionStatus = JSON.parse(lastMessage.data).stripeSubscriptionStatus;

      // check in Cognito if user is confirmed, if not, send a new confirmation code (Auth.resendSignUp)
      // PL 240920: 'confirmed' was set by the USERMERGED handler, which is no longer in use
      // the federated sign up process will take the user directly to Prep > Dashboard and will not show this component
      // removing userState from the check
      // if (subscriptionStatus === 'active' && user.userState !== 'confirmed') {
      //   dispatch(resendConfirmationEmail(userId));
      //   // finish loading and show verification code input
      //   setIsLoading(false);
      // } else if (subscriptionStatus === 'active' && user.userState === 'confirmed') {
      //   setIsLoading(false);
      // } else {
      //   setIsLoading(false);
      //   setDisplayError(true);
      // }
      if (subscriptionStatus === 'active') {
        dispatch(resendConfirmationEmail(userId));
        // finish loading and show verification code input
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setDisplayError(true);
      }
    }
  }, [lastMessage, readyState]);

  // handle PAY LATER CASE

  useEffect(() => {
    if (payLater) {
      // we don't need to do anything here, Cognito has sent the verification email once it was dispatched from hubListener, so the user just needs to enter the verification code
      setIsLoading(false);
    }
  }, [payLater]);

  // situation: user has completed payment and stripe navigates to this page
  // need to verify the status of stripe SetupIntent (NEW -- need to be added to the non-rendered version where the user uses the link containing validation code from their email)
  // and user's email (they received a code)

  async function handleNextStep(passedConfirmationCode) {
    const confirmationCode = passedConfirmationCode || localConfirmationCode; // if there is a confirmation code in the form, it takes precedence
    try {
      await dispatch(confirmUser(userId, confirmationCode)); // this will trigger changes in the useEffect below (state.user.registration)
    } catch (err) {
      setIsSubmitting(false);
      if (err.message.includes('CodeMismatchException')) {
        setCodeError('CodeMismatchException');
      } else {
        setCodeError('genericError');
      }
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setIsSubmitting(true);
    setCodeError(undefined);
    setLocalConfirmationCode(e.target[0].value); // first target is input field, second is button
    handleNextStep(e.target[0].value);
  }
  return isLoading ? (
    <div className="w-full h-[65vh;] flex flex-col gap-4 justify-center items-center text-gray-400">
      <MiniSpinner className="h-12 w-12 animate-spin" />
      <p className="text-xl text-gray-500">{t('register.paymentStillProcessed')}</p>
    </div>
  ) : (
    <div className="relative bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-0" aria-hidden="true">
        <svg className="absolute top-0 left-1/2 transform translate-x-64 -translate-y-8" width={640} height={784} fill="none" viewBox="0 0 640 784">
          <defs>
            <pattern id="9ebea6f4-a1f5-4d96-8c4e-4c2abf658047" x={118} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect y={72} width={640} height={640} className="text-gray-50" fill="currentColor" />
          <rect x={118} width={404} height={784} fill="url(#9ebea6f4-a1f5-4d96-8c4e-4c2abf658047)" />
        </svg>
      </div>

      <div className="relative pt-6 pb-16 sm:pb-24 lg:pb-32">
        <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
              {displayError ? (
                <ErrorMessage />
              ) : (
                <WelcomeMessage
                  handleSubmit={handleSubmit}
                  localConfirmationCode={localConfirmationCode}
                  setLocalConfirmationCode={setLocalConfirmationCode}
                  version={user?.userState === 'confirmed' ? 'userConfirmed' : 'userNotConfirmed'}
                  identityProvider={user?.provider}
                  codeError={codeError}
                  loading={isSubmitting}
                />
              )}
            </div>
            <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
              <svg
                className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-8 scale-75 origin-top sm:scale-100 lg:hidden"
                width={640}
                height={784}
                fill="none"
                viewBox="0 0 640 784"
                aria-hidden="true"
              >
                <defs>
                  <pattern id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e" x={118} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect y={72} width={640} height={640} className="text-gray-50" fill="currentColor" />
                <rect x={118} width={404} height={784} fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)" />
              </svg>
              <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
                <button type="button" className="relative block w-full bg-white rounded-lg overflow-hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span className="sr-only">Watch our video to learn more</span>
                  <img className="w-full" src="https://images.unsplash.com/photo-1556740758-90de374c12ad?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80" alt="" />
                  <div className="absolute inset-0 w-full h-full flex items-center justify-center" aria-hidden="true">
                    <svg className="h-20 w-20 text-indigo-500" fill="currentColor" viewBox="0 0 84 84">
                      <circle opacity="0.9" cx={42} cy={42} r={42} fill="white" />
                      {/* eslint-disable-next-line max-len */}
                      <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
PaymentSuccessfulCore.propTypes = {
  clientSecret: PropTypes.string,
  payLater: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};
PaymentSuccessfulCore.defaultProps = {
  clientSecret: null,
  payLater: null,
};

export default function PaymentSuccessful() {
  const [showModal, setShowModal] = useState({ visible: false });
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(['site']);

  // CallbackHandler should put the stripe params in sessionStorage
  const [setupIntentObject, _] = useState(JSON.parse(sessionStorage.getItem('setupIntent')));
  const [payLater, __] = useState(JSON.parse(sessionStorage.getItem('payLater')));
  const clientSecret = setupIntentObject?.setupIntentClientSecret;

  const [stripePromiseReady, setStripePromiseReady] = useState(false);

  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const registration = useSelector((state) => state.user.registration);

  // TODO: to reenter at a later date, need to get clientSecret / setupIntent from Stripe
  // useful in case there is a crash between SignUp3 and here

  const { userId } = useParams();

  // redirect away from this page if the user is logged in or there is no userId provided
  useEffect(() => {
    // if (isLoggedIn) navigate(`/${i18n.language}/app`);
    console.log('running useEffect in PAymentSuccessful');
    if (isLoggedIn) navigate(`/${i18n.language}/app/getready`); // Prep component (the one for adding demo data) will check if user has any data and if so, redirect to /app

    // user comes from clicking the email link, so there is a search parameter named confirmationCode, then go directly to dispatch
    // UPDATE: not possible now, as incoming url needs to have a client_secret from Stripe
    // if (userId && confirmationCodeUrl) handleNextStep();

    // if there is the userId parameter and no confirmation code, the user most likely came from registration and wants to fill out the code manually
    // (no action necessary, just render the page)

    // if there is neither userId parameter nor confirmation code, this shouldn't happen, so go to main page
    console.log('running useEffect in PAymentSuccessful');
    if (!userId) navigate(`/${i18n.language}/notfound`);
  });

  // the stripe promise must be resolved, otherwise the child component gets a null stripe object
  useEffect(() => {
    stripePromise.then((data) => {
      setStripePromiseReady(true);
    });
  });

  // handles the next step after the user has entered the confirmation code (i.e. confirmation of Cognito registration )
  // if registration changes in the redux store, display a success message
  useEffect(() => {
    console.log('PaymentSuccessful - registration has changed - running useEffect', registration);
    // if registrationConfirmed changes to 'confirmed', means the user is confirmed already and should go to the dashboard
    if (registration?.status === 'confirmed') {
      // if password is still in state / store, then use it to log in and forward user to App
      if (registration?.username && registration?.password) {
        setShowModal({ visible: true, alertId: 'userRegistrationSuccessfulWithPassword' });
        // the rest is handled in the useEffect below (which reacts to modal state changes)
      } else {
        // show modal "success enter your login details on the next screen"
        setShowModal({ visible: true, alertId: 'userRegistrationSuccessful' });
        // the rest is handled in the useEffect below (which reacts to modal state changes)
      }
    }
  }, [registration]);

  // handle modal state changes from above
  useEffect(() => {
    async function handleAsyncSignin() {
      try {
        const returnObject = await Auth.signIn(registration.username, registration.password);
        return returnObject;
      } catch (err) {
        console.error('PaymentSuccessful - handleAsyncSigning . error when trying to sign up user', err);
      }
      return null;
    }

    console.log('PaymentSuccessful - showModal has changed - starting useEffect', showModal);
    if (!showModal.visible) {
      // modal has been closed
      if (showModal.alertId === 'userRegistrationSuccessful') {
        // this should automatically bring up the login screen for not-logged-in users
        // navigate(`/${i18n.language}/app`);
        navigate(`/${i18n.language}/app/getready`); // see above
      }
      if (showModal.alertId === 'userRegistrationSuccessfulWithPassword') {
        // dispatch login, the change in isLoggedIn status will trigger the useEffect on top of this module and navigate to dashboard
        handleAsyncSignin();
      }
    }
  }, [showModal]);

  return (
    <>
      {showModal.visible ? <AlertModal alertId={showModal.alertId} alertResetFunction={setShowModal} resetCallbackObject={{ ...showModal, visible: false }} /> : null}
      {/* stripe promise must be fulfilled before passing to Elements */}
      {stripePromiseReady === true ? (
        <Elements stripe={stripePromise}>
          <PaymentSuccessfulCore clientSecret={clientSecret} payLater={payLater} isLoading={isLoading} setIsLoading={setIsLoading} userId={userId} />
        </Elements>
      ) : (
        <div className="w-full h-[65vh;] flex flex-col gap-4 justify-center items-center text-gray-400">
          <MiniSpinner className="h-12 w-12 animate-spin" />
          <p className="text-xl text-gray-500">{t('register.paymentSuccessful.stillProcessed')}</p>
        </div>
      )}
    </>
  );
}
