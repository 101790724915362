/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import React, { memo, useEffect, useState, useRef } from 'react';
import { useForm, useFieldArray, useFormState } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Auth, API } from 'aws-amplify';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import ToolTip from '../../../elements/ToolTip';
import { currencyCodes } from '../../../misc/currencyCodes';
import MiniSpinner from '../../../misc/MiniSpinner';
import AccountDataStockFields from './AccountDataStockFields';
import AccountDataLoanFields from './AccountDataLoanFields';
import InputField from './AccountDataInputField';

dayjs.extend(utc);

function getPeriodNum(periodName) {
  let periodNum;
  switch (periodName) {
    case 'month':
      periodNum = 1;
      break;
    case 'quarter':
      periodNum = 3;
      break;
    case 'year':
      periodNum = 12;
      break;
    default:
      periodNum = undefined;
  }
  return periodNum;
}

function getCurrent(arr, date = dayjs.utc().valueOf()) {
  return arr.reduce((prev, current) => (prev.effectiveDate > current.effectiveDate && current.effectiveDate < date ? prev : current));
}

// component initialises in loading state (spinner), then calls get
function AccountData({ index, workflowStepStatus, updateWorkflowStatus, userInput, setUserInput, category }) {
  const { t } = useTranslation('app', { keyPrefix: 'addAccount.D4' });

  const [loading, setLoading] = useState(true);
  const [maxAccounts, setMaxAccounts] = useState(Infinity);
  const [maxExDeAccounts, setMaxExDeAccounts] = useState(Infinity);

  const selectDepositAccounts = useSelector((state) => state.data?.deposits.accounts);

  const { register, reset, control, setValue, getValues, formState, trigger, watch } = useForm({
    mode: 'all',
    defaultValues: userInput && userInput.accountArray && { accountArray: userInput.accountArray },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'accountArray',
  });

  // expects account name and account object retrieved from provider API
  function appendDepositAccount(accountObject, name) {
    console.log('appending deposit account', accountObject, name);
    append({
      name,
      currency: accountObject.currency,
      iban: accountObject.iban,
      // ↑↑ very important, when we renew access in GC, we need to match new GC providerAccountIds by IBAN, so this must be copied over from GC object and remain unchanged (implemented separately)
      bic: userInput.provider.bic,
      bankName: userInput.provider.providerInstitutionName,
      isImported: true,
      providerAccountId: accountObject.providerAccountId,
      providerName: accountObject.providerName || accountObject.provider,
    });
  }

  // expects account name and account object retrieved from provider API
  function appendStocksAccount(accountObject, name) {
    console.log('appending stock account', accountObject, name);
    append({
      name,
      accountNo: accountObject.accountNumber,
      brokerName: userInput.provider.providerInstitutionName,
      isImported: true,
      providerAccountId: accountObject.providerAccountId,
      providerName: accountObject.providerName || accountObject.provider,
    });
  }

  function appendLoanAccount(accountObject, name) {
    console.log('appending loan account', accountObject, name);
    append({
      name,
      currency: accountObject.currency,
      iban: accountObject.iban,
      bankName: userInput.provider.providerInstitutionName,
      isImported: true,
      providerAccountId: accountObject.providerAccountId,
      providerName: accountObject.providerName || accountObject.provider,
      balance: accountObject.balance, // getAccounts from finApi has balance even for loans
      // impose default values
      calculateUntilEnd: true,
      projectCreateNew: true,
      direction: 'received',
    });
  }

  // ------------------
  // INITIALISE FORM
  // ------------------

  // initialise form and add either an empty array item (manual sync) or trigger getAccountDetails and add them all
  useEffect(() => {
    const cancellationToken = { cancelled: false };
    // immediatelly-invoked function expression (IIFE) to handle async syntax within useEffect
    (async () => {
      // ------------------------------------------------------------------------
      // HANDLE SYNCTYPE 'MANUAL'
      // ------------------------------------------------------------------------

      if (userInput.syncType === 'manual') {
        console.log('updating array with manual sync');
        const newAccount = {
          name: '',
          currency: 'EUR',
          isImported: true, // it won't be displayed, but it needs to be there otherwise "submit" routine further down won't work
        };
        // append(newAccount);
        reset({ accountArray: [newAccount] });
        // update userInput with the object above
        setUserInput((prev) => ({ ...prev, accountArray: newAccount })); // added to userInput for submission through AddAccount

        setLoading(false);
      }

      // ------------------------------------------------------------------------
      // HANDLE SYNCTYPE 'AUTOMATIC'
      // ------------------------------------------------------------------------

      // if the syncType is automatic and there are no accounts from an existing connection
      // get account details (name, iban) from the API and add them to the form
      if (userInput.syncType === 'automatic') {
        if (cancellationToken.cancelled) {
          return; // do not continue if the useEffect was cancelled
        }

        // ------------------------------------------------------------------------
        // SET MAXIMUM NUMBER OF ACCOUNTS TO BE ADDED
        // ------------------------------------------------------------------------

        // get current user directly from cognito (to have the latests attributes and not the cached ones)
        if (maxAccounts === Infinity || maxExDeAccounts === Infinity) {
          const user = await Auth.currentAuthenticatedUser({ bypassCache: true });

          const currentAccountIdsArray = JSON.parse(user.attributes['custom:all_account_slots']);
          const exDeAccountIdsArray = JSON.parse(user.attributes['custom:ex_de_deposit_slots']);
          console.log('DEBUG: currentAccountIdsArray', currentAccountIdsArray);
          console.log('DEBUG: exDeAccountIdsArray', exDeAccountIdsArray);

          // count nulls in each array to determine how many accounts can be added
          setMaxAccounts(currentAccountIdsArray.filter((a) => a === null).length);
          setMaxExDeAccounts(exDeAccountIdsArray.filter((a) => a === null).length);
        }

        // ------------------------------------------------------------------------
        // GET ACCOUNT DETAILS FROM PROVIDER OR FROM USERINPUT
        // ------------------------------------------------------------------------

        let retrievedProviderAccounts;
        let providerAccessId;

        if (cancellationToken.cancelled) {
          return; // do not continue if the useEffect was cancelled
        }

        // if userInput has retrievedProviderAccounts, we don't need to call the API again
        // newProviderAccounts would be returned by getWebFormUrl if finAPI decided webform is not needed
        if (userInput.retrievedProviderAccounts) {
          retrievedProviderAccounts = userInput.retrievedProviderAccounts;
          providerAccessId = userInput.providerAccessId;
        } else {
          const session = await Auth.currentSession();

          // there was a webform involved, let's fetch account details
          const response = await API.get('myAPI', `deposits/processids/${userInput.provider.processId}?country=${userInput.countryCode}`, {
            body: null,
            headers: {
              'Content-Type': 'application/json',
              Authorization: session.idToken.jwtToken,
            },
          });
          // returned object is { accounts: [], providerAccessId } // the latter being either bankConnectionId for finapi or requisitionId for gocardless
          // CAUTION: bankConnectionId does not get returned by the webform, it is retrieved by getAccountDetails API call and returned here for the first time
          console.log('AccountData: received accounts from provider', response, 'under the following providerAccessId', response.providerAccessId);

          retrievedProviderAccounts = response.accounts;
          providerAccessId = response.providerAccessId;
        }

        if (cancellationToken.cancelled) {
          return; // do not continue if the useEffect was cancelled
        }

        // if successful, put them to accountArray for the user to decide which one to use
        // structure of accounts is { providerAccountId, providerAccountName, providerAccountType, iban, currency, provider }
        remove(0); // it removes nothing, but somehow helps with setting the form to valid state, so that it can be submitted to userInput

        // update providerAccessId in userInput
        setUserInput((prev) => ({ ...prev, providerAccessId }));

        // -------------------------------------------------------------------------------------
        // PREPARE EXISTING ACCOUNTS FOR UPDATE WITH NEW PROVIDER/ACCOUNT IDS FOR GC ACCOUNTS
        // -------------------------------------------------------------------------------------

        // existingAccounts come from Redux and only contain accounts for the current category, provider and selected providerInstitutionId (done in RedirectToProvider)
        const existingAccountsUpdated = []; // if there is anything here at the end, we will putAccount them in AddAccount's handleSubmit
        const responseAccountsWithExistingAccountsMapped = retrievedProviderAccounts.map((ra) => ({
          ...ra,
          matchingExistingAccount: (userInput.existingAccounts || []).find((ea) => ea.iban === ra.iban),
        }));

        // if this is a GC account in reconcileAccount mode (new requisition must replace old requisition, record the old requisitionId)
        // to be used when submitting in AddAccount > handleSubmit
        if (userInput.reconcileAccounts && userInput.existingAccounts && userInput.existingAccounts.length > 0) {
          setUserInput((prev) => ({ ...prev, removedGCRequisitonId: prev.existingAccounts[0].tags.provider.gocardless.providerAccessId }));
        }

        // TODO here we could theoretically also check previous providerAccessId and new providerAccessId and only initiate the below if those are not the same

        // here we expect to see the matchingExistingAccount object for all accounts that are already in Redux (mapping happens a few lines above by comparing ibans)
        console.log('responseAccountsWithExistingAccountsMapped', responseAccountsWithExistingAccountsMapped);

        if (cancellationToken.cancelled) {
          return; // do not continue if the useEffect was cancelled
        }

        responseAccountsWithExistingAccountsMapped.forEach((ram) => {
          if (ram.matchingExistingAccount) {
            // if userInput.reconcileAccounts is true, this is a GC account and we need to update the tags of the EXISTING accounts with the changed providerAccessId and providerAccountId
            if (userInput.reconcileAccounts) {
              existingAccountsUpdated.push({
                ...ram.matchingExistingAccount,
                tags: {
                  ...ram.matchingExistingAccount.tags,
                  provider: {
                    ...ram.matchingExistingAccount.tags.provider,
                    gocardless: {
                      ...ram.matchingExistingAccount.tags.provider.gocardless,
                      providerAccessId,
                      providerAccountId: ram.providerAccountId,
                    },
                  },
                },
              });
            }

            // remove all accounts from form whose ibans are already in Redux (ibans for syncType 'automatic' are read-only and cannot be altered by user, so we can take them as a reference)
            return;
          }

          // ------------------------------------------------------------------------
          // ADD REMAINING NEW ACCOUNTS TO FORM
          // ------------------------------------------------------------------------

          if (cancellationToken.cancelled) {
            return; // do not continue if the useEffect was cancelled
          }

          // caution: this output format is also used in useForm as well as in RedirectToProvider - if changed here, change there as well
          const appendedProviderAccountIds = fields.map((f) => f.providerAccountId);
          if (category === 'deposits' && ram.depositType !== 'stocks' && ram.depositType !== 'loans') {
            if (!appendedProviderAccountIds.includes(ram.providerAccountId)) appendDepositAccount(ram, ram.providerAccountName);
          }
          if (category === 'stocks' && ram.depositType === 'stocks') {
            if (!appendedProviderAccountIds.includes(ram.providerAccountId)) appendStocksAccount(ram, ram.providerAccountName);
          }
          if (category === 'loans' && ram.depositType === 'loans') {
            if (!appendedProviderAccountIds.includes(ram.providerAccountId)) appendLoanAccount(ram, ram.providerAccountName);
          }
        });

        // ----------------------------------------------------------------------------------------
        // UPDATE EXISTING ACCOUNTS WHICH HAVE NOT BEEN RETURNED BY PROVIDER TO SYNCTYPE 'MANUAL'
        // ----------------------------------------------------------------------------------------

        // find out if we have any unmatched existing accounts left (they are already filtered by current category, provider and providerInstitutionId)
        // if we do, that means one or more accounts have been removed from the provider and we need to convert them to manual and inform the user
        // show all existingAccounts which are not in responseAccountsWithExistingAccountsMapped
        const unmatchedExistingAccounts = (userInput.existingAccounts || []).filter((ea) => !responseAccountsWithExistingAccountsMapped.find((ra) => ra.iban === ea.iban));
        if (unmatchedExistingAccounts.length > 0) {
          console.log('unmatched existing accounts', unmatchedExistingAccounts);
          // set tags.gocardless / tags.finapi to {} in existingAccountsUpdated
          unmatchedExistingAccounts.forEach((uea) => {
            // push to existingAccountsUpdated - they will be processed later in AddAccount
            existingAccountsUpdated.push({
              ...uea,
              syncType: 'manual',
              tags: {
                ...uea.tags,
                [userInput.provider]: {}, // remove tags for the current provider
              },
            });
          });
          // show a dialog to the user that the account has been removed from the provider and has been switched to manual

          window.dispatchEvent(
            new CustomEvent('setAlert', {
              detail: {
                id: 'providerAccountsRemoved',
                caller: 'AccountData',
                args: { list: unmatchedExistingAccounts.map((uea) => `${uea.name} (${uea.iban})`).join(', ') },
              },
            }),
          );
        }

        setUserInput((prev) => ({ ...prev, existingAccountsUpdated }));

        await trigger(); // trigger validation for all fields -- this will among others run a validation on the allowed account numbers and ggf. display the error messages

        console.log('AccountData: reached end of useEffect');

        setLoading(false);
      } // end of if block
    })(); // end of IIFE

    return function cleanup() {
      console.log('AccountData: cleanup');
      remove(); // remove all items from the array
      cancellationToken.cancelled = true;
    };
  }, [userInput.accountsFromExistingConnection]);

  useEffect(() => {
    // set up a window event receiver to trigger validation when Next button is pressed in AddAccount > NavComponent
    window.addEventListener('triggerValidation', () => {
      trigger();
    });
    return function cleanup() {
      window.removeEventListener('triggerValidation', () => {
        trigger();
      });
    };
  }, []);

  // account data for loans must be transformed before adding to userInput
  // incoming object is the entire form; we are changing the account objects within accountArray
  function transformLoansData(data) {
    if (data.length === 0) return data;
    const accountArray = data.map((item) => {
      // reverse sign on loan Amount when taken
      const loanAmount = (item.direction === 'received' ? -1 : 1) * Math.abs(Number(item.loanAmount));

      let { interestRate } = item;
      // if advanced view is off, overwrite the interestRate array with a single object from simple rate
      if (!item.interestRateAdvancedView) {
        // if interestRateSimple is true, we transform it to the interestRate array with a date from the past
        interestRate = [{ effectiveDate: dayjs.utc('1970-01-01').startOf('month').valueOf(), value: item.interestRateSimple }];
      } else {
        // if advanced view is on, we set all dates to month-start
        interestRate = item.interestRate.map((i) => ({ ...i, effectiveDate: dayjs.utc(i.date).startOf('month').valueOf() }));
      }

      // moving interest from Bereitsstellungszins and interestAfterTerm
      if (
        item.bereitstellungszinsen
        && item.bereitstellungszinsenSinceDate
        && interestRate.filter((i) => i.effectiveDate === dayjs.utc(item.bereitstellungszinsenSinceDate).startOf('month').valueOf()).length === 0
      ) {
        interestRate.push({ effectiveDate: dayjs.utc(item.bereitstellungszinsenSinceDate).startOf('month').valueOf(), value: item.bereitstellungszinsen });
      }

      if (
        item.interestAfterTerm
        && item.interestAfterTermSinceDate
        && interestRate.filter((i) => i.effectiveDate === dayjs.utc(item.interestAfterTermSinceDate).startOf('month').valueOf()).length === 0
      ) {
        interestRate.push({ effectiveDate: dayjs.utc(item.interestAfterTermSinceDate).startOf('month').valueOf(), value: item.interestAfterTerm });
      }

      // moving period and tilgung to the { effectiveDate, value } structure
      const period = [{ effectiveDate: dayjs.utc('1970-01-01').startOf('month').valueOf(), value: item.period }];

      // calculate tilgung from installment amount and interest rate (by this time we already have a complete interestRate array)
      // we use it for simulation only, so we want to know what is the current interest rate (this month) to simulate all future payments
      // the form gathers information first about "type" (annuity / fixedPrincipal / interestOnly), then in next step depending on the selected "type":
      // - annuity: annuitySubtype (byTilgungsrate / byInstallmentAmount) and annuitySubtypeAmount (4% or 1000€)
      // - fixedPrincipal: fixedPrincipalSubtype (byTilgungsrate / byPrincipalRepaid) and fixedPrincipalSubtypeAmount (4% or 1000€)
      // the user inputs installment / principal amount per period, which is not always a month
      let tilgung;
      if (item.type === 'annuity') {
        if (item.annuitySubtype === 'byTilgungsrate') tilgung = item.annuitySubtypeTilgung;
        else {
          // byInstallmentAmount
          const currentPeriod = getCurrent(period).value;
          const periodNum = getPeriodNum(currentPeriod);

          const currentInterestRate = getCurrent(interestRate).value;
          const tilgungsrateInterest = ((currentInterestRate / 100) * Math.abs(loanAmount)) / (12 / periodNum);
          const tilgungsratePrincipal = item.annuitySubtypeAmount - tilgungsrateInterest;
          if (tilgungsratePrincipal < 0) tilgung = 0;
          else tilgung = ((tilgungsratePrincipal * (12 / periodNum)) / Math.abs(loanAmount)) * 100; // in %
        }
      }

      if (item.type === 'fixedPrincipal') {
        if (item.fixedPrincipalSubtype === 'byTilgungsrate') tilgung = item.fixedPrincipalSubtypeTilgung;
        else {
          // byPrincipalRepaid (user enteres the amount of principal repaid every period)
          const currentPeriod = getCurrent(period).value;
          const periodNum = getPeriodNum(currentPeriod);

          tilgung = ((item.fixedPrincipalSubtypeAmount * (12 / periodNum)) / Math.abs(loanAmount)) * 100; // in %
        }
      }

      if (item.type === 'interestOnly') {
        tilgung = 0;
      }

      const percentRepaidAnnually = [{ effectiveDate: dayjs.utc('1970-01-01').startOf('month').valueOf(), value: tilgung }];

      // handle end date
      let contractEndDate = null;
      if (!item.calculateUntilEnd) {
        contractEndDate = dayjs.utc(item.endDate).startOf('month').valueOf();
      }

      // create connected deposits array
      let connectedDepositAccounts = [];
      if (item.connectedDepositMode === 'existingAccount') {
        connectedDepositAccounts = [item.connectedDepositAccountObject];
      }

      // setting projectId to null if “create new project” is ticked
      const connectedProjectId = item.projectId === 'new' ? null : item.connectedProjectId;

      // remember importFlag, if necessary for loans
      const importFlag = 'post';

      console.log('transformed item', {
        ...item,
        loanAmount,
        interestRate,
        period,
        percentRepaidAnnually,
        contractEndDate,
        connectedProjectId,
        connectedDepositAccounts,
        importFlag,
      });
      return {
        ...item,
        loanAmount,
        interestRate,
        period,
        percentRepaidAnnually,
        contractEndDate,
        connectedProjectId,
        connectedDepositAccounts,
        importFlag,
      };
    });
    return accountArray;
  }

  const watchForm = watch(); // subscribe to changes in field content
  // keep the previous value of watchForm to compare with the new one (to avoid infinite loops / update depth errors)
  const prevWatchForm = useRef();

  function refreshUserInputAndWorkflowStatus() {
    // only allow those with imported flag set to on and with accountName / currency (WHY?)
    // Trigger validation for all fields (why not use trigger() though?)
    // trigger();
    const formValues = (getValues('accountArray') || []).filter((item) => item.isImported); // let's just select all "isImported", if they are not valid, this step won't be set to true
    // console.log('refreshUserInputAndWorkflowStatus: formValues', getValues('accountArray'), 'is form valid', formState.isValid);

    if (formState.isValid) {
      // if there is a difference in userInput, update it (it causes a update depth error if we don't do that check)
      if (prevWatchForm.current !== JSON.stringify(formValues)) {
        // run category-specific transformations, if necessary (so far only for loans)
        const formValuesTransformed = category === 'loans' ? transformLoansData(formValues) : formValues;
        setUserInput((prev) => ({ ...prev, accountArray: formValuesTransformed }));
        prevWatchForm.current = JSON.stringify(formValues); // to be compared for the next time (it has to be the raw form structure, not the formValuesTransformed data!)
      } // added to userInput for submission through AddAccount
      // setUserInput({ ...userInput, accountArray: readyItems }); // added to userInput for submission through AddAccount
      if (!workflowStepStatus[index]) updateWorkflowStatus(index, true); // if there is no 'if' here, it causes update depth error
    } else if (workflowStepStatus[index]) updateWorkflowStatus(index, false);
  }

  useEffect(() => {
    refreshUserInputAndWorkflowStatus();
  }, [watchForm]);

  return (
    <form className="space-y-6">
      {loading && (
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <h3 className="text-xl font-bold leading-6 text-gray-500 mb-6">{t('gettingAdditionalAccountInformation')}</h3>
          <MiniSpinner className="w-10 h-10 text-gray-400 animate-spin" />
        </div>
      )}
      {!loading && fields.length === 0 && (
        <div className="absolute top-24 right-0 left-0 bottom-24 flex flex-col items-center justify-center">
          <h3 className="text-xl font-bold leading-6 text-gray-500 mb-6">{t('providerAllAccountsAdded')}</h3>
        </div>
      )}
      {!loading
        && fields.length > 0
        && fields.map((item, idx) => (
          // <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 md:gap-6 pb-6 border-b border-gray-300">
          <div className="space-y-4 md:space-y-0 md:grid md:grid-cols-3 xl:grid-cols-4 md:gap-6 pb-6 border-b border-gray-300">
            <div className="md:row-span-2">
              <h3 className="text-xl md:text-2xl font-bold leading-6 text-gray-900">{fields.length < 2 ? `${t('accountInfo')}` : `${t('account')} ${idx + 1}`}</h3>
              <p className="mt-4 sm:mt-2 text-sm text-gray-500 space-y-2">
                <p>{userInput.syncType === 'manual' ? t('accountInfoManual') : t('accountInfoAutomatic')}</p>
                {fields.length > 1 && <p>{userInput.syncType === 'manual' ? '' : t('accountInfoAutomatic2')}</p>}
              </p>
            </div>
            {/* ACCOUNT NAME */}
            <InputField
              id="name"
              myIndex={idx}
              type="text"
              autoComplete="off"
              label={t('accountName.label')}
              toolTip={t('accountName.tooltip')}
              placeholder="ABC bank savings account"
              optional={false}
              register={register}
              errors={formState.errors}
              setValue={setValue}
              validationRules={{ required: { value: true, message: t('accountName.error') } }}
            />
            {/* CURRENCY DROPDOWN -- hidden for stocks */}
            {['deposits', 'loans'].includes(category) && (
              <div>
                <label htmlFor="currency" className="flex text-sm font-medium text-gray-700">
                  {t('baseCurrency.label')}
                  <ToolTip info={t('baseCurrency.tooltip')} />
                </label>
                <div className="mt-1">
                  <select
                    id={`${idx}.currency`}
                    name={`${idx}.currency`}
                    className="shadow-sm focus:ring-brandBlue-400 focus:border-brandBlue-400 block w-full sm:text-sm border-gray-300 rounded-md"
                    {...register(`accountArray.${idx}.currency`)}
                  >
                    {currencyCodes.map((c) => (
                      <option key={c}>{c}</option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            {/* INSTITUTION NAME */}
            <InputField
              id={['deposits', 'loans'].includes(category) ? 'bankName' : 'brokerName'}
              myIndex={idx}
              type="text"
              autoComplete="off"
              label={['deposits', 'loans'].includes(category) ? t('bankName.label') : t('brokerName.label')}
              // "mostly for decorative purposes; or to make your account's heading look complete"
              toolTip={['deposits', 'loans'].includes(category) ? t('bankName.tooltip') : t('brokerName.tooltip')}
              placeholder={['deposits', 'loans'].includes(category) ? t('bankName.placeholder') : t('bankName.placeholder')}
              optional
              register={register}
              errors={formState.errors}
              // validationRules={{ required: { value: true, message: t('bankName.error') } }}
            />
            {/* IBAN or ACCOUNT NUMBER */}
            <InputField
              id={['deposits', 'loans'].includes(category) ? 'iban' : 'accountNo'}
              myIndex={idx}
              formatting="md:col-start-2"
              type="text"
              autoComplete="off"
              label={['deposits', 'loans'].includes(category) ? t('iban.label') : t('accountNo.label')}
              toolTip={['deposits', 'loans'].includes(category) ? t('iban.tooltip') : t('accountNo.tooltip')}
              placeholder={['deposits', 'loans'].includes(category) ? t('iban.placeholder') : t('accountNo.placeholder')}
              optional
              register={register}
              errors={formState.errors}
              // validationRules={{ required: { value: true, message: t('iban.error') } }}
            />
            {/* BIC (deposits only) */}
            {category === 'deposits' && (
              <InputField
                id="bic"
                myIndex={idx}
                type="text"
                autoComplete="off"
                label={t('bic.label')}
                toolTip={t('bic.tooltip')}
                placeholder="ABCDDEFFXXX"
                optional
                register={register}
                errors={formState.errors}
              />
            )}
            {/* IS IMPORTED flag, only shows up when there are more than 1 account to be added */}
            <div className="pt-4 flex items-center gap-2 order-last md:order-none">
              {fields.length > 1 && (
                <div>
                  <div className="flex items-center space-x-2">
                    <input
                      id={`${idx}.isImported`}
                      name={`${idx}.isImported`}
                      className="w-5 h-5 shadow-sm text-brandBlue-500 focus:ring-brandBlue-400 focus:border-brandBlue-400 border-gray-300 rounded-md"
                      type="checkbox"
                      {...register(`accountArray.${idx}.isImported`, {
                        validate: (value) => {
                          // only consider maxExDeAccounts if the userInput.country !== 'DE'
                          const limit = Math.min(maxAccounts, userInput.countryCode === 'DE' ? Infinity : maxExDeAccounts);
                          if (getValues('accountArray').filter((f) => f.isImported).length <= limit) return true;
                          // TEST ME if (fields.filter((f) => f.isImported).length <= limit) return true;
                          if (limit === 0) return t('isImported.error0');
                          if (limit === 1) return t('isImported.error1');
                          return t('isImported.error', { num: limit });
                        },
                      })}
                    />
                    <label htmlFor={`${idx}.isImported`} className="flex text-sm font-medium text-gray-700">
                      {t('isImported.label')}
                      <ToolTip info={t('isImported.tooltip')} />
                    </label>
                  </div>
                  {/* console.log('DEBUG accountArray', formState.errors.accountArray, (formState.errors.accountArray || [])[0]?.isImported); */}
                  {formState.errors.accountArray?.[idx]?.isImported && (
                    <div className="text-sm text-red-500 pt-1" role="alert">
                      {formState.errors.accountArray?.[idx]?.isImported?.message}
                    </div>
                  )}
                </div>
              )}
            </div>
            {category === 'stocks' && (
              <AccountDataStockFields
                idx={idx}
                formState={formState}
                register={register}
                getValues={getValues}
                userInput={userInput}
                selectDepositAccounts={selectDepositAccounts}
                refreshUserInputAndWorkflowStatus={refreshUserInputAndWorkflowStatus}
              />
            )}
            {category === 'loans' && (
              <AccountDataLoanFields
                idx={idx}
                formState={formState}
                register={register}
                control={control}
                watch={watch}
                getValues={getValues}
                setValue={setValue}
                userInput={userInput}
                selectDepositAccounts={selectDepositAccounts}
                refreshUserInputAndWorkflowStatus={refreshUserInputAndWorkflowStatus}
              />
            )}
          </div>
        ))}
    </form>
  );
}
AccountData.propTypes = {
  index: PropTypes.number.isRequired,
  workflowStepStatus: PropTypes.array.isRequired,
  updateWorkflowStatus: PropTypes.func.isRequired,
  userInput: PropTypes.object,
  setUserInput: PropTypes.func,
  scrollUpToComponent: PropTypes.func,
  category: PropTypes.string.isRequired,
};
AccountData.defaultProps = {
  userInput: {},
  setUserInput: {},
};

// the following code is in place to reduce the circular dependency between AddAccount and AccountData
// this form updates userInput in the parent component which is a prop of this component, so updates to it cause renders
function areEqual(prevProps, nextProps) {
  // Deep compare all props
  return isEqual(prevProps, nextProps);
}

export const AccountDataMemo = memo(AccountData, areEqual);
export default AccountDataMemo;
