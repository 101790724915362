import { createSelector } from '@reduxjs/toolkit';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { nanoid } from 'nanoid';
import { t } from 'i18next';
import dayjs from 'dayjs';
import { groupBy } from '../../../../misc/arrayHelpers';
import applyUniversalFIFO from '../../../../misc/applyUniversalFIFO';
import * as globals from '..';
import * as stocks from '../stocks';
import * as loans from '../loans';
import * as pension from '../pension';

const createSelectorWithDebug = createSelectorCreator(
  defaultMemoize,
  // eslint-disable-next-line arrow-body-style
  (a, b) => {
    console.log('Comparing:', a, b); // Log the values being compared
    return a === b; // Use the default equality check
  },
);

export const selectDepositAccounts = (state) => state.data?.deposits.accounts;

// the computor function returns a function which takes accountId as parameter
export function getInterestRateByDepositAccount(accounts, accountId) {
  const targetAccount = accounts.find((account) => account.id === accountId);
  const tags = typeof targetAccount?.tags === 'string' ? JSON.parse(targetAccount?.tags) : targetAccount?.tags;
  return tags?.growthRate || 0;
}

function getSaldoObjectPerAccountId(accountTransactions, date) {
  const transactionsInScope = accountTransactions;
  // this returns an object (!) { projectId1: [], projectId2: [], null: [] }
  const combinedTransactions = [];
  Object.values(transactionsInScope).forEach((projectTransaction) => {
    const filteredTransactions = projectTransaction.filter((tx) => tx.date <= date);
    combinedTransactions.push(...filteredTransactions);
  });

  const quantity = combinedTransactions.reduce((prev, curr) => prev + curr.quantity, 0);
  return { quantity, currency: combinedTransactions[0]?.currency };
}

// returns an array of all projects with their position in a given stock on a given account, on a given date
function getBalanceObjectPerProjectId(accountTransactionsPerProjectId, date) {
  // returns an array with { projectId, quantity, upac, upbc, currency }
  const returnObject = [];
  Object.entries(accountTransactionsPerProjectId).forEach(([projectId, projectTransactions]) => {
    const filteredTransactions = projectTransactions.filter((tx) => tx.date <= date);
    // const amount = filteredTransactions.reduce((prev, curr) => prev + curr.amount, 0);
    // const accountCurrencyAmount = filteredTransactions.reduce((prev, curr) => prev + curr.accountCurrencyAmount, 0);
    const quantity = filteredTransactions.reduce((prev, curr) => prev + curr.quantity, 0);
    returnObject.push({
      projectId,
      quantity,
      upac: 1,
      upbc: projectTransactions.at(-1).upbc, // last transaction's upbc
      currency: projectTransactions[0].currency,
    });
  });
  return returnObject;
}
// returns an array of all projects with their position in a given stock on a given account, on a given date

// expects all deposit transactions as input, so as to not repeat the calculation in depositsGlobalTransactionView
// generates a list of annual transactions for each deposit account with growthRate
// as a failsafe it also calculates the overall account balance on a given date so that the payouts are not higher than the account balance
// returns an array of annual transactions with interest payments, separately for each project and for "no project" (projmectId = null)
export function simulatedInterestView(baseDate, baseCurrency, quotes, sliderEndDate, depositTransactions, accounts, projectMode = false) {
  if (!depositTransactions || depositTransactions.length === 0) return [];

  // generate a list of years in scope, starting with now() and ending with slider date
  const startDate = dayjs.utc().add(1, 'month').add(1, 'year').startOf('month')
    .valueOf(); // start of next month
  const years = [startDate];
  let year = startDate;
  while (years.at(-1) < (projectMode ? sliderEndDate : baseDate)) {
    year = dayjs.utc(year).add(1, 'year').valueOf();
    years.push(year);
  }

  // generate a view of deposits transactions grouped by accountId and projectId
  const depositsTransactionsObject = groupBy(depositTransactions || [], ['accountId', 'projectId']);
  // { accountId1: { projectId1: [], projectId2: [], null: [] }, accountId2: ... }

  const simulatedInterestTransactions = [];
  years.forEach((date) => {
    // for each account, for each project, calculate the position by project on a given date
    Object.keys(depositsTransactionsObject).forEach((accountId) => {
      const projectBalanceObjectsThisAccount = getBalanceObjectPerProjectId(depositsTransactionsObject[accountId], date); // returns an array with { projectId, quantity, upac, upbc, currency }

      // handle account overdraft: https://monestry.atlassian.net/l/cp/ronMFZKM
      // calculate overall account balance on the given date (to manage overdraft caused by parallel projects)
      const currentAccountBalanceObject = getSaldoObjectPerAccountId(depositsTransactionsObject[accountId], date); // returns an array with { quantity, currency }

      projectBalanceObjectsThisAccount.forEach((projectBalanceObject) => {
        // add simulated interest generated in previous loops to current account balance
        const simulatedInterestTransactionsBalance = simulatedInterestTransactions
          .filter((tx) => tx.accountId === accountId && tx.projectId === projectBalanceObject.projectId)
          .reduce((prev, curr) => ({ quantity: prev.quantity + curr.quantity }), { quantity: 0 });
        // console.log('simulatedInterestTransactionsBalance', simulatedInterestTransactionsBalance);

        // the total balance on a given date is the smallest of the two balances - the one from current project and the one from the account + interest
        const currentBalanceQuantity = Math.min(currentAccountBalanceObject.quantity, projectBalanceObject.quantity) + simulatedInterestTransactionsBalance.quantity;

        const quantity = Math.max(currentBalanceQuantity, 0) * (getInterestRateByDepositAccount(accounts, accountId) / 100); // see comment above currentAccountBalance definition
        // TODO PRD-1409
        // WORKAROUND: if baseCurrency is not currency, use CURRENT exchange rate from quotes
        const { currency } = projectBalanceObject;
        let upbc = 1;
        if (currency !== baseCurrency) {
          // upbc is by what do we need to multiply quantity (of account currency) to get base currency
          // quoteBaseCurrency is how much is 1 unit of baseCurrency in currency, e.g. for USD it is 0.92
          // as fallback take the most current upbc that was found by getBalanceObjectPerProjectId
          const currentQBC = quotes[currency]?.current?.quoteBaseCurrency;
          upbc = (currentQBC && currentQBC !== 0 ? 1 / currentQBC : undefined) || projectBalanceObject.upbc;
        }

        if (quantity === 0) return; // no interest to be paid
        const newTransaction = {
          category: 'deposits',
          projectId: projectBalanceObject.projectId, // this may potentially return 'null' instead of null
          upac: 1,
          upbc,
          amount: quantity * upbc,
          currency,
          quantity,
          transactionCurrency: currency,
          accountCurrency: currency,
          assetId: currency,
          accountId,
          id: nanoid(),
          date,
          otherParty: t('app:projects.simulated'),
          otherPartyAccount: t('app:projects.simulated'),
          isSimulated: true,
          description: `${t('app:projects.interestPayment')} ${currentBalanceQuantity.toFixed(2)} ${projectBalanceObject.currency}`,
          label: 'investment-interest',
          tags: {
            type: 'inflow',
            recurring: { activated: false },
            interestPayment: true,
            nonEditable: true,
          },
        };
        simulatedInterestTransactions.push(newTransaction);
      });
    });
  });
  // console.log('simulatedInterestTransactions', simulatedInterestTransactions);
  return simulatedInterestTransactions;
}

// returns an array with all deposits transactions (real or real+simulated) up to the Slider Date (baseDate) or to today (depending on dashboardMode in state)
// no isolated transactions are included
export const depositTransactions = createSelector(
  (state) => state.data?.deposits.transactions,
  (state) => state.data?.deposits.simulatedTransactions,
  (state) => state.data?.deposits.accounts,
  stocks.stockTransactions, // needed for dividend calc
  loans.loanTransactions, // needed for future loan payments calc
  (state) => state.data?.loans.accounts, // needed for future loan payments calc
  (state) => state.data?.pension.accounts, // needed for simulated pension contributions and payments
  (state) => state.data.stocks.settings,
  globals.globalQuotesView,
  (state) => state.user.profile.settings.baseCurrency,
  (state) => state.simulation,
  (state) => state.user?.profile.settings.inflationRate,
  (state) => state.user?.profile.settings.sliderEndDate,
  (state) => state.projects,
  function depositTransactionsCallback(
    transactions,
    simulatedTransactions,
    accounts,
    stocksTransactions,
    loanTransactions,
    loanAccounts,
    pensionAccounts,
    stockSettings,
    quotes,
    baseCurrency,
    simulation,
    inflationRate,
    sliderMax,
    projects,
  ) {
    const timer = dayjs().valueOf();
    if (process.env.REACT_APP_SELECTOR_DEBUG) console.log('SEL depositTransactions start');
    // if this is project mode, inject transactions below
    let transactionsUnpacked = transactions; // normally return plain simple transactions
    // in project mode inject simulated transactions and unpack the recurring ones
    if (simulation?.dashboardMode === 'projects') {
      const { baseDate } = simulation;
      const isolatedProjects = projects?.filter((project) => project.settings?.isIsolated).map((project) => project.id);

      // exclude isolated projects (only show the global view)
      const simulatedTransactionsWithoutIsolated = simulatedTransactions.filter((txn) => !isolatedProjects.includes(txn.projectId));

      // "unpack" recurring transactions
      const projectDepositTransactionsUntilSliderDateWithRecurring = transactions
        .concat(simulatedTransactionsWithoutIsolated)
        .filter((transaction) => transaction.date <= baseDate)
        .flatMap((txn) => globals.unpackRecurringTransactionsCore(inflationRate, baseDate, sliderMax, txn));

      // calculate simulated interest payment transactions
      const interestPaymentTransactions = projectDepositTransactionsUntilSliderDateWithRecurring.concat(
        simulatedInterestView(baseDate, baseCurrency, quotes, sliderMax, projectDepositTransactionsUntilSliderDateWithRecurring, accounts, false),
      );

      // calculate simulated dividend payment transactions
      const pastDividendTransactions = projectDepositTransactionsUntilSliderDateWithRecurring.filter((transaction) => transaction.label === 'investment-dividend');
      const dividendTransactions = stocks.simulatedDividendView(stocksTransactions, stockSettings, pastDividendTransactions, accounts, quotes, baseDate, sliderMax, baseCurrency, false) || [];

      // calculate simulated loan installments
      const loanInstallmentTransactions = loans.simulatedLoanInstallmentView(loanTransactions, loanAccounts, /* deposit */ accounts, quotes);

      // calculate simulated pension contributions and payouts
      const pensionContributionsAndPayouts = pension.addSimulatedContributionsAndPayouts(pensionAccounts, quotes, baseDate, inflationRate, false);

      // concatenate all results
      transactionsUnpacked = projectDepositTransactionsUntilSliderDateWithRecurring
        .concat(interestPaymentTransactions)
        .concat(dividendTransactions)
        .concat(loanInstallmentTransactions)
        .concat(pensionContributionsAndPayouts);
    }
    // apply FIFO to all transactions, one accountId after another
    // CAUTION: deposit accounts can have a negative balance; if that is the case, FIFO must run in reverseMode
    const uniqueAccountIds = Array.from(new Set(transactionsUnpacked.map((tr) => tr.accountId)));
    const finalArray = [];
    uniqueAccountIds.forEach((accountId) => {
      const transactionsForAccount = transactionsUnpacked.filter((tr) => tr.accountId === accountId);
      const transactionsAfterFifo = applyUniversalFIFO(transactionsForAccount);
      finalArray.push(
        transactionsForAccount.map((tr) => ({
          ...tr,
          quantityOpen: transactionsAfterFifo.find((tx) => tx.id === tr.id) ? transactionsAfterFifo.find((tx) => tx.id === tr.id).quantity : 0,
        })),
      );
    });
    if (process.env.REACT_APP_SELECTOR_DEBUG) console.log('SEL depositTransactions finish', dayjs().valueOf() - timer);
    return finalArray.flat();
  },
);

// returns an array with all deposits transactions (simulated and real), no time limit (recurring transactions are only simulated until Slider End date)
// including all isolated transactions
export const depositTransactionsProjectView = createSelector(
  (state) => state.data?.deposits.transactions,
  (state) => state.data?.deposits.simulatedTransactions,
  (state) => state.data?.deposits.accounts,
  stocks.stockTransactionsProjectView, // needed for dividend calc
  loans.loanTransactionsProjectView, // needed for future loan payments calc
  (state) => state.data?.loans.accounts, // needed for future loan payments calc
  (state) => state.data?.pension.accounts, // needed for simulated pension contributions and payments
  (state) => state.data.stocks.settings,
  globals.globalQuotesView,
  (state) => state.user.profile.settings.baseCurrency,
  (state) => state.simulation,
  (state) => state.user?.profile.settings.inflationRate,
  (state) => state.user?.profile.settings.sliderEndDate,
  function depositTransactionsProjectViewCallback(
    transactions,
    simulatedTransactions,
    accounts,
    stocksTransactions,
    loanTransactions,
    loanAccounts,
    pensionAccounts,
    stockSettings,
    quotes,
    baseCurrency,
    simulation,
    inflationRate,
    sliderMax,
  ) {
    // if this is project mode, inject transactions below
    if (simulation?.dashboardMode === 'projects') {
      const { baseDate } = simulation;

      const projectDepositTransactionsUntilSliderDateWithRecurring = transactions
        .concat(simulatedTransactions)
        // unpack recurring transactions by creating transactions for every recurring transaction (never = until the end of slider)
        .flatMap((txn) => globals.unpackRecurringTransactionsCore(inflationRate, baseDate, sliderMax, txn));

      // add simulated interest payment transactions
      const interestPaymentTransactions = simulatedInterestView(baseDate, baseCurrency, quotes, sliderMax, projectDepositTransactionsUntilSliderDateWithRecurring, accounts, true);

      const currentDividendTransactions = projectDepositTransactionsUntilSliderDateWithRecurring.filter((transaction) => transaction.label === 'investment-dividend');
      const dividendTransactions = stocks.simulatedDividendView(stocksTransactions, stockSettings, currentDividendTransactions, accounts, quotes, baseDate, sliderMax, baseCurrency, true) || [];

      // calculate simulated loan installments
      const loanInstallmentTransactions = loans.simulatedLoanInstallmentView(loanTransactions, loanAccounts, /* deposit */ accounts, quotes);

      // calculate simulated pension contributions and payouts
      const pensionContributionsAndPayouts = pension.addSimulatedContributionsAndPayouts(pensionAccounts, quotes, sliderMax, inflationRate, false);

      return projectDepositTransactionsUntilSliderDateWithRecurring
        .concat(interestPaymentTransactions)
        .concat(dividendTransactions)
        .concat(loanInstallmentTransactions)
        .concat(pensionContributionsAndPayouts);
    }
    // otherwise return plain simple transactions
    return transactions;
  },
);

export const incomeFromCapitalLabels = ['investment-fees', 'investment-dividend', 'investment-interest', 'investment-costs', 'investment-rent', 'investment-loanInterest', 'investment-pension'];

export const incomeFromCapitalLabelsIncome = ['investment-dividend', 'investment-interest', 'investment-rent', 'investment-pension'];
export const incomeFromCapitalLabelsExpense = ['investment-fees', 'investment-costs', 'investment-loanInterest'];

// get all taxes and fees and dividend transactions from deposits since baseline and return the sum
// payouts are just that - payouts; investment sale proceeds do not belong to that category
// labels in use: https://monestry.atlassian.net/wiki/spaces/TECH/pages/253001825/Labels
// eslint-disable-next-line max-len
export const incomeFromCapitalPayoutsAndFeesCurrent = (state) => depositTransactions(state).reduce((prev, curr) => prev + (incomeFromCapitalLabels.includes(curr.label) && curr.date >= globals.baseline(state) ? curr.amount || 0 : 0), 0);

export const incomeFromCapitalPayoutsAndFeesBaseline = (state) => depositTransactions(state).reduce(
  (prev, curr) => prev + (incomeFromCapitalLabels.includes(curr.label) && curr.date <= globals.baseline(state) && curr.date >= globals.referenceBaseline(state) ? curr.amount || 0 : 0),
  0,
);

// sum of outflows (all negative transactions which are not marked as internal or capitalIncome)
export const outflowsCurrent = (state) => depositTransactions(state)
  .filter((transaction) => transaction.quantity < 0 && transaction.label?.split('-')[0] !== 'investment' && transaction.date >= globals.baseline(state))
  .reduce((acc, curr) => acc + curr.quantity * curr.upbc, 0);

// sum of outflows (all negative transactions which are not marked as internal or capitalIncome) in the reference period
export const outflowsBaseline = (state) => depositTransactions(state)
  .filter(
    // eslint-disable-next-line max-len
    (transaction) => transaction.quantity < 0 && transaction.label?.split('-')[0] !== 'investment' && transaction.date < globals.baseline(state) && transaction.date >= globals.referenceBaseline(state),
  )
  .reduce((acc, curr) => acc + curr.quantity * curr.upbc, 0);

// sum of inflows (all positive transactions which are not marked as internal or capitalIncome)
export const inflowsCurrent = (state) => depositTransactions(state)
  .filter((transaction) => transaction.quantity > 0 && transaction.label?.split('-')[0] !== 'investment' && transaction.date >= globals.baseline(state))
  .reduce((acc, curr) => acc + curr.quantity * curr.upbc, 0);

// sum of inflows (all positive transactions which are not marked as internal or capitalIncome) in the reference period
export const inflowsBaseline = (state) => depositTransactions(state)
  .filter(
    // eslint-disable-next-line max-len
    (transaction) => transaction.quantity > 0 && transaction.label?.split('-')[0] !== 'investment' && transaction.date < globals.baseline(state) && transaction.date >= globals.referenceBaseline(state),
  )
  .reduce((acc, curr) => acc + curr.quantity * curr.upbc, 0);
