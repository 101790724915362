/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useMemo, useRef, startTransition } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useSelector, shallowEqual } from 'react-redux';
import PivotTable from 'react-pivottable/PivotTable';
import { createSelector } from '@reduxjs/toolkit';
import renderers from './renderers';
import * as reportingSelectors from './reportingSelectors';
// INFO: the responsive components of Nivo require a parent with a defined width and height
// they can be defined as '100%', but that not always work in the layout;
// in other words: the parent of this component must prepare a space for the chart
// and the chart will fill it out

// pass any changes to the default chart props with layoutOptions below

const debugLevel = 0;

/**
 * Wrapper around the PivotTable component to display charts and reports.
 *
 * @param {string} reportId: string, the id of the report to display
 * @param {function} dataCallback: function, a function that will be applied to the data before displaying it (usually for limiting account and/or time range)
 * @param {function} (deprecated) filterCallback: deprecated, use dataCallback instead (better performance)
 * @param {object} layoutOptions: object, any changes to the default chart props (see below)
 *
 * @returns {React.Component} the chart
 *
 * Logic:
 * - initalise empty, with reportId, filterCallback and layoutOptions as props (props are memoized using memo on the bottom of this file)
 * - use selector to get the reportDef from the store, memoize that value (using createSelector)
 * - get data from selector based on reportDef, memoize that value
 * - display chart
 *
 * It can also be used in viewer mode. In viewer mode all those above do not have to happen, because the data is already in the store
 */
function ChartWrapper({ reportId, filterCallback, dataCallback, layoutOptions, isViewer }) {
  // const debugLevel = reportId === 'allAssetsSunburstxxxx' ? 0 : 3;

  if (debugLevel > 2) console.log(dayjs().format('mm:ss.SSS'), reportId, 'ChartWrapper rendered with reportId', reportId, 'filterCallback', filterCallback, 'layoutOptions', layoutOptions);

  const selectReports = createSelector(
    (state) => state?.reports,
    (r) => r,
  );
  const reports = useSelector(selectReports, shallowEqual); // it kept recalculating without shallowEqual
  const selectedReportDef = reports?.find((rep) => rep.id === reportId);

  const [displayedReportDef, setDisplayedReportDef] = useState(undefined); // which report is displayed in the current view
  const [dataObject, setDataObject] = useState(undefined); // data object for the current view
  const [rerenderControl, setRerenderControl] = useState(0); // change this to force a rerender (which will force getting the latest value from the selector --> see comments below)
  const componentIsRendering = useRef(false); // used to prevent updating the state in the useEffect below while the componnet is rendering

  // mark when rendering starts and ends
  useEffect(() => {
    componentIsRendering.current = false;
    return () => {
      componentIsRendering.current = true;
    };
  });

  useEffect(() => {
    if (debugLevel > 2) console.log(dayjs().format('mm:ss.SSS'), reportId, 'selectedReportDef changed, setting state of displayedReportDef to', selectedReportDef);
    setDisplayedReportDef(selectedReportDef);

    return () => {
      if (debugLevel > 2) console.log(dayjs().format('mm:ss.SSS'), reportId, 'cleanup of useEffect on selectedReportDef');
    };
  }, [selectedReportDef]);

  // select dataSettings based on the reportDef (so that we only run one selector)
  // use memo will execute the function on initialisation and if dependencies change, and return the value
  const dataObjectSelector = useMemo(() => {
    if (debugLevel > 2) console.log(dayjs().format('mm:ss.SSS'), reportId, 'displayedReportDef changed, calculating dataObjectSelector');
    switch (displayedReportDef?.dataScope) {
      case 'transactions':
        return reportingSelectors.selectorDatascopeTransactions;
      case 'assets':
        return reportingSelectors.selectorDatascopeAssets;
      case 'quotes':
        return reportingSelectors.selectorDatascopeQuotes;
      case 'snapshots':
        return reportingSelectors.selectorDatascopeSnapshots;
      default:
        return reportingSelectors.selectorDatascopeAssets; // because if there is nothing here, it complains that there is a different amount of selectors now
    }
  }, [displayedReportDef?.dataScope]);

  if (debugLevel > 2) console.log(dayjs().format('mm:ss.SSS'), reportId, 'correct selector function set, pointing the useSelector to the calculated selector function');
  const selectedDataObject = useSelector((state) => dataObjectSelector(state, filterCallback), shallowEqual); // all these selectors are memoized, so they will only run if the dependencies change

  const standardPivotTableProps = {
    // onChange: (s) => setDisplayedReportDef(s), // was here, but does not seem to be used for anything
    onChange: null,
    renderers,
  };

  // event listener
  useEffect(() => {
    const handleDebouncingFinished = () => {
      // only force re-render when there isn't already a re-render happening
      // this solution causes a warning "Cannot update a component (`ChartWrapper`) while rendering a different component (`ChartWrapper`)."
      // but it seems to work fine
      if (!componentIsRendering.current) setRerenderControl((prevControl) => prevControl + 1);
    };

    window.addEventListener('reportingDebouncingFinished', handleDebouncingFinished);

    return () => {
      window.removeEventListener('reportingDebouncingFinished', handleDebouncingFinished);
    };
  }, []);

  useEffect(() => {
    if (displayedReportDef) {
      if (debugLevel > 2) console.log(dayjs().format('mm:ss.SSS'), reportId, 'begin executing the data object selector useEffect');

      // apply filterCallback on data if provided and send them to state, from where they will be used by the chart
      const alteredDataSettings = { ...selectedDataObject };
      if (debugLevel > 2) console.log(dayjs().format('mm:ss.SSS'), reportId, 'data retrieved from reporting selector', selectedDataObject);

      if (selectedDataObject && filterCallback) {
        alteredDataSettings.data = (selectedDataObject.data || [])
          .filter((x) => x.date > (displayedReportDef.dateFilter?.from || new Date(1902, 0, 1).valueOf()) && x.date <= (displayedReportDef.dateFilter?.to || new Date(2200, 0, 1).valueOf()))
          .filter(filterCallback);
        // caution: this function is used also in Reporting as well as in PivotTableUI, if changed here - must be changed there as well
      }

      // if there is dataCallback, use it instead of the reporting selectors
      if (selectedDataObject && dataCallback) {
        alteredDataSettings.data = dataCallback(alteredDataSettings.data);
      }
      if (debugLevel > 2) console.log(dayjs().format('mm:ss.SSS'), reportId, 'local callback applied to data, setting state of data object to', alteredDataSettings);

      setDataObject(alteredDataSettings);
      if (debugLevel > 2) console.log(dayjs().format('mm:ss.SSS'), reportId, 'data object state set');
    }

    // handle evtl. debouncing in input selector
    // when using lodash.debounce, the selector returns stale data (because it returns something while the function is still being debounced)
    // so we have to force a rerender to get the correct data just to be sure
    // this solution was replaced with the "handleDebounceFinished" event listener above, but keeping it here just in case
    // const timeout = setTimeout(() => {
    //   setRerenderControl((prev) => prev + 1);
    //   if (debugLevel > 2) console.log(dayjs().format('mm:ss.SSS'), reportId, 're-rendering the component to get the correct data');
    // }, 1010); // this has to be only slightly longer than the debouncing period length

    return () => {
      if (debugLevel > 2) console.log(dayjs().format('mm:ss.SSS'), reportId, 'cleanup of useEffect on selectDataObject+displayedReportDef');
      // clearTimeout(timeout);
    };
  }, [selectedDataObject, displayedReportDef]);

  if (dataObject?.data?.length === 0) return <div />;

  return displayedReportDef && dataObject ? (
    <div className="grid w-full h-full">
      {/* prettier-ignore */}
      <PivotTable
        {...standardPivotTableProps}
        {...dataObject}
        {...displayedReportDef}
        layoutOptions={layoutOptions}
      />
    </div>
  ) : (
    <div>No report found</div>
  );
}
ChartWrapper.propTypes = {
  reportId: PropTypes.string.isRequired,
  filterCallback: PropTypes.func,
  dataCallback: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  layoutOptions: PropTypes.objectOf(PropTypes.any),
  isViewer: PropTypes.bool,
};
ChartWrapper.defaultProps = {
  filterCallback: () => true,
  dataCallback: (data) => data,
  layoutOptions: {},
  isViewer: false,
};

export const ChartWrapperMemo = React.memo(ChartWrapper);
export default ChartWrapperMemo;
