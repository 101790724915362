import getQuotes from '../../../redux/reducers/sharedThunks/getQuotes';
import { calculateDatesAndAssetIds } from '../../../redux/actions/data/helpers';

// INPUT transformations

// used to put quote object attributes in the exact order expected by table and grid layouts
export function categoryOrderedObject(account = null) {
  //  outer function takes a parameter returns a CALLBACK function for .map with that parameter already in
  return function realEstateOrdereObjectInnerFunc(quote) {
    // this has to exactly follow the column order laid out by gridLayout
    return {
      date: quote.date,
      source: quote.source,
      quoteBaseCurrency: quote.quoteBaseCurrency,
      currency: quote.currency,
      quote: quote.quote,
      pricePerSquareMetre: account?.valuationParameters?.livingArea ? quote.quoteBaseCurrency / Number(account?.valuationParameters?.livingArea) : null,
      originalPricePerSquareMetre: account?.valuationParameters?.livingArea ? quote.quote / Number(account?.valuationParameters?.livingArea) : null,
      assetId: quote.assetId,
    };
  };
}

// applies category-related final changes before sending to Grid
export function applyCategorySpecificChanges(_quotes, account = null) {
  return _quotes.map(categoryOrderedObject(account));
}

// OUTPUT transformations

// used inside of .map
// performs category - specific transformations after standard transformations of Grid output
// seqeunce of properties is no longer important
// this happens already after items with inputFlag = 'delete' have been added, so just take care of 'put'
export function outputTransformCategoryQuotes(account = null) {
  //  outer function takes a parameter returns a CALLBACK function for .map with that parameter already in
  return function outputTransformCategoryQuotesInnerFunc(item) {
    return {
      ...item,
      assetId: item.assetId || account.id,
      source: item.source || 'manual',
      importFlag: item.importFlag || 'put',
    };
  };
}

// TABLE

// used in table to get quotes from backend
export function handleSync(category, _quotes, dispatch) {
  dispatch(getQuotes(calculateDatesAndAssetIds(_quotes)));
}
