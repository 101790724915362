/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import { Auth, API } from 'aws-amplify';
import { getSchemaByCategory, realEstateTransaction, objectsOfValueTransaction, loanTransaction } from '@monestry-dev/schema';
import { setMessage } from '../../redux/actions/message';
import SelectProvider from './accountWorkflow/SelectProvider';
import RedirectToProvider from './accountWorkflow/RedirectToProvider';
import AccountData from './accountWorkflow/AccountData';
import RealEstateAccountData from './accountWorkflow/RealEstateAccountData';
import { getDataByAccount, postAccount, putAccount, postData } from '../../redux/reducers/data';
import FullPageOverlayContainer from '../../elements/FullPageOverlayContainer';
import { NavComponent } from './NavComponent';
import PensionAccountData from './accountWorkflow/PensionAccountData';
import ObjectsOfValueAccountData from './accountWorkflow/ObjectsOfValueAccountData';
import MetalsAccountData from './accountWorkflow/MetalsAccountData';
import UnlistedSharesAccountData from './accountWorkflow/UnlistedSharesAccountData';
import CryptoAccountData from './accountWorkflow/CryptoAccountData';

const debugLevel = process.env.REACT_APP_MDEBUG || 0;

/**
 * This component manages the new account workflow. Workflow is defined in the workflows object. Each subsequent step updates workflowStepStatus.
 * When workflowStepStatus has only true values, the Submit button is displayed.
 *
 * @param {string} category - was set by Dashboard > DashboardCategory when the user clicked on "Add New" button
 * @param {object} addModeState - controls the display of this add account dialog { visible, category }
 * @param {func} setAddModeState - sets the addModeState
 * @param {object} addAccountState - the state of the add account workflow { category, userInput, workflowStepStatus, displayedStep (?) }
 *
 * If addAccountState is present on initialisation, it means the workflow is being restarted (e.g. after redirecting to openbanking provider via AddAccountCallbakReceiver and Dashboard)
 *
 * @returns React.Component
 */
export default function AddAccount({ category, addModeState, setAddModeState, addAccountState }) {
  const myRef = useRef(null);
  useEffect(() => {
    myRef.current.scrollIntoView({ behavior: 'instant' });
  }, []);

  const dispatch = useDispatch();

  const { t } = useTranslation('app', { keyPrefix: 'addAccount' });

  const [userInput, setUserInput] = useState(addAccountState?.userInput || {}); // being assembled during the workflow from the inputs of different child components
  const [loading, setLoading] = useState(false); // true when the user has submitted the form and we are waiting for the backend to respond
  const [displayedStep, setDisplayedStep] = useState(addAccountState?.displayedStep || 0); // the index of the component that is currently displayed
  const [messageToShowBeforeNext, setMessageToShowBeforeNext] = useState(null); // if this is not null, show the message in a popup before moving to the next step
  const [signalNext, setSignalNext] = useState(false); // passes the event of user clicking NEXT in NAV COMPONENT to the current component

  //------------------------------------------------------------
  // DEFINE ALL WORKFLOWS
  //------------------------------------------------------------

  const workflows = {
    deposits: [SelectProvider, RedirectToProvider, AccountData],
    stocks: [SelectProvider, RedirectToProvider, AccountData],
    realEstate: [RealEstateAccountData],
    loans: [SelectProvider, RedirectToProvider, AccountData],
    pension: [PensionAccountData],
    objectsOfValue: [ObjectsOfValueAccountData],
    metals: [MetalsAccountData],
    unlistedShares: [UnlistedSharesAccountData],
    crypto: [CryptoAccountData],
  };
  const currentWorkflow = workflows[category]; // an array that holds the components constituting the workflow in the proper sequence

  //------------------------------------------------------------
  // SET UP THIS WORKFLOW'S STATE
  //------------------------------------------------------------

  // set "workflowStepStatus" to an array of the same amount of items as the workflow calculated in the "workflows" object
  const [workflowStepStatus, setWorkflowStepStatus] = useState(addAccountState?.workflowStepStatus || [...Array(currentWorkflow.length)]);

  // update the status of a single step in the workflow to 'status'; this function will be passed to the child components so that they may update this state
  function updateWorkflowStatus(step, status) {
    if (debugLevel > 2) console.info(`updateWorkflowStatus: step ${step} status ${status}`);

    const newStatus = [...workflowStepStatus];
    newStatus[step] = status;
    setWorkflowStepStatus(newStatus);
  }

  // workflow is ready (and the Submit button is displayed) when all of its steps are ready
  const formReady = workflowStepStatus.every((step) => step === true);
  if (debugLevel > 2) console.info('formReady', formReady, workflowStepStatus);

  //------------------------------------------------------------
  // (REAL ESTATE ONLY) CREATE A TRANSACTION
  //------------------------------------------------------------

  async function createRealEstateTransaction(accountId) {
    const dataArray = [
      realEstateTransaction.validateSync({
        id: nanoid(),
        accountId,
        date: dayjs.utc(userInput.date).startOf('day'),
        category: 'realEstate',
        originalPrice: userInput.originalPrice,
        currency: userInput.currency,
        price: null,
        amount: 1,
        transactionType: 'purchase',
        importFlag: 'post',
      }),
    ];
    try {
      await dispatch(
        postData({
          data: dataArray,
          category: 'realEstate',
          accountId,
        }),
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(setMessage('addTransactionManually'));
    }
  }

  //------------------------------------------------------------
  // (OBJECTS OF VALUE ONLY) CREATE A TRANSACTION
  //------------------------------------------------------------

  async function createObjectsOfValueTransaction(_userInput, accountId) {
    let dataArray;
    try {
      // dataArray = [objectsOfValueTransaction.validateSync({
      dataArray = [
        objectsOfValueTransaction.cast({
          id: nanoid(),
          accountId,
          date: dayjs.utc(_userInput.date).startOf('day').valueOf(),
          category: 'objectsOfValue',
          quantity: _userInput.quantity,
          transactionType: 'purchase',
          upac: _userInput.userInputPurchaseValueAccountCurrency / _userInput.quantity,
          upbc: _userInput.userInputPurchaseValueBaseCurrency / _userInput.quantity,
          uptc: null,
          transactionCurrency: null,
          accountCurrency: _userInput.currency,
          assetCurrency: _userInput.currency,
          importFlag: 'post',
          description: 'Initial',
        }),
      ];
    } catch (err) {
      console.error('createObjectsOfValueTransaction: error', err);
      throw err;
    }
    try {
      await dispatch(
        postData({
          data: dataArray,
          category: 'objectsOfValue',
          accountId,
        }),
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(setMessage('addTransactionManually'));
    }
  }

  //------------------------------------------------------------
  // (UNLISTED SHARES ONLY) CREATE A TRANSACTION
  //------------------------------------------------------------

  async function createUnlistedSharesTransaction(_userInput, accountId) {
    let dataArray;
    try {
      // dataArray = [objectsOfValueTransaction.validateSync({
      dataArray = [
        objectsOfValueTransaction.cast({
          id: nanoid(),
          accountId,
          assetId: accountId,
          date: dayjs.utc(_userInput.date).startOf('day').valueOf(),
          category: 'unlistedShares',
          quantity: _userInput.quantity,
          upac: _userInput.upac / _userInput.quantity,
          upbc: _userInput.upbc / _userInput.quantity,
          uptc: null,
          transactionCurrency: null,
          accountCurrency: _userInput.currency,
          assetCurrency: _userInput.currency,
          importFlag: 'post',
          description: 'Initial',
        }),
      ];
    } catch (err) {
      console.error('createUnlistedSharesTransaction: error', err);
      throw err;
    }
    try {
      await dispatch(
        postData({
          data: dataArray,
          category: 'unlistedShares',
          accountId,
        }),
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(setMessage('addTransactionManually'));
    }
  }

  //------------------------------------------------------------
  // (LOANS ONLY) CREATE A TRANSACTION
  //------------------------------------------------------------

  async function createLoansTransaction(_userInput, accountId) {
    // if threre is no balance in the incoming data, we cannot create a transaction
    if (!_userInput.balance) return;

    let dataArray;
    try {
      dataArray = [
        loanTransaction.cast({
          id: nanoid(),
          accountId,
          date: dayjs.utc().startOf('day').valueOf(),
          category: 'loans',
          accountCurrency: _userInput.currency,
          assetCurrency: _userInput.currency,
          quantity: -1 * Math.abs(Number(_userInput.balance)), // if this is a balance, then it is negative
          quantityInterest: 0,
          upac: 1,
          description: t('currentBalance'),
          importFlag: 'post',
        }),
      ];
    } catch (err) {
      console.error('createUnlistedSharesTransaction: error', err);
      throw err;
    }
    try {
      await dispatch(
        postData({
          data: dataArray,
          category: 'loans',
          accountId,
        }),
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(setMessage('addTransactionManually'));
    }
  }

  //------------------------------------------------------------
  // HANDLER: CREATE ACCOUNT
  //------------------------------------------------------------

  // if this is a workflow where several accounts can be created at the same time (when several accounts come from provider, like in deposits and stocks)
  // userInput will only have the common information like countryCode and provider, while accountArray will have all the account-specific information
  // _userInput is one of the accounts from accountArray
  async function dispatchUserInput(_userInput, accountData = null) {
    // "flatten" the object (if there are .provider and .accountArray properties, then move everything from there into the root of the object)
    const flattenedUserInput = { ..._userInput, ..._userInput.provider, ...accountData }; // if .provider and .accountData do not exist, they get ignored

    // if there is a provider, put providerName and providerAccountId stuff into the provider tag
    if (_userInput.provider) {
      flattenedUserInput.tags = {
        ...flattenedUserInput.tags,
        provider: {
          [accountData.providerName]: {
            providerAccountId: accountData.providerAccountId,
            providerInstitutionId: userInput.provider.providerInstitutionId,
            providerAccessId: userInput.providerAccessId, // bankConnectionId for finapi, requisitionId for gocardless
          },
        },
      };
    }

    // if there is a connectedDepositMode === 'existingAccount', put the account id into the right field
    // if (accountData && accountData.connectedDepositMode === 'existingAccount') {
    if (flattenedUserInput.connectedDepositMode === 'existingAccount') {
      // 240709 unclear why connectedDEspositAccountObject should come from and not from userInput; adding it as a fallback
      // note: this will always be one id, because this is how the form is built
      flattenedUserInput.connectedDepositAccounts = [flattenedUserInput.connectedDepositAccountObject];
    }

    // (STOCKS + CRYPTO ONLY) if there is a connectedDepositMode === 'createTechnicalAccount', create a new deposit account
    let newDepositAccountId;
    if (flattenedUserInput.connectedDepositMode === 'createTechnicalAccount') {
      newDepositAccountId = nanoid();
      // update stock account payload with the new connected deposit account id
      flattenedUserInput.connectedDepositAccounts = [newDepositAccountId];
      // create new deposit account payload
      const depositAccount = {
        id: newDepositAccountId,
        name: flattenedUserInput.name,
        bankName: flattenedUserInput.brokerName,
        currency: flattenedUserInput.currency, // STOCKS do not use currency for their account, so we use this field for currency of the technical deposit account
        countryCode: flattenedUserInput.countryCode,
        depositType: 'savings-account',
        syncType: 'manual',
        tags: {},
      };
      const payloadDeposit = getSchemaByCategory('deposits', 'account').cast(depositAccount);
      dispatch(postAccount({ data: payloadDeposit, category: 'deposits' })); // async?
    }

    console.log('flattenedUserInput', flattenedUserInput); // ma miec providerAccountId

    // cast the object to the right schema
    const accountSchema = getSchemaByCategory(category, 'account');
    let payload;
    try {
      const validated = accountSchema.validateSync(flattenedUserInput); // this has better error reporting than yup.cast
      console.log('validated', validated);
      payload = accountSchema.cast(validated, { stripUnknown: true }); // this will cast all types and remove unregisteter properties
      console.log('payload', payload);
    } catch (error) {
      console.error('Error casting accountSchema', error);
      console.error('error.inner', error.inner);
      // spell out errors for yup.cast
      if (error.inner) {
        error.inner.forEach((err) => {
          console.error(`Field: ${err.path}, Error: ${err.message}`);
        });
      }
      throw new Error('Validation error (add account).');
    }

    // post new account
    try {
      const resultDispatch = await dispatch(postAccount({ data: payload, category }));
      console.log('DEBUG result', JSON.stringify(resultDispatch, null, 2));
      if (resultDispatch.error) throw new Error('AddAccount > dispatchUserInput: Dispatch postAccount returned an error');

      // if the following fails, consider the account created, but inform user that transaction must be created manually
      try {
        // this can only work if user provided 'name', 'date' and 'originalPrice' in the realEstate form
        if (resultDispatch.meta.requestStatus === 'fulfilled') {
          if (category === 'realEstate' && userInput.name && userInput.date && userInput.originalPrice) {
            // the form has fields for the purchase transaction, post it once account has been created
            await createRealEstateTransaction(resultDispatch.payload.accounts[0].id);
          }
          if (category === 'objectsOfValue') {
            // the form has fields for the purchase transaction, post it once account has been created
            await createObjectsOfValueTransaction(flattenedUserInput, resultDispatch.payload.accounts[0].id);
          }
          if (category === 'unlistedShares') {
            // the form has fields for the purchase transaction, post it once account has been created
            await createUnlistedSharesTransaction(flattenedUserInput, resultDispatch.payload.accounts[0].id);
          }
          if (category === 'loans') {
            // the form has fields for the purchase transaction, post it once account has been created
            await createLoansTransaction(flattenedUserInput, resultDispatch.payload.accounts[0].id);
          }
        }

        // if syncType is automatic, then intiate the sync (asynchronously) to retrieve data
        if (payload.syncType && payload.syncType === 'automatic') {
          dispatch(getDataByAccount({ category, accountId: resultDispatch.payload.accounts[0].id }));
        }
      } catch (error) {
        console.error('Error: AddAccount: createTransaction failed', error);
        setLoading(false);
        window.dispatchEvent(
          new CustomEvent('setAlert', {
            detail: {
              id: 'addTransactionManually',
              caller: resultDispatch.id, // accountId
              callbackOk: () => {},
            },
          }),
        );
      }
      return resultDispatch;
    } catch (error) {
      console.error('Error: AddAccount: postAccount failed', error);
      setLoading(false);
      dispatch(setMessage('accountCouldNotBeCreated'));
      throw error;
    }
  }

  // ↓↓ main SUBMIT handler for the AddAccount workflows
  async function handleSubmit(e) {
    console.info('handleSubmit: start', 'workflowStepStatus', JSON.stringify(workflowStepStatus, null, 2), 'userInput', JSON.stringify(userInput, null, 2));
    // if the last component in the workflow has been validated, the user has finished the workflow
    if (workflowStepStatus.every((item) => !!item)) {
      // we can now add the account to the database
      setLoading(true);
      // in case there are several accounts in the payload, split them up and post them one by one (in parallel)
      // accountArray is used for provider-based accounts, for one "AddAccount" workflow we may have several accounts
      let promises = [];
      if (userInput.accountArray) {
        console.info('handleSubmit: accountArray detected.');
        // in this scenario userInput has .accountArray, which has all the account-relevant fields
        // but userInput still has some overall fields, like countryCode, provider, etc.

        // we need to do putAccounts in sequence, otherwise Cognito slots get updated at the same time and only one account gets updated
        // (see PRD-1610)
        // promises = userInput.accountArray
        //   .filter((accountData) => accountData.isImported) // we only are adding those with isImported
        //   .map((accountData) => dispatchUserInput(userInput, accountData));
        const accountsToSubmit = userInput.accountArray.filter((accountData) => accountData.isImported);

        promises.push(
          (async () => {
            // eslint-disable-next-line no-restricted-syntax
            for (const a of accountsToSubmit) {
              // eslint-disable-next-line no-await-in-loop
              const result = await dispatchUserInput(userInput, a);
              console.log(new Date().toISOString(), 'DEBUG result', JSON.stringify(result, null, 2));
            }
          })(),
        );
      } else {
        promises = [dispatchUserInput(userInput)];
      }

      // if there is userInput.reconcileAccounts, it means we need to take the new providerAccountId and providerAssetIds and update it to all existing accounts
      // which have been already been updated in userInput.existingAccountsUpdated by RedirectToProvider
      if (userInput.existingAccountsUpdated && userInput.existingAccountsUpdated.length > 0) {
        console.info('AddAccount: detected existing accounts to update', userInput.existingAccountsUpdated);
        promises.push(...userInput.existingAccountsUpdated.map((ea) => dispatch(putAccount({ data: ea, category }))));
      }

      // if this is a GC account (running with useInput.reconcileAccounts) and if there is a previous requisition id, we need to also remove the previous requisition, otherwise it will remain there
      if (userInput.reconcileAccounts && userInput.removedGCRequisitonId) {
        console.info('AddAccount: detected GC account to remove', userInput.removedGCRequisitonId);
        const session = await Auth.currentSession();

        promises.push(
          API.del('myAPI', `deposits/requisition/${userInput.removedGCRequisitonId}`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: session.idToken.jwtToken,
            },
          }),
        );
      }

      try {
        const result = await Promise.all(promises);

        // close add account dialog
        setAddModeState({ visible: false, category: null, addAccountState: undefined });
      } catch (err) {
        console.error('handleSubmit: error', err);
        setLoading(false);
        dispatch(setMessage('accountCouldNotBeCreated', 'Validation error.'));
      }
    }
  }

  //------------------------------------------------------------
  // PREPARE WORKFLOW STATUS INDICATOR
  //------------------------------------------------------------

  const steps = currentWorkflow.map((item, index) => ({
    name: item.name,
    status: index === displayedStep ? 'current' : index < displayedStep ? 'complete' : 'upcoming',
  }));

  function handleClose(e) {
    e.preventDefault();
    setAddModeState({ ...addModeState, visible: false });
  }

  const Component = currentWorkflow[displayedStep];

  return (
    <FullPageOverlayContainer closeCallback={handleClose} fixedHeight noPadding>
      <main className="flex flex-col h-full" ref={myRef}>
        <div className="flex-grow px-2 sm:px-8">
          <div className="px-4 py-5 sm:px-8 sm:py-8">
            <Component
              category={category}
              index={displayedStep}
              setDisplayedStep={setDisplayedStep}
              workflowStepStatus={workflowStepStatus}
              updateWorkflowStatus={updateWorkflowStatus}
              userInput={userInput}
              setUserInput={setUserInput}
              setMessageToShowBeforeNext={setMessageToShowBeforeNext}
              signalNext={signalNext}
              setSignalNext={setSignalNext}
            />
          </div>
        </div>
        <NavComponent
          setAddModeState={setAddModeState}
          displayedStep={displayedStep}
          setDisplayedStep={setDisplayedStep}
          workflowStepStatus={workflowStepStatus}
          steps={steps}
          loading={loading}
          messageToShowBeforeNext={messageToShowBeforeNext}
          setMessageToShowBeforeNext={setMessageToShowBeforeNext}
          handleSubmit={handleSubmit}
          setSignalNext={setSignalNext}
        />
      </main>
    </FullPageOverlayContainer>
  );
}
AddAccount.propTypes = {
  category: PropTypes.string.isRequired,
  addModeState: PropTypes.objectOf(PropTypes.any).isRequired,
  setAddModeState: PropTypes.func.isRequired,
  addAccountState: PropTypes.objectOf(PropTypes.any),
};
AddAccount.defaultProps = {
  addAccountState: null,
};
