import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Auth, API } from 'aws-amplify';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid';
import { setMessage } from '../../redux/actions/message';
import MiniSpinner from '../../misc/MiniSpinner';

// there is a second or two so required to generate billing portal link, during which the link won't work; the component will show spinners during that time

export default function Subscription() {
  const [portalSessionURL, setPortalSessionURL] = useState(null);
  const [loading, setLoading] = useState(true);
  const [enabled, setEnabled] = useState(false);

  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();

  // get link to customer portal session from backend
  useEffect(() => {
    async function fetchCustomerPortalSessionURL() {
      try {
        setLoading(true);
        const session = await Auth.currentSession();
        const myInit = {
          body: { returnURL: `https://monestry.de/${i18n.language}/app` },
          headers: {
            'Content-Type': 'application/json',
            Authorization: session.idToken.jwtToken,
          },
        };
        const response = await API.post('myAPI', 'customer/getStripePortalURL', myInit);
        setPortalSessionURL(response.url); // just the url as string
        setLoading(false);
        setEnabled(true);
      } catch (error) {
        dispatch(setMessage('generalError'));
        setLoading(false);
      }
    }

    fetchCustomerPortalSessionURL();
  }, []);

  function handleOpen() {
    // open link in new tab
    if (portalSessionURL) window.open(portalSessionURL, '_blank', 'noopener,noreferrer');
  }

  const buttonFormatting = 'text-brandBlue-400 border px-3 py-2 border-brandBlue-400 hover:bg-gray-50 rounded-md font-bold sm:text-sm flex gap-1.5';
  const buttonText = enabled ? t('app:general.open') : t('app:general.error');

  return (
    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5 text-gray-700 font-normal">
      <div className="space-y-6 sm:space-y-5">
        <div className="space-y-6 sm:space-y-5">
          <div>
            <p className="mt-1 text-sm text-gray-500">{t('app:settings.billing.leadingText1')}</p>
            <p className="mt-1 text-sm text-gray-500">{t('app:settings.billing.leadingText2')}</p>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:justify-items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5 text-sm">
            <p>{t('app:settings.billing.currentSubscription')}</p>
            <button type="button" className={buttonFormatting} onClick={handleOpen} disabled={!enabled}>
              {loading ? <MiniSpinner className="w-4 h-4 animate-spin text-gray-300" /> : buttonText}
              <ArrowTopRightOnSquareIcon className="text-gray-400 h-4 w-4 mt-0.5" />
            </button>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:justify-items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5 text-sm">
            <p>{t('app:settings.billing.upgradePlan')}</p>
            <button type="button" className={buttonFormatting} onClick={handleOpen} disabled={!enabled}>
              {loading ? <MiniSpinner className="w-4 h-4 animate-spin text-gray-300" /> : buttonText}
              <ArrowTopRightOnSquareIcon className="text-gray-400 h-4 w-4 mt-0.5" />
            </button>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:justify-items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5 text-sm">
            <p>{t('app:settings.billing.paymentMethod')}</p>
            <button type="button" className={buttonFormatting} onClick={handleOpen} disabled={!enabled}>
              {loading ? <MiniSpinner className="w-4 h-4 animate-spin text-gray-300" /> : buttonText}
              <ArrowTopRightOnSquareIcon className="text-gray-400 h-4 w-4 mt-0.5" />
            </button>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:justify-items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5 text-sm">
            <p>{t('app:settings.billing.downloadInvoices')}</p>
            <button type="button" className={buttonFormatting} onClick={handleOpen} disabled={!enabled}>
              {loading ? <MiniSpinner className="w-4 h-4 animate-spin text-gray-300" /> : buttonText}
              <ArrowTopRightOnSquareIcon className="text-gray-400 h-4 w-4 mt-0.5" />
            </button>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:justify-items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5 text-sm">
            <p>{t('app:settings.billing.cancelSubscription')}</p>
            <button type="button" className={buttonFormatting} onClick={handleOpen} disabled={!enabled}>
              {loading ? <MiniSpinner className="w-4 h-4 animate-spin text-gray-300" /> : buttonText}
              <ArrowTopRightOnSquareIcon className="text-gray-400 h-4 w-4 mt-0.5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
