/* eslint-disable no-promise-executor-return */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import dayjs from 'dayjs';
import { getSchemaByCategory } from '@monestry-dev/schema';
import { postAccount } from '../../redux/reducers/data';
import { postMixedData } from '../../redux/actions/data';
import getQuotes from '../../redux/reducers/sharedThunks/getQuotes';
import { putQuotes } from '../../redux/reducers/quotes';
import { postProject } from '../../redux/actions/projects';
import seedDataInput from '../../pages/seedInitData';
import MiniSpinner from '../../misc/MiniSpinner';

const debugLevel = process.env.REACT_APP_MDEBUG || 3;

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

// takes seedDataInput and dispatches all API calls to seed the demo data
// handles cancellation via cancelSignal
export async function seedDemoData(dispatch, navigate, t, i18n, promisesManagedByParent, rGetQuotes, postCallsDone) {
  const cancelSignal = false; // TODO remove it from here once the component is stable

  if (debugLevel > 2) console.log('seedDemoData: starting');
  // run a few API calls via axios to seed the demo data
  const aaplDate = dayjs.utc().subtract(19, 'months').startOf('day').valueOf();
  const tslaDate = dayjs.utc().subtract(18, 'months').startOf('day').valueOf();

  if (debugLevel > 2) console.log('seedDemoData: dispatching quotes');
  // eslint-disable-next-line no-param-reassign
  rGetQuotes = await dispatch(
    getQuotes({
      assets: [
        { providerAssetId: 'AAPL', assetId: 'dummyAAAPPPPLLL', currency: 'USD', category: 'stocks' },
        { providerAssetId: 'TSLA', assetId: 'dummyTTSSSSLLAA', currency: 'USD', category: 'stocks' },
      ],
      dates: [aaplDate, tslaDate],
    }),
  );

  const AAPL = rGetQuotes?.payload?.find((q) => q.assetId === 'dummyAAAPPPPLLL' && String(q.date) === String(aaplDate))?.quote;
  // TSLA was split, so the original price needs to be multiplied by 3 (FMP returns rebased quotes only)
  // eslint-disable-next-line no-unsafe-optional-chaining
  const TSLA = rGetQuotes?.payload?.find((q) => q.assetId === 'dummyTTSSSSLLAA' && String(q.date) === String(tslaDate))?.quote * 3;

  const payload = seedDataInput(t); // pass the translation function to have labels translated
  // get quotes from API and set prices in both stocks and deposit transactions
  // stocks
  payload.transactions[0].transactionOriginalPrice = AAPL;
  payload.transactions[1].transactionOriginalPrice = TSLA;
  // deposits
  payload.transactions[3].fxAmount = AAPL * payload.transactions[0].transactionAmount;
  payload.transactions[5].fxAmount = TSLA * payload.transactions[1].transactionAmount;

  // useEffect cleanup sets aborted to true
  // my guess is that when useEffect is executed, all promises land in the macrotask queue at the time of execution
  // when StrictMode re-mounts this component, getQuotes above are running (and are being aborted as planned), but all promises below have been queued and are not running yet
  // eslint-disable-next-line no-param-reassign
  promisesManagedByParent = [];
  if (!cancelSignal.aborted) {
    if (!postCallsDone.current[0]) {
      payload.accounts.forEach((a) => {
        // validate accounts with schema
        const schema = getSchemaByCategory(a.category, 'account');
        try {
          const validationResult = schema.validateSync(a);
        } catch (e) {
          console.error('error when validating schema for', a, e);
        }

        if (!cancelSignal.aborted) promisesManagedByParent.push(dispatch(postAccount({ category: a.category, data: a, cancelSignal })));
        if (debugLevel > 2) console.log('starting dispatch postAccount for', a, 'aborted is', JSON.stringify(cancelSignal));
      });

      // create all accounts first
      const response1 = await Promise.allSettled(promisesManagedByParent);
      // if any of the promises failed, log the error
      response1.forEach((r) => {
        // response is the RTK output object; if it has error property, it means the promise was rejected
        if (r.error) console.error('error in account promise', JSON.stringify(r.error, null, 2));
      });

      // mark accounts post calls as done
      postCallsDone.current[0] = true;
    }

    // validate transactions with schema
    payload.transactions.forEach((x) => {
      const schema = getSchemaByCategory(x.category, 'transaction');
      try {
        const validationResult = schema.validateSync(x);
      } catch (e) {
        console.error('error when validating schema for', x, e);
      }
    });

    // reset promises array from parent if the thing hasn't been cancelled and if this post has not been done during a previous render
    // eslint-disable-next-line no-param-reassign
    promisesManagedByParent = [];
    if (!postCallsDone.current[1]) {
      if (!cancelSignal.aborted) promisesManagedByParent.push(dispatch(postMixedData(payload.transactions)));
      if (debugLevel > 2) console.log('starting dispatch postAccount for postMixedData', 'aborted is', JSON.stringify(cancelSignal));

      // vv this will only work if quotes are realEstate
      if (!cancelSignal.aborted) {
        promisesManagedByParent.push(
          dispatch(
            putQuotes({
              body: payload.quotes,
              category: 'realEstate',
              accountId: payload.quotes[0].assetId,
            }),
          ),
        );
      }

      // mark transactions post calls as done
      postCallsDone.current[1] = true;
    }

    if (!postCallsDone.current[2]) {
      if (debugLevel > 2) console.log('starting dispatch postAccount for projects', 'aborted is', JSON.stringify(cancelSignal), 'postCallsDone[2]', JSON.stringify(postCallsDone.current[2]));
      if (!cancelSignal.aborted && !postCallsDone.current[2]) payload.projects.forEach((p) => promisesManagedByParent.push(dispatch(postProject(p))));
      if (debugLevel > 2) console.log('starting dispatch postAccount for projects', 'aborted is', JSON.stringify(cancelSignal));

      // mark projects post calls as done
      postCallsDone.current[2] = true;
    }

    const response2 = await Promise.allSettled(promisesManagedByParent);
    // if any of the promises failed, log the error
    response2.forEach((r) => {
      if (r.error) console.log('error in transaction / quote promise', JSON.stringify(r.error, null, 2));
    });
  }
}

// this component needs to be bypassed if this is not the first time the user is using the app (i.e. the one after registration)
// store is still in initial state at this stage (because only the Dashboard or Reporting gets data), so that is not helpful
// we will check if user has any tours in his profile, and if yes, redirect away to /app;
// (if the user opened Dashboard at least once, they would have seen at least one tour)

export default function Prep() {
  // upon initialising it should run a 5 second timer and redirect to /app/dashboard
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(['site'], { keyPrefix: 'register.prep' });
  const [showContent, setShowContent] = useState(false); // so that it does not show content before decision on whether to show this component or not has been taken
  // (federated signIn and signUp only can forward to /getready, not directly to /app/)

  // refs hold values across renders and do not trigger re-renders (state changes do)
  const postCallsDone = useRef([false, false, false]); // accounts, transactions, projects (quotes we need to repeat before each posts anyway)
  const seedDemoDataIsRunning = useRef(false);
  const userRef = useRef(null);

  // get user to component state first (so that it does not cause duplications due to uncancelled promises when re-rendering this component)
  useEffect(() => {
    async function setUser() {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      userRef.current = user;

      const selectToursSeen = user.attributes['custom:tours_seen'] && JSON.parse(user.attributes['custom:tours_seen']);

      if (selectToursSeen && selectToursSeen.length > 0) {
        navigate(`/${i18n.language}/app/dashboard`);
      } else {
        setShowContent(true);
      }

      if (debugLevel > 2) console.log('Prep: user retrieved successfully');
    }

    if (!userRef.current) setUser();
  }, []);

  // when user is updated in component state, run seedDemoData
  // make sure only one copy of seedDemoData runs at the same time, as they are POST calls
  // postCallsDone stores the status of the needed POST calls, so that they are not repeated
  useEffect(() => {
    let rGetQuotes;
    const promises = [];

    // only execute if user is set, because we need to know if the user has seen any tours
    // (if not, then this is a first-time user and we need to get data)
    if (userRef.current) {
      // declare API calls here, so that we may cancel them from the useEffect cleanup
      const selectToursSeen = userRef.current.attributes['custom:tours_seen'] && JSON.parse(userRef.current.attributes['custom:tours_seen']);
      if (debugLevel > 2) console.log('Prep: selectToursSeen', selectToursSeen);

      if (!selectToursSeen || selectToursSeen.length === 0) {
        // if seedDemoData is running (triggered by an earlier render), wait for it to finish
        while (seedDemoDataIsRunning.current) {
          // eslint-disable-next-line no-await-in-loop
          new Promise((resolve) => setTimeout(resolve, 333)).then(() => console.log('Prep: waiting for seedDemoData to be free'));
        }

        // if seedDemoData is not running (triggered by an earlier render), start it
        if (!seedDemoDataIsRunning.current) {
          seedDemoDataIsRunning.current = true;
          seedDemoData(dispatch, navigate, t, i18n, promises, rGetQuotes, postCallsDone).then(
            () => {
              if (debugLevel > 2) console.info('Prep > seedDemoData: finished without errors, moving on to Dashboard');
              navigate(`/${i18n.language}/app/dashboard`);
            },
            (e) => {
              console.error('Error: Prep > seedDemoData', e);
              if (debugLevel > 2) console.info('Prep > seedDemoData: errors caught, moving on to Dashboard');
              navigate(`/${i18n.language}/app/dashboard`);
            },
          );
          seedDemoDataIsRunning.current = false;
        }
      }
    }

    // cleanup / abort sequence
    // ATTENTION: this cleanup callback is created on the first render; it will hold on to the variables from that first render (including state!)
    // the only way to pass "current" variables is to "tunnel" them through a ref
    return () => {
      if (debugLevel > 2) console.log('Prep: cleanup started, resetting seedDemoDataIsRunning');
      seedDemoDataIsRunning.current = false;
      if (typeof rGetQuotes === 'function') rGetQuotes.abort();
      // isAbortedRef.current = { aborted: true };
      promises.forEach((p) => {
        const pr = promises.pop();
        if (typeof pr === 'function') pr.abort();
      });
    };
  }, [userRef.current]);

  return showContent ? (
    <div className="relative bg-white">
      <div className="mx-auto lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
        <div className="px-6 pb-24 pt-10 sm:pb-32 lg:col-span-7 lg:px-0 lg:pb-56 lg:pt-60 lg:pl-24 lg:min-h-screen xl:pt-80 xl:col-span-6">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h1 className="mt-24 text-4xl font-bold tracking-tight text-gray-900 sm:mt-10 sm:text-6xl">{t('header')}</h1>
            <p className="mt-6 text-lg leading-6 text-gray-600">{t('body')}</p>
            <div className="mt-8 flex items-center gap-x-6">
              <MiniSpinner className="w-8 h-8 text-gray-300 animate-spin" />
            </div>
          </div>
        </div>
        <div className="relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
          <img
            className="aspect-[3/2] w-full bg-gray-50 object-cover lg:absolute lg:inset-0 lg:aspect-auto lg:h-full"
            src="https://images.unsplash.com/photo-1498758536662-35b82cd15e29?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2102&q=80"
            alt=""
          />
        </div>
      </div>
    </div>
  ) : (
    <div />
  );
}
