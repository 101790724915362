// indexedDBUtils.js

const DB_NAME = 'monestry';
const STORE_NAME = 'redux';
const DB_VERSION = 1;

// Initialize the IndexedDB
const openDB = () => new Promise((resolve, reject) => {
  const request = indexedDB.open(DB_NAME, DB_VERSION);

  // Handle upgrades (creating object stores)
  request.onupgradeneeded = (event) => {
    const db = event.target.result;
    if (!db.objectStoreNames.contains(STORE_NAME)) {
      db.createObjectStore(STORE_NAME, { keyPath: 'sliceName' });
    }
  };

  request.onsuccess = (event) => {
    resolve(event.target.result);
  };

  request.onerror = (event) => {
    reject(new Error(`Error opening IndexedDB: ${event.target.errorCode}`));
  };
});

// Save a slice to IndexedDB
export const saveSliceToIndexedDB = async (sliceName, encryptedData) => {
  // console.info(`Saving slice to IndexedDB: ${sliceName}`);
  const db = await openDB();
  const transaction = db.transaction(STORE_NAME, 'readwrite');
  const store = transaction.objectStore(STORE_NAME);
  const request = store.put({ sliceName, encryptedData });

  return new Promise((resolve, reject) => {
    request.onsuccess = () => resolve();
    request.onerror = (event) => reject(new Error(`Error saving slice: ${event.target.errorCode}`));
  });
};

// Load a slice from IndexedDB
export const loadSliceFromIndexedDB = async (sliceName) => {
  const db = await openDB();
  const transaction = db.transaction(STORE_NAME, 'readonly');
  const store = transaction.objectStore(STORE_NAME);
  const request = store.get(sliceName);

  return new Promise((resolve, reject) => {
    request.onsuccess = (event) => {
      if (event.target.result) {
        resolve(event.target.result.encryptedData);
      } else {
        resolve(null); // No data found
      }
    };
    request.onerror = (event) => reject(new Error(`Error loading slice: ${event.target.errorCode}`));
  });
};
