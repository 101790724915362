/* eslint-disable import/no-duplicates */
/* eslint-disable react/jsx-filename-extension */
import React, { Suspense } from 'react';
import { Amplify, Hub } from 'aws-amplify';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
// import './index.css';
// import './css/index.css';
import { Provider } from 'react-redux';
import awsconfig from './aws-config';
import App from './App';
import Spinner from './elements/Spinner';
import * as serviceWorker from './serviceWorker';
import store from './store';
import hubListener from './hubListener';
import initializeApp from './redux/persist/initializeApp'; // handles rehydration of persisted slices

import './i18n'; // this only runs the code in the file without importing anything

Hub.listen(/.*/, (capsule) => hubListener(capsule, store));

Amplify.configure(awsconfig);

if (process.env.REACT_APP_ENV_SUFFIX === 'dev') {
  window.myAppStore = store;
}

const container = document.getElementById('root');
const root = createRoot(container);
initializeApp(store).then(() => {
  root.render(
    <React.StrictMode>
      <Suspense fallback={<Spinner />}>
        <Provider store={store}>
          <App />
        </Provider>
      </Suspense>
    </React.StrictMode>,
  );
});

// ReactDOM.render(

//   document.getElementById('root'),
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
