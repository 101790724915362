/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { Hub, Auth } from 'aws-amplify';
import slicesToPersist from './slicesToPersist';
import loadPersistedSlice from './loadPersistedSlice';

export default async function initializeApp(store) {
  // this block runs only once when the app is initialized
  try {
    // Check if the user is already authenticated and get the current session
    let sub;
    try {
      const session = await Auth.currentSession();
      ({ sub } = session.getIdToken().payload);
    } catch (error) {
      if (error === 'No current user') {
        // the intended way of this working is rehydration of slices only when the user is signed in
        // console.info('initializeApp: No current user, exiting gracefully.');
        return;
      }
      console.error('initializeApp: Error getting current session', error);
    }

    const currentState = store.getState();
    if (currentState.user.rehydrationStatus === 'notStarted') {
      // console.log('initializeApp: Rehydrating persisted slices');
    } else return;

    store.dispatch({
      type: 'SET_REHYDRATION_STATUS',
      payload: 'inProgress',
    });

    // Rehydrate each persisted slice asynchronously
    for (const sliceName of slicesToPersist) {
      const persistedSlice = await loadPersistedSlice(sliceName, sub);
      // console.log('initializeApp: Rehydrating slice', sliceName, persistedSlice);
      if (persistedSlice) {
        store.dispatch({
          type: `${sliceName}/hydrate`,
          payload: persistedSlice,
        });
      }
    }

    // Mark rehydration as finished
    store.dispatch({
      type: 'SET_REHYDRATION_STATUS',
      payload: 'finished',
    });
  } catch (error) {
    console.error('initializeApp: Error rehydrating persisted slices', error);
    store.dispatch({
      type: 'SET_REHYDRATION_STATUS',
      payload: 'finished',
    });
  }

  // Hub listener for subsequent auth events (should only run when rehydration is not in progress or done)
  Hub.listen('auth', async ({ payload: { event, data } }) => {
    const currentState = store.getState();
    if ((event === 'signIn' || event === 'tokenRefresh') && currentState.user.rehydrationStatus === 'notStarted') {
      // console.log('initializeApp: Rehydrating persisted slices after auth event', event);
      try {
        const session = await Auth.currentSession();
        const { sub } = session.getIdToken().payload;

        if (!sub) {
          console.error('Hub Listener: unable to rehydrate after auth event - user not found');
          return;
        }

        store.dispatch({
          type: 'SET_REHYDRATION_STATUS',
          payload: 'inProgress',
        });

        // Rehydrate persisted slices with new idToken
        for (const sliceName of slicesToPersist) {
          const persistedSlice = await loadPersistedSlice(sliceName, sub);
          if (persistedSlice) {
            store.dispatch({
              type: `${sliceName}/hydrate`,
              payload: persistedSlice,
            });
          }
        }

        // Update rehydration status
        store.dispatch({
          type: 'SET_REHYDRATION_STATUS',
          payload: 'finished',
        });
      } catch (error) {
        console.error('Hub Listener: Error rehydrating after auth event', error);
      }
    } else if (event === 'signOut') {
      store.dispatch({
        type: 'user/clear', // Or other logic to clear the store
      });
    }
  });
}
