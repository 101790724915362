/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState, shallowEqual, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { ChevronRightIcon, XMarkIcon } from '@heroicons/react/24/solid';
import dayjs from 'dayjs';
import { createSelector } from '@reduxjs/toolkit';
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
import { putAccount } from '../../redux/reducers/data';
import { setMessage } from '../../redux/actions/message';
// eslint-disable-next-line import/no-named-as-default
import TransactionsWrapper from './TransactionsWrapper';
// eslint-disable-next-line import/no-named-as-default
import QuotesWrapper from './QuotesWrapper';
import DialogSelectFile from '../../elements/DialogSelectFile';
import ColumnMatcher from './ColumnMatcher';
import AccountSettings from './AccountSettings';
import AlertModal from '../../elements/AlertModal';
import ChartWrapper from '../reports/ChartWrapper';
import AccountSum from '../../elements/AccountSum';
import UnlistedAddValuationWizard from './UnlistedAddValuationWizard';
import UnlistedCapitalTransactionWizard from './UnlistedCapitalTransactionWizard';
import getParamsByCategory from './params/getParamsByCategory';
import InputComponentAsyncCallback from '../../elements/InputComponentAsyncCallback';
import DuplicateCheck from './DuplicateCheck';
import AttributeBar from './AttributeBar';
import ToolTipNoIcon from '../../elements/ToolTipNoIcon';
import { getArticleLink } from '../../blog';
import LoanIndicators from './LoanIndicators';
import PensionIndicators from './PensionIndicators';

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function AccountDetails({ account: incomingAccount, setAccount, fileReceived, setFileReceived }) {
  // on mobile layouts, when dashboard is scrolled to the bottom, this component is rendered "above" the visible screen
  // as scrolling is disabled, the user cannot scroll to the top of the page -- so we have to do it for him
  // scroll to the top of the page (somehow it got rendered in the middle)
  const myRef = useRef(null);
  useEffect(() => {
    myRef.current.scrollIntoView({ behavior: 'instant' });
  }, []);

  // if anything changes in store.data, this component does not seem to receive the new data of the account object
  // keep the component initialised already with the account object and at the same time to receive all the updates
  // we will overwrite the account object with the store object for the given account.id
  const selectorAccount = createSelector(
    (state) => state.data[incomingAccount.category].accounts,
    (accounts) => accounts.find((item) => item.id === incomingAccount.id),
    {
      memoizeOptions: {
        // equalityCheck: (a, b) => a === b,
        // maxSize: 10,
        resultEqualityCheck: shallowEqual,
      },
    },
  );

  const dispatch = useDispatch();

  const selectAccount = useSelector(selectorAccount);
  if (!selectAccount) return true;
  const account = { ...incomingAccount, ...selectAccount };

  const baseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency) || 'EUR';

  // defines the parameter file to be used based on category
  // const selectTransactions = useSelector((state) => globalTransactionViewPerCategory(state, account.category));
  const params = getParamsByCategory(account.category);

  const { t, i18n } = useTranslation('app');

  const [tableState, setTableState] = useState({
    sortBy: 'date',
    sortDirectionAsc: true,
    colWidths: params.colWidths, // FIXME: stocks (cols)
    tableHeight: 300,
  });

  // used in Grid -- indicates that at least 1 change has been done by the user
  // including delete, which does not have a yellow background
  // needed here for the X overlay close button
  const [userChangesPresent, setUserChangesPresent] = useState(false);

  const [displayedComponent, setDisplayedComponent] = useState(fileReceived ? 'import' : 'table'); // if you see fileReceived, go directly to import
  const [displayedComponentMode, setDisplayedComponentMode] = useState('transactions');
  const display = displayedComponent;
  const [alert, setAlert] = useState({ visible: false });

  // handle user input from alert modal
  useEffect(() => {
    if (alert.id === 'aboutToLoseData') {
      if (alert.buttonClicked === 'ok') {
        setAccount(null);
      }
    }
  }, [alert.buttonClicked]);

  function handleClose(e) {
    if (display !== 'table' && userChangesPresent) {
      setAlert({
        visible: true,
        id: 'aboutToLoseData',
        buttons: ['ok', 'cancel'],
      });
    } else {
      setAccount(null);
    }
  }

  // create an event listener which calls setAlert when the user hits Esc when there are changes in the Grid view
  // DO NOT attempt to move it to the Redux alert, it won't work, as hooks cannot be used in listeners
  useEffect(() => {
    function handleEsc(e) {
      if (e.key === 'Escape') {
        if (display !== 'table' && userChangesPresent) {
          setAlert((prevState) => ({
            ...prevState,
            visible: true,
            id: 'aboutToLoseData',
            buttons: ['ok', 'cancel'],
          }));
        } else {
          setAccount(null);
        }
      }
    }

    // if (display === 'grid' || display === 'table') {
    document.addEventListener('keydown', handleEsc, false);
    // }
    return () => {
      document.removeEventListener('keydown', handleEsc, false);
    };
  }, [{ userChangesPresent, displayedComponent }]); // it needs to be one object; if it is more, it creates several listeners

  async function inputFieldCallback({ propertyName, newValue }) {
    const accountObject = { ...account, [propertyName]: newValue };
    let result;
    try {
      result = await dispatch(putAccount({ data: accountObject, category: account.category }));
      dispatch(setMessage('accountUpdatedSuccessfully'));
    } catch (err) {
      dispatch(setMessage('accountCouldNotBeUpdated'));
      throw new Error('accountCouldNotBeUpdated'); // the error is caught in the InputComponent
    }
    return result; // handled in InputComponent
  }

  const standardProps = {
    account,
    setAccount,
    displayedComponent,
    setDisplayedComponent,
  };

  const wrapperProps = {
    tableState,
    setTableState,
    displayedComponentMode,
    setDisplayedComponentMode,
    userChangesPresent,
    setUserChangesPresent,
    highlightTransactionId: account.highlightTransactionId,
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div id="accountDetailsParent" ref={myRef} className="absolute w-full h-auto min-h-full z-50 top-0 left-0 md:p-16 bg-gray-300 bg-opacity-90 space-y-6" role="dialog">
      {/* FIXME the height for mobile */}
      {alert.visible ? <AlertModal alertId={alert.id} alertResetFunction={setAlert} resetCallbackObject={alert} buttons={alert.buttons || null} visible={alert.visible} /> : null}
      <div className="relative bg-gray-50 shadow xl:max-h-full 2xl:h-[85vh] px-4 py-5 sm:rounded-lg sm:px-8 sm:py-8 flex flex-col">
        {/* both these elements need z-70 to be above the header of account details */}
        <button id="account-details-close-button" type="button" className="absolute top-0 right-0 m-4 cursor-pointer z-[70]">
          <XMarkIcon className="w-6 h-6 text-gray-400 hover:text-gray-500" onClick={handleClose} />
        </button>
        <a
          id="account-details-help-button"
          href={`/${i18n.language}/blog/${getArticleLink('account-details', i18n.language)}`}
          target="_blank"
          rel="noopener noreferrer"
          className="absolute top-0 right-0 m-4 mr-12 cursor-pointer z-[70]"
        >
          <ToolTipNoIcon info={t('accountDetails.help')}>
            <QuestionMarkCircleIcon className="w-6 h-6 text-brandBlue-400 hover:text-brandBlue-500" onClick={() => {}} />
          </ToolTipNoIcon>
        </a>
        {/* header section is not displayed for ColumnMatcher */}
        {display !== 'column' && (
          // this element overlaps with the Table setion below XS (this is sorted out in AttributeBar), so it has to be kept above it (it has z-50), so that it can be clicked
          <section aria-label="account header" className="z-[60]">
            <div>
              <nav className="hidden sm:flex" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-4">
                  <li>
                    <div className="flex">
                      <div className="text-sm font-medium text-gray-400 hover:text-gray-500">{t(`dashboard.categories.${account.category}`)}</div>
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center">
                      <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                      <div aria-current="page" className="ml-4 text-sm font-medium text-gray-700 hover:text-gray-800">
                        {t('accountDetails.accountDetails')}
                      </div>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
            <div className="flex items-center justify-between pt-4 sm:pt-0">
              <div className="flex-1 min-w-0">
                <InputComponentAsyncCallback
                  parentValue={account.name}
                  stub="Account name?"
                  propertyName="name"
                  formatting="sm:-ml-[0.4rem] font-bold text-gray-900 text-xl xs:text-2xl sm:text-3xl"
                  callback={inputFieldCallback}
                  showButtons
                />
              </div>
              {display === 'table' && (
                <div className="flex-shrink-0 flex md:mt-0 md:ml-4 z-10">
                  <p className="font-bold leading-7 text-gray-900 text-xl xs:text-2xl sm:text-3xl">
                    <AccountSum
                      account={account}
                      category={account.category}
                      currencyCodeFormat="text-base xs:text-lg sm:text-xl"
                      // for deposits, if the account is not in baseCurrency, we show the sum in accountCurrency in AccountDetails (and in baseCurrency on Tile)
                      showInBaseCurrency={!(account.category === 'deposits' && account.currency !== baseCurrency)}
                    />
                  </p>
                </div>
              )}
            </div>
            <AttributeBar account={account} />
          </section>
        )}
        <div className="grid grid-cols-1 2xl:grid-cols-3 gap-8 h-full max-h-full lg:overflow-hidden">
          <div
            className={`${display === 'table' ? 'xl:col-span-2' : 'xl:col-span-3'} ${
              display === 'column' ? 'xl:h-[80vh]' : 'xl:h-[60vh]'
            } pt-2 flex flex-col gap-6 items-start lg:overflow-hidden w-full`}
          >
            {display === 'table' && displayedComponentMode === 'transactions' && <TransactionsWrapper {...standardProps} {...wrapperProps} />}
            {display === 'grid' && displayedComponentMode === 'transactions' && <TransactionsWrapper {...standardProps} {...wrapperProps} />}
            {display === 'table' && displayedComponentMode === 'quotes' && <QuotesWrapper {...standardProps} {...wrapperProps} />}
            {display === 'grid' && displayedComponentMode === 'quotes' && <QuotesWrapper {...standardProps} {...wrapperProps} />}
            {display === 'settings' ? <AccountSettings {...standardProps} /> : null}
            {display === 'import' ? <DialogSelectFile {...standardProps} fileReceived={fileReceived} setFileReceived={setFileReceived} /> : null}
            {display === 'column' ? <ColumnMatcher {...standardProps} displayedComponentMode={displayedComponentMode} alert={alert} setAlert={setAlert} /> : null}
            {display === 'duplicate' && <DuplicateCheck {...standardProps} {...wrapperProps} />}
            {display === 'wizard' && displayedComponentMode === 'quotes' && <UnlistedAddValuationWizard {...standardProps} {...wrapperProps} />}
            {display === 'wizard' && displayedComponentMode === 'transactions' && <UnlistedCapitalTransactionWizard {...standardProps} {...wrapperProps} />}
          </div>
          {display === 'table' && (
            <section aria-labelledby="chart">
              <div className="rounded-md bg-white xl:overflow-hidden shadow">
                <div className="p-6">
                  <h2 className="text-base font-semibold text-gray-900" id="chart-title">
                    {['realEstate', 'objectsOfValue'].includes(account.category) ? t('accountDetails.last10years') : null}
                    {['deposits', 'stocks', 'metals', 'unlistedShares'].includes(account.category) ? t('accountDetails.last12months') : null}
                    {['loans', 'pension'].includes(account.category) ? t('accountDetails.stats') : null}
                  </h2>
                  {/* if the chart column needs to be taller, add the category here; otherwise it is fixed at h-96 */}
                  <div className={`mt-6 flex justify-center ${['loans', 'pension'].includes(account.category) ? 'min-h-[24rem]' : 'h-96'}`}>
                    {account.category === 'deposits' && (
                      <ChartWrapper
                        reportId="transactionsByMonthxx"
                        dataCallback={(data) => {
                          const thisAccountData = data.filter((item) => item.accountId === account.id);
                          const months = [...new Set(thisAccountData.map((tr) => dayjs.utc(tr.date).format('YYYY-MM')))].sort().reverse().slice(0, 12);
                          return thisAccountData.filter((item) => months.includes(dayjs.utc(item.date).format('YYYY-MM')));
                        }}
                      />
                    )}
                    {['stocks', 'metals', 'unlistedShares', 'crypto'].includes(account.category) && (
                      <ChartWrapper
                        reportId="stocksByMonthxxxxxxxx"
                        filterCallback={(item) => item.date > dayjs().subtract(12, 'months').valueOf() && item.accountId === account.id}
                        layoutOptions={{
                          startLabel: false,
                          endLabel: false,
                          margin: { left: 10, right: 60, bottom: 60, top: 30 },
                        }}
                      />
                    )}
                    {(account.category === 'realEstate' || (account.category === 'objectsOfValue' && displayedComponentMode === 'quotes')) && (
                      <ChartWrapper
                        reportId="quotesByAssetIdxxxxxx"
                        filterCallback={(item) => item.assetId === account.id}
                        layoutOptions={{
                          axisBottom: { tickRotation: -90 },
                          margin: { bottom: 60 },
                        }}
                      />
                    )}
                    {account.category === 'objectsOfValue' && displayedComponentMode === 'transactions' && (
                      <ChartWrapper
                        reportId="realEstateByMonthxxxx"
                        filterCallback={(item) => item.assetId === account.id}
                        layoutOptions={{
                          axisBottom: { tickRotation: -90 },
                          margin: { bottom: 60, right: 10, left: 60, top: 30 },
                        }}
                      />
                    )}
                    {account.category === 'loans' && <LoanIndicators accountId={account.id} />}
                    {account.category === 'pension' && <PensionIndicators accountId={account.id} />}
                  </div>
                  <div className="mt-6">
                    <Link
                      to={`/${i18n.language}/app/reports`}
                      className="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      {t('accountDetails.goToReports')}
                    </Link>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      </div>
    </div>
  );
}
AccountDetails.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  account: propTypes.object.isRequired,
  setAccount: propTypes.func.isRequired,
  fileReceived: propTypes.oneOf([
    propTypes.shape({
      fileName: propTypes.string.isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      fileData: propTypes.any,
    }),
    null,
  ]),
  setFileReceived: propTypes.func,
};
AccountDetails.defaultProps = {
  fileReceived: null,
  setFileReceived: null,
};
