/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from '@headlessui/react';
import { TrashIcon } from '@heroicons/react/24/outline';
import MiniSpinner from '../../misc/MiniSpinner';
import { deleteAccount } from '../../redux/reducers/data';
import AccountSum from '../../elements/AccountSum';
import { clearAlert, setMessage } from '../../redux/actions/message';

// accountThatIsLoading is in DashboardCategory, so that we don't have to do it in every Tile separately the second time
export default function Tile({ account, category, Icon, color, setAccount, editMode, isViewer, isCategoryLoading, accountThatIsLoading }) {
  const [mouseover, setMouseover] = useState(false);
  const [delayHandler, setDelayHandler] = useState(null);
  const [deleteButton, setDeleteButton] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);

  const dispatch = useDispatch();

  const userBaseCurrency = isViewer ? useSelector((state) => state.viewer.baseCurrency) : useSelector((state) => state.user.profile.settings.baseCurrency);

  const isDisabled = isCategoryLoading || accountThatIsLoading?.id === account.id;

  function handleCustomEvent(payload) {
    const event = new CustomEvent('showTransactionPreview', { detail: payload });
    window.dispatchEvent(event);
  }

  async function handleMouseOver(e) {
    if (isViewer) return; // don't show tiletip for viewer

    // if we are in edit mode, do delete icon instead of show recent transactions table
    if (editMode) {
      setDeleteButton(true);
    } else {
      if (!mouseover) {
        setDelayHandler(
          setTimeout(() => {
            handleCustomEvent({
              account,
              userBaseCurrency,
            });
          }, 500),
        );
      }
      setMouseover(true); // to only enable one mouseover at a time
    }
  }

  function handleMouseOut(e) {
    // console.log('starting handleMouseOut');
    handleCustomEvent(null);
    setMouseover(false);
    clearTimeout(delayHandler);
    setDelayHandler(null);
    setDeleteButton(false);
  }

  // handle response from the alert dialog
  const alertId = 'youAreAboutToDeleteAccount'; // responses: ok | cancel

  // realEstate deleteAccount seems to set the signal

  // handles click: if Edit Mode is on, it displayed the delete button and on click dispatches deleteAccount
  // if Edit Mode not on, it opens AccountDetails view
  async function handleClick(e) {
    if (isViewer) return; // don't open details for viewer

    if (isDisabled) return; // don't do anything if the tile is disabled

    if (editMode) {
      // show the dispatch modal and define the callback
      window.dispatchEvent(
        new CustomEvent('setAlert', {
          detail: {
            id: alertId,
            caller: account.id,
            callbackOk: async () => {
              const response = await dispatch(deleteAccount({ accountId: account.id, category })); // returns the /fulfilled action from thunk
              if (response.meta.requestStatus !== 'fulfilled') {
                dispatch(setMessage('accountCouldNotBeUpdated'));
              }
              dispatch(clearAlert());
            },
          },
        }),
      );
    } else {
      setAccount(account);
    }
  }

  return (
    <Transition
      as="div"
      className={`col-span-1 relative bg-white pb-2 pr-4 w-full max-w-[18rem] sm:min-w-full shadow rounded-md ${isViewer && !isDisabled ? 'cursor-default' : 'cursor-pointer hover:bg-gray-50'}`}
      // puppeteer doesn't support -_ in class names and ids, so remove them
      // id={`tile${category}${account.id.replaceAll('-', '').replaceAll('_', '')}`}
      id={`tile${category}${account.id.replace(/[^a-zA-Z0-9]/g, '')}`}
      appear
      show
      enter="transition-opacity duration-200"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      onPointerEnter={handleMouseOver}
      onFocus={handleMouseOver}
      onPointerLeave={handleMouseOut}
      onBlur={handleMouseOut}
      onClick={handleClick}
      onKeyPress={() => {}} // FIXME (a11y)
      role="button"
      tabIndex="0"
    >
      {deleteButton && (
        <div className="absolute w-full h-full flex justify-center rounded-lg items-center bg-gray-100 opacity-80">
          <TrashIcon className="w-12 h-12 text-gray-400 opacity-100" />
        </div>
      )}
      {loadingStatus && (
        <div className="absolute w-full h-full flex justify-center rounded-lg items-center bg-gray-100 opacity-80 z-10">
          <MiniSpinner className="w-8 h-8 text-gray-500 opacity-100 animate-spin" />
        </div>
      )}
      <div className="h-full">
        <div className="py-3 md:py-4 grid grid-cols-4 space-x-2 items-stretch h-full">
          <div className="col-span-1 flex items-center justify-center">
            <Icon className="text-gray-400 w-5 md:w-6 h-5 md:h-6 order-2" />
          </div>
          <div className="col-span-3 justify-between flex flex-col">
            <p className="text-gray-500 text-xs md:text-sm leading-4 truncate">{account.name}</p>
            <div
              id={`tile-${account.id.replace(/[^a-zA-Z0-9]/g, '')}-sum`}
              // providing text-base makes the sm and md values not works
              // also, the font in text-base and font-bold looks distorted, so using scale to correct
              className="text-gray-900 md:pt-1 text-[1rem] font-bold sm:text-lg md:text-xl flex transform scale-y-110 sm:scale-y-100"
            >
              <AccountSum account={account} category={category} currencyCodeFormat="text-base" showInBaseCurrency isViewer={isViewer} />
            </div>
          </div>
        </div>
      </div>
      <div className={`absolute bottom-0 h-2 ${color.bottom} w-full rounded-b-md`} />
    </Transition>
  );
}
Tile.propTypes = {
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  category: PropTypes.oneOf(['deposits', 'stocks', 'realEstate', 'pension', 'objectsOfValue', 'metals', 'unlistedShares', 'loans', 'crypto']).isRequired,
  Icon: PropTypes.objectOf(PropTypes.any).isRequired,
  color: PropTypes.objectOf(PropTypes.any).isRequired,
  setAccount: PropTypes.func,
  editMode: PropTypes.bool.isRequired,
  isViewer: PropTypes.bool,
  isCategoryLoading: PropTypes.bool,
  accountThatIsLoading: PropTypes.objectOf(PropTypes.any),
};
Tile.defaultProps = {
  setAccount: () => {},
  isViewer: false,
  isCategoryLoading: false,
  accountThatIsLoading: { id: null }, // so that the .id works in code
};
