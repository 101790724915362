import React, { useState, useEffect, useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch } from 'react-redux';
import Tour from 'reactour';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import getTour from '../tours/getTour';
import { addSeenTours } from '../redux/actions/user';

/**
 * TourWrapper gets the list of seen tours from the user profile in Auth and displays the first tour that the user has not seen yet.
 *
 * @param {string} componentName - module name (a tour needs to be defined with this name in /tours/getTour.js)
 * @param {number} devModeShowTour - (used to test tours) if set, this tour will be displayed regardless of the user's profile
 *
 * @returns React.Component
 */
export default function TourWrapper({ componentName, devModeShowTour }) {
  if (!componentName) return <div />;

  const componentTours = getTour(componentName); // localTours: tour object is { id, steps[] }
  if (!componentTours) return <div />;

  const [displayedTour, setTourState] = useState(null); // stores tour object { id, steps[] }
  const [user, setUser] = useState(null);

  // we want the user to see one tour at a time; that tour should be displayed only once (even in dev mode when it gets rendered two times)
  // tour lock does not have to be in state, can be local
  // using ref, because changing ref does not trigger re-render
  const tourLock = useRef(false);

  const dispatch = useDispatch();

  // get the toursSeen from the user's profile in Amplify Auth store
  useEffect(() => {
    Auth.currentAuthenticatedUser({ bypassCache: true }).then(
      (userData) => setUser(userData),
      (error) => console.error(error),
    );
  }, []);

  // run this once we have the user
  useEffect(() => {
    async function execLogic() {
      const toursSeen = user.attributes['custom:tours_seen'] ? JSON.parse(user.attributes['custom:tours_seen']) : null;

      // handle what tour to display
      let toursToDisplay;
      if (typeof devModeShowTour === 'number') {
        // if the devModeShowTour parameters is set, display selected tour regardless what is in user's profile
        toursToDisplay = componentTours;
      } else {
        // remove all tours from localTours whose ids are in toursSeen
        toursToDisplay = componentTours.filter((tour) => !toursSeen || !toursSeen.includes(tour.id));
      }

      // if we have tours to display and if there is no tour being displayed already, display the first one
      if (toursToDisplay.length > 0 && !tourLock.current) {
        // update the ref to indicate that the tour is being shown already (to handle double-invoke and other strict mode toys in DEV mode)
        tourLock.current = true;

        // get the first tour from list and display it
        setTourState(toursToDisplay[typeof devModeShowTour === 'number' ? devModeShowTour : 0]);
      }
    }

    if (user && !tourLock.current) execLogic();
  }, [user]);

  if (!displayedTour) return <div />;

  return (
    // eslint-disable-next-line react/no-unstable-nested-components
    <ErrorBoundary FallbackComponent={() => <div />}>
      <Tour
        steps={displayedTour?.steps}
        isOpen={!!displayedTour} // true if tourState has contents
        rounded={4} // rounded corners
        accentColor="#1797f2"
        disableInteraction
        onRequestClose={() => {
          dispatch(addSeenTours(displayedTour?.id));
          setTourState(null);
          // tourLock.current = false; // this is imho not necessary, because we want this component to not to show any further tours this time
        }}
      />
    </ErrorBoundary>
  );
}

TourWrapper.propTypes = {
  componentName: PropTypes.string,
  devModeShowTour: PropTypes.number,
};
TourWrapper.defaultProps = {
  componentName: null,
  devModeShowTour: null,
};
