/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';
import CategoryWrapperTransactions from './CategoryWrapperTransactions';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

// this is a HOC which does not add anything to the UI
// it's purpose is to prepare TRANSACTION data which are to be shown in the table
export function TransactionsWrapper({
  displayedComponent,
  setDisplayedComponent,
  account,
  setAccount,
  tableState,
  setTableState,
  displayedComponentMode,
  setDisplayedComponentMode,
  userChangesPresent,
  setUserChangesPresent,
  highlightTransactionId,
}) {
  const commonProps = {
    account,
    setAccount,
    tableState,
    setTableState,
    setDisplayedComponent,
    displayedComponentMode,
    setDisplayedComponentMode,
    userChangesPresent,
    setUserChangesPresent,
  };

  if (displayedComponent === 'table') {
    return <CategoryWrapperTransactions {...commonProps} displayedComponent="table" highlightTransactionId={highlightTransactionId} />;
  }
  if (displayedComponent === 'grid') {
    return <CategoryWrapperTransactions {...commonProps} displayedComponent="grid" />;
  }
  return null;
}
TransactionsWrapper.propTypes = {
  displayedComponent: PropTypes.string.isRequired,
  setDisplayedComponent: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  setAccount: PropTypes.func.isRequired,
  tableState: PropTypes.object.isRequired,
  setTableState: PropTypes.func.isRequired,
  displayedComponentMode: PropTypes.string.isRequired,
  setDisplayedComponentMode: PropTypes.func.isRequired,
  userChangesPresent: PropTypes.bool.isRequired,
  setUserChangesPresent: PropTypes.func.isRequired,
  highlightTransactionId: PropTypes.string,
};
TransactionsWrapper.defaultProps = {
  highlightTransactionId: null,
};

// we are trying to stop redundant re-renders based on account prop allegedly changing
export const MemoizedTransactionsWrapper = memo(TransactionsWrapper, isEqual);
export default MemoizedTransactionsWrapper;
