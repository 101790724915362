import { configureStore } from '@reduxjs/toolkit';
import user from './redux/reducers/user';
import message from './redux/reducers/message';
// eslint-disable-next-line import/no-cycle
import data from './redux/reducers/data';
import quotes from './redux/reducers/quotes';
import simulation from './redux/reducers/simulation';
import misc from './redux/reducers/misc';
import projects from './redux/reducers/projects';
import reports from './redux/reducers/reports';
import viewer from './redux/reducers/viewer';
import persistSlicesMiddleware from './redux/persist/persist';

// help debug state mutations between dispatches
const logValueChangesMiddleware = (store) => (next) => (action) => {
  const prevState = store.getState();
  const result = next(action);
  const nextState = store.getState();
  const changedModules = [];

  // log all actions in console
  console.log('STORE: Action:', action.type);

  const valueName = 'projects'; // replace with the name of the value you want to log
  if (prevState[valueName] !== nextState[valueName]) {
    // if state[valueName] does no longer refer to the same memory address, log it
    // console.log(`Value ${valueName} changed from ${JSON.stringify(prevState[valueName], null, 2)} to ${JSON.stringify(nextState[valueName], null, 2)}`);
    // eslint-disable-next-line no-restricted-syntax
    for (const moduleName in nextState) {
      if (nextState[moduleName] !== prevState[moduleName]) {
        changedModules.push(moduleName);
      }
    }
    if (changedModules.length > 0) {
      console.log(`STORE Modules ${changedModules.join(', ')} changed`);
    }
  }
  return result;
};

// Automatically adds the thunk middleware and the Redux DevTools extension
const store = configureStore({
  // Automatically calls `combineReducers`
  reducer: {
    user,
    message,
    data,
    simulation,
    misc,
    projects,
    reports,
    quotes,
    viewer,
  },
  devTools:
    process.env.REACT_APP_ENV_SUFFIX !== 'prod'
      ? {
        maxAge: 2000, // Increase the action limit
      }
      : false, // Disable DevTools in production
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      // Ignore these action types
      ignoredActions: ['SET_ALERT'],
      // Ignore these field paths in all actions
      ignoredActionPaths: ['message.alert.callbackOk'],
      // Ignore these paths in the state
      ignoredPaths: ['message.alert.callbackOk'],
    },
  }).concat(persistSlicesMiddleware(['data', 'quotes'])),
  // }).concat(logValueChangesMiddleware),
});

// const state = new Proxy(store.getState(), {
//   set: (target, property, value) => {
//     console.log('State changed:', value);
//     // eslint-disable-next-line no-param-reassign
//     target[property] = value;
//     return true;
//   },
// });

// store.subscribe(() => {
//   Object.assign(state, store.getState());
// });

export default store;
