/* eslint-disable no-underscore-dangle */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import isEqual from 'lodash/isEqual';
import { currencyCodes } from '../../../misc/currencyCodes';
import { convertCurrency } from '../../../misc/ecbCurrencyRates';
import Dropdown from './Dropdown'; // import wrapper for the common Dropdown component
import InputField from './InputField';
import MiniSpinner from '../../../misc/MiniSpinner';
import Button from '../../../elements/Button';

dayjs.extend(utc);

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Header({ text }) {
  Header.propTypes = {
    text: PropTypes.string.isRequired,
  };
  const { t: tI } = useTranslation(['app'], { keyPrefix: 'addAccount.unlistedShares' });
  return <h4 className="xs:col-span-2 lg:col-span-3 text-base font-bold text-gray-900">{tI(text)}</h4>;
}

function UnlistedSharesAccountData({ index, updateWorkflowStatus, userInput, setUserInput, signalNext, setSignalNext }) {
  const { t: tL } = useTranslation('app', { keyPrefix: 'schema.unlistedShares.account' });
  const { t: tI } = useTranslation(['app'], { keyPrefix: 'addAccount.unlistedShares' });

  const selectDepositAccounts = useSelector((state) => state.data.deposits.accounts);
  const baseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency);

  const [fxRate, setFxRate] = useState(null); // holds the fx rate for the base currency if the two currencies are not the same

  //--------------------------------------------------------------------------------
  // default values for the form state (userInput + some tweaks)
  //--------------------------------------------------------------------------------

  const defaultValues = { ...(userInput && { ...userInput.data }) };
  defaultValues.currency = defaultValues.currency === undefined ? baseCurrency : defaultValues.currency;

  const { register, watch, reset, getValues, control, formState, setValue, trigger } = useForm({ mode: 'onBlur', defaultValues });
  const { errors, isValid, isDirty, dirtyFields } = formState;
  const connectedDepositMode = watch('connectedDepositMode');
  const currency = watch('currency');
  const upac = watch('upac');
  const date = watch('date');

  //--------------------------------------------------------------------------------
  // synchronise this component's form with the parent component's form state
  //--------------------------------------------------------------------------------
  function processOutputData(formValues) {
    const obj = { ...formValues };

    // housekeeping: convert string-numbers to numbers
    obj.quantity = Number(formValues.quantity);
    if (baseCurrency === currency) obj.upbc = obj.upac; // otherwise there will be a upbc field in the form

    // handle connectedDepositIds
    if (obj.connectedDepositMode === 'existingAccount') {
      const connectedObject = obj.connectedDepositAccountsObject || { id: null, name: null }; // before the user picks the answer here
      obj.connectedDepositAccounts = [connectedObject.id];
    } else if (obj.connectedDepositMode === 'createAccountLater') {
      obj.connectedDepositAccounts = [];
    }

    return obj;
  }

  console.log('component is rendering');

  useEffect(() => {
    console.log('component mounted or updated');

    return () => {
      console.log('component unmounted');
    };
  }, []);

  const watchAll = watch(); // see useEffect below
  const prevRenderWatchAll = React.useRef(null); // see useEffect below

  useEffect(() => {
    // compare current watchAll to its previous version -- only run when something really changes
    // TODO this is a hack, but the problem is that every change of the form seems to trigger a rerender of the component which triggers this useEffect, creating a circular dependency
    // TODO ideally we would try to eliminate the circular dependency whatsoever
    if (isEqual(watchAll, prevRenderWatchAll.current)) return;
    prevRenderWatchAll.current = watchAll;

    // check if all validations are passed and if so, set component status to true
    if (isDirty) {
      const output = getValues();
      // transform the output data before sending them to caller
      const processedOutput = processOutputData(output);
      setUserInput({ ...userInput, ...processedOutput });
      // we do not check isValid in the same useEffect because it is not updated yet (it is updated after the useEffect, because it is async)
      // so we do it in a separate useEffect, which reacts to isValid only
    }
  }, [watchAll]);

  // react to changes in isValid
  useEffect(() => {
    if (isValid) updateWorkflowStatus(index, true);
  }, [isValid]);

  //--------------------------------------------------------------------------------
  // subscribe to signal from parent that the user has clicked the "next" button
  // (and then trigger all validations to show errors)
  //--------------------------------------------------------------------------------

  useEffect(() => {
    if (signalNext) {
      trigger();
      setSignalNext(false);
    }
  }, [signalNext]);

  //---------------------------------------------------------------------------------------------
  // get fx rate for base currency when it is different than account currency
  //---------------------------------------------------------------------------------------------

  useEffect(() => {
    async function updateFxRate() {
      setFxRate('loading');
      let newRate;
      try {
        newRate = await convertCurrency(upac, currency, baseCurrency, dayjs.utc(date).startOf('day').valueOf());
        newRate = newRate.toFixed(2); // Round to 2 decimal digits
        setFxRate(newRate);
      } catch (err) {
        console.error('UnlistedSharesAccountData > updateFxRate > error:', err);
        setFxRate(null);
      }
    }
    // only run when there is upac and date already
    if (upac && date) updateFxRate();
  }, [currency, upac]);

  //---------------------------------------------------------------------------------------------
  // render the form
  //---------------------------------------------------------------------------------------------

  return (
    <div className="space-y-6 md:space-y-0 md:grid md:grid-cols-4 md:gap-6">
      <div className="md:col-span-1">
        <h3 className="text-xl md:text-2xl font-bold leading-6 text-gray-900">{tI('accountInfo')}</h3>
        <p className="mt-4 text-sm text-gray-500">{tI('provideInfo')}</p>
        {String(isValid)}
      </div>

      <form className="md:col-span-3 ">
        <div className="divide-y divide-gray-200">
          {/* SECTION 1: name, currency */}

          <div className="py-3 lg:pb-4 grid xs:grid-cols-2 lg:grid-cols-3 gap-4 xs:gap-6 lg:gap-8">
            <InputField
              id="name"
              label={tL('name.label')}
              category="unlistedShares"
              type="text"
              placeholder={tL('name.placeholder')}
              autoComplete="off"
              optional={false}
              register={register}
              errors={errors}
              validationRules={{ required: { value: true, message: tL('name.error') } }}
            />
            <Dropdown
              id="currency"
              list={currencyCodes}
              label={tL('currency.label')}
              placeholder={tL('currency.placeholder')}
              toolTip={tL('currency.tooltip')}
              register={register}
              optional={false}
              errors={errors}
              control={control}
              validationRules={{ required: { value: true, message: tL('currency.error') } }}
            />

            {/* quantity, purchaseDate, purchasePrice */}

            <InputField
              id="date"
              label={tL('date.label')}
              category="unlistedShares"
              type="date"
              placeholder={tL('date.placeholder')}
              autoComplete="off"
              optional={false}
              register={register}
              errors={errors}
              validationRules={{ required: { value: true, message: tL('date.error') } }}
            />
            <InputField
              formatting="col-start-1"
              id="quantity"
              label={tL('quantity.label')}
              category="unlistedShares"
              type="number"
              placeholder={tL('quantity.placeholder')}
              autoComplete="off"
              optional={false}
              register={register}
              errors={errors}
              validationRules={{ required: { value: true, message: tL('quantity.error') }, pattern: { value: /^[0-9]+$/, message: tL('quantity.error') } }}
            />
            <InputField
              id="upac"
              label={tL('upac.label')}
              category="unlistedShares"
              type="number"
              placeholder={tL('upac.placeholder')}
              autoComplete="off"
              optional={false}
              register={register}
              errors={errors}
              validationRules={{ required: { value: true, message: tL('upac.error') } }}
            />
            {baseCurrency !== currency && (
              <div>
                <InputField
                  id="upbc"
                  label={tL('upbc.label')}
                  category="unlistedShares"
                  type="number"
                  placeholder={tL('upbc.placeholder')}
                  autoComplete="off"
                  optional={false}
                  register={register}
                  errors={errors}
                  validationRules={{ required: { value: true, message: tL('upbc.error') } }}
                />
                {fxRate === 'loading' && <MiniSpinner className="h-5 w-5" />}
                {fxRate !== 'loading' && fxRate !== null && (
                  <div className="flex space-x-1 items-center justify-between">
                    <p className="text-sm">{tI('fxRate', { fxRate: Number(fxRate).toLocaleString('de', { style: 'currency', currency }) })}</p>
                    <Button text={tI('apply')} noFrameNoArrow onClick={() => setValue('upbc', fxRate)} formatting="self-end justify-self-start" />
                  </div>
                )}
              </div>
            )}
          </div>

          {/* SECTION 3: CONNECTED DEPOSITS */}

          <div className="py-3 lg:py-8 grid xs:grid-cols-2 lg:grid-cols-3 gap-4 xs:gap-6 lg:gap-8">
            <fieldset className="md:col-span-2 xl:col-span-3 grid md:grid-cols-2 xl:grid-cols-3 justify-items-start items-center gap-4 text-sm text-gray-400">
              <Header text="whenIBuy" />
              <div className="flex items-center">
                <input
                  id="existingAccount"
                  name="existingAccount"
                  type="radio"
                  value="existingAccount"
                  className="h-4 w-4 border-gray-300 text-brandBlue-500 focus:ring-brandBlue-400"
                  {...register('connectedDepositMode', { required: { value: true, message: tL('connectedDepositMode.error') } })}
                />
                <label htmlFor="existingAccount" className="ml-3 block text-sm font-medium text-gray-700">
                  {tI('existingAccount')}
                </label>
                {/* if this radio button is selected, display the deposit accounts dropdown */}
              </div>
              {connectedDepositMode === 'existingAccount' && (
                <div className="xl:flex xl:items-center">
                  <label htmlFor="connectedDepositAccountObject" className="flex text-sm font-medium text-gray-700">
                    {tI('existingAccountSelect')}
                  </label>
                  <div className="mt-1 ml-2">
                    <select
                      id="connectedDepositAccountObject"
                      name="connectedDepositAccountObject"
                      className="text-gray-500 shadow-sm focus:ring-brandBlue-400 focus:border-brandBlue-400 block w-full text-sm border-gray-300 rounded-md"
                      {...register('connectedDepositAccountObject')}
                    >
                      {selectDepositAccounts.map((a) => (
                        <option key={a.id} value={a.id}>
                          {a.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              <div className="flex items-center col-start-1">
                <input
                  id="createAccountLater"
                  name="createAccountLater"
                  type="radio"
                  value="createAccountLater"
                  className="h-4 w-4 border-gray-300 text-brandBlue-500 focus:ring-brandBlue-400"
                  {...register('connectedDepositMode', { required: { value: true, message: tL('connectedDepositMode.error') } })}
                />
                <label htmlFor="createAccountLater" className="ml-3 block text-sm font-medium text-gray-700">
                  {tI('createAccountLater')}
                </label>
              </div>
              {connectedDepositMode === 'createAccountLater' && (
                <div className="flex gap-2">
                  <InformationCircleIcon className="h-6 w-6 text-brandBlue-500 self-start" aria-hidden="true" />
                  {tI('createAccountLaterDescription')}
                </div>
              )}
              <p className="col-start-1 mt-2 text-sm text-red-500" id="connectedDepositMode-error">
                {formState.errors.connectedDepositMode && <span>{formState.errors.connectedDepositMode.message}</span>}
              </p>
            </fieldset>
          </div>
        </div>
      </form>
    </div>
  );
}

UnlistedSharesAccountData.propTypes = {
  index: PropTypes.number.isRequired,
  updateWorkflowStatus: PropTypes.func.isRequired,
  userInput: PropTypes.object,
  setUserInput: PropTypes.func,
  signalNext: PropTypes.bool,
  setSignalNext: PropTypes.func,
};
UnlistedSharesAccountData.defaultProps = {
  userInput: {},
  setUserInput: {},
  signalNext: undefined,
  setSignalNext: () => {},
};

export default UnlistedSharesAccountData;
