/* eslint-disable no-param-reassign */
// koń jaki jest, każdy widzi
function flatArrayToObject(arr) {
  return arr.reduce((prev, curr) => ({ ...prev, ...curr }), {});
}

// takes an object and flattens it into an array
// example: { 'category1': 'type1': { <some_values >}, 'type2': { <some_values >} }, 'category2': 'type1': { <some_values >}, 'type2': { <some_values >} } } (see: Quotes in store)
// will be converted to an array of { category: 'category1', type: 'type1', <some_values> }, { category: 'category1', type: 'type2', <some_values> },
// { category: 'category2', type: 'type1', <some_values> }, { category: 'category2', type: 'type2', <some_values> }
// EXPECTS:
// - obj: an object to be flattened
// - parentNames: an array of strings, each string is a name of a parent property (e.g. ['category', 'type'])
// - parents: to be used in the recursive function, leave it empty

// this new version will only go as many levels deep as there are parentNames
export function objectToArray(obj, parentNames = ['assetId', 'date'], parents = []) {
  if (!obj || parents.length >= parentNames.length) return [];

  const returnObject = Object.entries(obj).map(([key, value]) => {
    // Check if the value is an object (but not an array) and if we haven't reached the max depth
    if (parents.length < parentNames.length - 1 && Object.values(value).findIndex((v) => v && typeof v === 'object' && !Array.isArray(v)) > -1) {
      // Recurse one level deeper
      return objectToArray(value, parentNames, [...parents, { [parentNames[parents.length]]: key }]);
    }
    // Construct the object at the current level
    return {
      ...flatArrayToObject(parents),
      [parentNames[parents.length]]: key,
      ...value,
    };
  });

  return returnObject.flat();
}

// export function objectToArray(obj, parentNames = ['assetId', 'date'], parents = []) {
//   if (!obj) return [];

//   const returnObject = Object.entries(obj).map(([key, value]) => {
//     // if any of value's properties is an object (but not an array), go one level deeper
//     if (
//       Object.values(value).findIndex((v) => (v ? typeof v === 'object' && !Array.isArray(v) : false)) > -1
//     ) {
//       // spread the parents array (containing key: value pairs from all the levels above) and the current level object
//       return objectToArray(value, parentNames, [
//         ...parents,
//         { [parentNames[parents.length]]: key },
//       ]);
//     }
//     // spread all the parents' key-value pairs, the current level key-value pair and the current bottom-level properties (value)
//     return { ...flatArrayToObject(parents), [parentNames[parents.length]]: key, ...value };
//   });
//   return returnObject.flat();
// }

export function removeDuplicatesFromArray(arr) {
  const b = arr.map((x) => JSON.stringify(x));
  return Array.from(new Set(b)).map((x) => JSON.parse(x));
}

// export function arrayToObject(arr, keys) {
//   // keys is an array of strings, each string is a name of a property found within the objects in the array
//   // make this a recursive function
//   // return an object with the keys as the values of the properties named in keys
//   // EXAMPLE: [{ assetId: 'a', date: 'b', quote: 'c' }, { assetId: 'd', date: 'e', quote: 'f' }] with keys = ['assetId', 'date']
//   // will return { a: { b: { assetId: 'a', date: 'b', quote: 'c' } }, d: { e: { assetId: 'd', date: 'e', quote: 'f' } } }
//   if (!arr || !keys || keys.length === 0) throw new Error('Invalid arguments passed to misc > arrayToObject function', arr, keys);
//   if (arr.length === 0) return {};
//   keys.forEach((key) => {
//     if (!arr[0]?.[key]) {
//       console.info('Error in arrayToObject function', arr[0], keys);
//       throw new Error(`Invalid arguments passed to misc > arrayToObject function. Key ${key} not found in the array`);
//     }
//   });

//   return arr.reduce((prev, curr) => {
//     const key = curr[keys[0]];
//     if (!prev[key]) prev[key] = {};
//     if (keys.length > 1) {
//       prev[key] = arrayToObject(
//         arr.filter((x) => x[keys[0]] === key),
//         keys.slice(1),
//       );
//     } else {
//       prev[key] = curr;
//     }
//     return prev;
//   }, {});
// }

// after extensive test of this and a recurcive version, this one is faster by a factor of 100
function arrayToObject2(arr, keys) {
  if (!arr || !keys || keys.length !== 2) {
    throw new Error('Invalid arguments. The second argument must contain exactly 2 keys.');
  }

  const returnedObject = {};

  arr.forEach((item) => {
    const key1Value = item[keys[0]];
    const key2Value = item[keys[1]];

    if (!key1Value || !key2Value) {
      throw new Error(`Invalid data: One of the keys is missing in the item: ${JSON.stringify(item)}`);
    }

    // Ensure nested structure exists
    if (!returnedObject[key1Value]) {
      returnedObject[key1Value] = {};
    }

    // Assign the current item at the correct location
    returnedObject[key1Value][key2Value] = item;
  });

  return returnedObject;
}

function arrayToObject3(arr, keys) {
  if (!arr || !keys || keys.length !== 3) {
    throw new Error('Invalid arguments. The second argument must contain exactly 3 keys.');
  }

  const returnedObject = {};

  arr.forEach((item) => {
    const key1Value = item[keys[0]];
    const key2Value = item[keys[1]];
    const key3Value = item[keys[2]];

    if (!key1Value || !key2Value || !key3Value) {
      throw new Error(`Invalid data: One of the keys is missing in the item: ${JSON.stringify(item)}`);
    }

    // Ensure nested structure exists for key1 and key2
    if (!returnedObject[key1Value]) {
      returnedObject[key1Value] = {};
    }

    if (!returnedObject[key1Value][key2Value]) {
      returnedObject[key1Value][key2Value] = {};
    }

    // Assign the current item at the correct location
    returnedObject[key1Value][key2Value][key3Value] = item;
  });
}

export function arrayToObject(arr, keys) {
  if (keys.length === 2) return arrayToObject2(arr, keys);
  if (keys.length === 3) return arrayToObject3(arr, keys);
  throw new Error('Invalid arguments. The second argument must contain exactly 2 or 3 keys.');
}

// data - array of objects
// params - array of property names within all of the objects ['property1', 'property2',...]
// returns: a tree-like object with the properties from params as keys and arrays of objects as values, i.e. { property1: { property2: { property3: [ { <object1> }, { <object2> }, ... ] } } }
export function groupBy(data, params) {
  if (data.length === 0) return {};
  try {
    return data.reduce((r, o) => {
      params
        .reduce(
          // eslint-disable-next-line no-return-assign
          (group, key, i, { length }) => (group[o[key]] = group[o[key]] || (i + 1 === length ? [] : {})),
          r,
        )
        .push(o);

      return r;
    }, {});
  } catch (e) {
    console.error('Error in groupBy function', e);
    console.info('data', JSON.stringify(data));
    return {};
  }
}

// expects an array of objects with date and quantity properties
// goes through the array sorted by date ASC and subtracts negative quantities from the positive ones which came before it (FIFO)
// returns an array of positive quantity transactions with remaining quantity (only those that have quantity left!)
export function applyFIFO(transactions) {
  // Sort transactions by date ascendingly
  const positiveTransactions = [];
  const negativeTransactions = [];
  transactions
    .slice() // copy array to avoid modifying the original (it causes an error in strict mode)
    .sort((a, b) => a.date - b.date)
    .forEach((t) => {
      // eslint-disable-next-line no-unused-expressions
      t.quantity >= 0 ? positiveTransactions.push(JSON.parse(JSON.stringify(t))) : negativeTransactions.push(JSON.parse(JSON.stringify(t)));
      // ↑↑ this must be a deep copy, otherwise it will modify the original array
    });

  // Process negative transactions against the positive ones
  negativeTransactions.forEach((negTrans) => {
    let absNegQuantity = Math.abs(negTrans.quantity);

    while (absNegQuantity > 0 && positiveTransactions.length > 0) {
      const posTrans = positiveTransactions[0];

      if (posTrans.quantity <= absNegQuantity) {
        // If the positive transaction is fully consumed, remove it
        absNegQuantity -= posTrans.quantity;
        positiveTransactions.shift(); // Remove this transaction as it's fully consumed
      } else {
        // If the positive transaction covers the negative, adjust its quantity
        posTrans.quantity -= absNegQuantity;
        absNegQuantity = 0; // The negative transaction is fully matched
      }
    }
  });

  // Return the remaining (or modified) positive transactions
  return positiveTransactions;
}

/**
 * calculates quantityOpen per FIFO principle for a given array of transactions
 *
 * @param {array} transactions - array of transactions FOR ONE ASSETID ON THE SAME ACCOUNT
 * @param {boolean} (optional) reverseMode - for loans taken need to run in reverse mode (see below)
 * @param {boolean} (optional) throwOnOverdraft - if true, throws an error if a sale transaction exceeds all previous purchases (normally it logs the error onto console only)
 *
 * @returns {array} - transactions sorted by date asc, with extra calculated fields:
 *   * quantityOpen field added to non-sale transactions and
 *   * purchaseValueBaseCurrency for sale transactions (used by selectorDatascopeTransactions)
 *   * linkedTransaction of type 'purchase' on all sale transactions (used by incomeFromCapitalSaleTransactionsCurrent > getLinkedTransactionPurchaseCost)
 *     contains tags: { id, type, quantity, upbc } of the linked transaction
 *
 * The function takes an array of transactions with date, upbc and quantity fields.
 * It returns the same array sorted by date and with quantityOpen field added as per FIFO principle
 * (except sale transactions, which has no quantityOpen - this is used in assetBalances to calculate current.purchaseValue
 * Reverse mode is used for loans taken, as they have a "sale" transaction before the "purchase" transactions
 */

export function applyFIFOReturnAll(transactions, reverseMode = false, throwOnOverdraft = false) {
  // Sort transactions by date ascendingly
  const positiveTransactions = [];
  const negativeTransactions = [];
  transactions
    .slice() // copy array to avoid modifying the original (it causes an error in strict mode)
    .sort((a, b) => a.date - b.date)
    // implements reverse mode -- for loans (see comment above)
    .map((t) => (reverseMode ? { ...t, quantity: -t.quantity } : t))
    .forEach((t) => {
      // eslint-disable-next-line no-unused-expressions
      t.quantity >= 0 ? positiveTransactions.push(JSON.parse(JSON.stringify({ ...t, quantityOpen: t.quantity }))) : negativeTransactions.push(JSON.parse(JSON.stringify(t)));
      // ↑↑ this must be a deep copy, otherwise it will modify the original array
      // create quantityOpen field for positive transactions, it equals quantity in the beginning
    });

  let i = 0;
  // Process negative transactions against the positive ones
  negativeTransactions.forEach((negTrans) => {
    let negTransOpenQuantity = Math.abs(negTrans.quantity);
    negTrans.purchaseValueBaseCurrency = 0; // add a field to store the purchase value of the negative transaction

    // for each negative transaction go through the positive stack and "use up" their quantity
    while (negTransOpenQuantity > 0 && i < positiveTransactions.length) {
      const posTrans = positiveTransactions[i];

      // we are dealing with physical quantities, so we can't have a negative quantityOpen
      if (posTrans.date <= negTrans.date && posTrans.quantityOpen <= negTransOpenQuantity) {
        // if the positive transaction is fully consumed, add quantityOpen of 0
        // console.info('Open negative quantity is: ', negTransOpenQuantity, 'next positive transaction has open quantity: ', posTrans.quantityOpen);
        // console.info('This positive transaction is consumed completely. The negative transaction has new purchaseValue of', posTrans.quantityOpen * posTrans.upbc, 'with upbc of', posTrans.upbc);
        negTransOpenQuantity -= posTrans.quantityOpen;
        negTrans.purchaseValueBaseCurrency += posTrans.quantityOpen * posTrans.upbc;
        negTrans.linkedTransactions = (negTrans.linkedTransactions || [])
          .filter((lt) => lt.tags?.type !== 'purchase') // remove existing purchase LT
          .concat([{ id: posTrans.id, category: posTrans.category, tags: { type: 'purchase', date: posTrans.date, quantity: (reverseMode ? -1 : 1) * posTrans.quantityOpen, upbc: posTrans.upbc } }]);
        posTrans.quantityOpen = 0;
        posTrans.quantitySold = posTrans.quantity;
        i += 1;
      } else if (posTrans.date <= negTrans.date) {
        // if the positive transaction covers the negative, add quantityOpen of the remaining quantity on the negative transaction
        // console.info('Open negative quantity is: ', negTransOpenQuantity, 'next positive transaction has open quantity: ', posTrans.quantityOpen);
        // console.info('This positive transaction will still have open quantity after this negative transaction is deducted.');
        // console.info('The negative transaction has new purchaseValue of', negTransOpenQuantity * posTrans.upbc, 'with upbc of', posTrans.upbc);
        negTrans.purchaseValueBaseCurrency += negTransOpenQuantity * posTrans.upbc;
        negTrans.linkedTransactions = (negTrans.linkedTransactions || [])
          .filter((lt) => lt.tags?.type !== 'purchase') // remove existing purchase LT
          .concat([
            {
              id: posTrans.id,
              category: posTrans.category,
              tags: { source: 'applyFIFOReturnAll', type: 'purchase', date: posTrans.date, quantity: (reverseMode ? -1 : 1) * negTransOpenQuantity, upbc: posTrans.upbc },
            },
          ]);
        posTrans.quantityOpen -= negTransOpenQuantity;
        negTransOpenQuantity = 0; // the negative transaction is fully matched
        posTrans.quantitySold = posTrans.quantity - posTrans.quantityOpen;
      } else if (posTrans.date > negTrans.date) {
        // if we ran out of transactions BEFORE the current sale transactions, it means the sale transaction exceeds all previous purchases
        // we throw an error here and leave it to the caller to handle it
        console.error(`applyFIFOReturnAll: sale transaction exceeds all previous purchases for transaction ${JSON.stringify(negTrans, null, 2)}`);
        if (throwOnOverdraft) throw new Error(`applyFIFOReturnAll: sale transaction exceeds all previous purchases for transaction ${JSON.stringify(negTrans, null, 2)}`);
        negTransOpenQuantity = 0; // go to the next transaction
        // as fallback we provide purchaseValueBaseCurrency equal to the available quantity on the positive transaction
      }
    }
    // if there is open negTransOpenQuantity after getting out of the while loop, that means we ran out of positive transactions that came before this transaction
    if (negTransOpenQuantity > 0) {
      console.error(`applyFIFOReturnAll: sale transaction exceeds all previous purchases for transaction ${JSON.stringify(negTrans, null, 2)}`);
      if (throwOnOverdraft) throw new Error(`applyFIFOReturnAll: sale transaction exceeds all previous purchases for transaction ${JSON.stringify(negTrans, null, 2)}`);
    }
  });

  // Return the remaining (or modified) positive transactions
  return (
    positiveTransactions
      // first make sure that all positive transactions have quantityOpen (the ones after the last negative transaction won't have it) and quantitySold
      .map((t) => {
        if (t.quantity < 0) return t;
        return {
          ...t,
          quantityOpen: !t.quantityOpen && t.quantityOpen !== 0 ? t.quantity : t.quantityOpen,
          quantitySold: !t.quantitySold && t.quantitySold !== 0 ? 0 : t.quantitySold,
        };
      })
      .concat(negativeTransactions)
      .sort((a, b) => a.date - b.date)
      .map((t, idx, self) => {
        if (reverseMode) {
          // in reverse mode we need to reverse the sign also for the "sale" transactions
          if (t.quantity < 0) {
            // sales transactions do not need quantityOpen (we just need to reverse the sign of quantity here)
            return {
              ...t,
              purchaseValueBaseCurrency: -t.purchaseValueBaseCurrency,
              quantity: -t.quantity,
            };
          }
          const returnObject = { ...t, quantityOpen: !t.quantityOpen && t.quantityOpen !== 0 ? self[idx - 1].quantityOpen : t.quantityOpen };
          return {
            ...returnObject,
            quantityOpen: -returnObject.quantityOpen,
            quantity: -returnObject.quantity,
            quantitySold: -returnObject.quantitySold,
          };
        }
        // if a transaction doesn't have quantityOpen here, it means it should just get one from the previous one
        if (t.quantity < 0) return t; // sale transactions don't need quantityOpen
        const returnObject = { ...t, quantityOpen: !t.quantityOpen && t.quantityOpen !== 0 ? self[idx - 1].quantityOpen : t.quantityOpen };
        // need to restore the negative sign if in reverse mode
        return returnObject;
      })
  );
}

export function applyFIFOOnCategory(transactions, reverseMode = false) {
  const data = groupBy(transactions, ['category', 'accountId', 'assetId']);
  // returns { category1: { accountId1: { assetId1: [ { <transaction1> }, { <transaction2> }, ... ] } } }
  // iterate through all categories, accountIds and assetIds
  // apply FIFO to each array of transactions
  // return the resulting array
  return Object.entries(data).reduce((prev, [category, accounts]) => {
    const accountsArray = Object.entries(accounts).reduce((prevAccounts, [accountId, assets]) => {
      const assetsArray = Object.entries(assets).reduce((prevAssets, [assetId, assetTransactions]) => {
        const transactionsArray = applyFIFOReturnAll(assetTransactions, reverseMode);
        return [...prevAssets, ...transactionsArray];
      }, []);
      return [...prevAccounts, ...assetsArray];
    }, []);
    return [...prev, ...accountsArray];
  }, []);
}
