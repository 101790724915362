{
  "general": {
    "findoutmore": "Mehr erfahren",
    "getdemo": "Demo",
    "startDemo": "Demo ausprobieren",
    "search": "Suche",
    "noResultsFound": "Keine Ergebnisse gefunden",
    "freetrial": "Kostenlosen Test starten",
    "howdowedoit": "Wie funktioniert das?",
    "language": "Sprache",
    "english": "Englisch",
    "german": "Deutsch",
    "country": "Land",
    "germany": "Deutschland",
    "send": "Schicken",
    "ok": "OK",
    "error": "Fehler",
    "yes": "Ja",
    "no": "Nein",
    "success": "Erfolgreich",
    "submit": "Speichern",
    "continue": "Fortsetzen",
    "cancel": "Abbrechen",
    "next": "Weiter",
    "back": "Zurück",
    "subscribe": {
      "header": "Erhalte unseres Newsletter",
      "body": "Neuigkeiten, Produkt-Updates und hilfreiche Tipps, in deiner Mailbox alle 2 Monate.",
      "emailprompt": "Email-Adresse",
      "button": "Anmelden"
    },
    "step": "Schritt",
    "stepOf": "von",
    "findOutMore": "Mehr herausfinden",
    "synchronising": "Synchronisiere Daten"
  },
  "login": {
    "invalidUsernameOrPassword": "Benutzername oder Passwort ungültig",
    "userNotConfirmed": "Benutzer nicht bestätigt. Setze dich mit dem Support-Team in Verbindung: support@monestry.de",
    "passwordResetRequired": "Dein Passwort muss zurückgesetzt werden, bitte nutze 'Passwort vergessen?'-Link unten.",
    "incorrectConfirmationCode": "The confirmation code you entered is incorrect. Please try again.",
    "unknownUser": "Wir konnten einen Benutzer mit der uns von dem gewählten externen Provider genannten Email leider nicht finden.",
    "signInIntoYourAccount": "Anmeldung",
    "signInWith": "Anmelden mit",
    "enterConfirmationCode": "Bestätigungscode eingeben",
    "enterConfirmationCodeEmail": {
      "tip": "Wir haben dir ein Sicherheitscode per Email geschickt. Bitte trage es hier ein.",
      "label": "Sicherheitscode",
      "error": "Der Code muss genau 6 Zeichen lang sein."
    },
    "orContinueWith": "oder weiter mit",
    "emailOrUsername": "Email oder Benutzername",
    "password": "Passwort",
    "rememberMe": "Angemeldet bleiben",
    "forgotPassword": "Password vergessen?",
    "signIn": "Anmelden",
    "fieldRequired": "Dieses Feld ist erforderlich.",
    "passwordRecovery": "Passwort zurücksetzen",
    "unableToResetPassword": "Passwort konnte nicht zurückgesetzt werden. Versuche bitte später noch einmal.",
    "enterNewPassword": "Neues Passwort eingeben",
    "tooManyRequests": "Um deine Daten zu schützen, ist es in den nächsten 15 Minuten nicht mehr möglich, den Passwort wieder zu ändern. Wir haben zu viele solche Anfragen erhalten."
  },
  "menu": {
    "features": "Features",
    "anonymous": "Anonyme Nutzung",
    "data": "Datenquellen",
    "blog": "Blog",
    "meet": "Über Monestry",
    "pricing": "Preise",
    "demo": "Demo",
    "dashboard": "Dashboard",
    "login": "Anmelden",
    "logout": "Ausloggen",
    "register": "Registrieren",
    "product": "Produkt",
    "support": "Unterstützung",
    "guide": "Handbuch",
    "howto": "Anweisungen",
    "contact": "Kontakt",
    "company": "Unternehmen",
    "about": "Über uns",
    "jobs": "Karriere",
    "press": "Presse",
    "partners": "Partner",
    "legal": "Rechtliches",
    "impressum": "Impressum",
    "privacy": "Vertraulichkeit",
    "agb": "AGB"
  },
  "main": {
    "cta": {
      "line-1": "Arbeitet dein Geld",
      "line-2": "so hart wie du?",
      "body-1": "Treffe die richtigen Entscheidungen mit Monestry - dem diskreten Finanzplanner deines Vertrauens.",
      "body-2": "Ein wirklich vollständiges Bild deiner Finanzen: Rückblick, Gegenwart, Zukunft. Automatische Updates von mehr als 6.000 Finanzinstituten weltweit."
    },
    "partners": "Unsere vertrauten Partner",
    "convenience": {
      "header": "Komfort",
      "title": "Wir machen dein Leben einfacher",
      "subtitle": "Monestry ist ins Leben gerufen worden, um Aufwand und Komplexität aus Verwaltung von persönlichen Finanzen herauszunehmen."
    },
    "complete": {
      "title": "Ein wirklich vollständiges Bild",
      "body": "Wir wissen, dass dein Vermögen mehr als nur Bankkonten und Aktien umfasst. Wir machen es möglich, zahlreiche weitere Anlagekategorien zu pflegen: von Immobilien über Kryptowährungen und Edelmetalle bis Wertobjekte und private Beteiligunen.",
      "sub1": {
        "title": "Immobilien",
        "body": "Wohnungen und Häuser, selbstgenutzt oder vermietet, stellen oft einen erheblichen Teil privates Vermögens dar. Mit Monestry kann der Wert dieser Investments einfach getrackt werden."
      },
      "sub2": {
        "title": "Rentenversicherungen",
        "body": "Ein vollständiges Blick privater Finanzen muss auch Rentenversicherungen erfassen, die eine bedeutende Rolle in Absicherung der Rentensituation spielen können."
      }
    },
    "current": {
      "title": "Marktwert auf Knopfdruck",
      "body": "Eine Liste aller Vermögensposten mit ihrem heutigen Wert zu erstellen ist nur ein Teil der Aufgabe. Morgen haben viele dieser Posten nämlich einen anderen Wert. Und übermorgen - noch einen anderen. Monestry stellt sicher, dass alle aktualisierbaren Anlageklassen auf dem letzten Stand bleiben.",
      "sub1": {
        "title": "Aktien, Währungen und Edelmetalle",
        "body": "Wir aktualisieren die Kurse aller Aktien, Währungen und Kryptowährungen sowie Marktpreise der Edelmetalle in deinem Bestand."
      },
      "sub2": {
        "title": "Aktueller Marktwert deiner Immobilien",
        "body": "Monestry schickt dir kostenlos alle 6 Monate einen aktualisierten Marktwert aller deiner Immobilienobjekte. Wir nutzen die gleichen Datenquellen, wie die großen Kreditinstitute der Republik.",
        "link": "Wie funktioniert das?"
      }
    },
    "security": {
      "header": "Letzter Stand der Technologie",
      "title": "Ihre Daten werden mit neusten Sicherheitslösungen geschützt",
      "body": "Wir nutzen moderne Verschlüsselung- und Sicherheitstechnologien, um die Nutzerdaten und unsere Systeme zu schützen. Die Nutzer dürfen anonym bleiben, und die Daten sind von den Nutzerinformationen strikt getrennt."
    },
    "simple": {
      "title": "Blick in die Zukunft",
      "body": "Was wäre, wenn du in die Zukunft blicken könntest? Wir können zwar keine genauen Vorhersagen machen, aber vielleicht können wir doch helfen. Nutze unsere Simulationen und Projekte, um zu sehen, wie dein Vermögen in 10, 20 oder 40 Jahren aussehen könnte.",
      "link": "Wie funktioniert das?",
      "quote": "\"Es ist unglaublich beruhigend zu sehen, wie meine Investitionen im Laufe der Zeit wachsen könnten. Dieses Tool hat mir wirklich geholfen, mehr Vertrauen in meine langfristige Finanzstrategie zu haben.\"",
      "name": "Anna - Frankfurt am Main, Deutschland"
    },
    "simpleOLD": {
      "title": "Einfachheit als Ziel",
      "body": "Leben und Arbeiten in moderner Zeite sind sehr komplex, weshalb wir es zu unserem vorrängigen Ziel erklärt haben, Monestry einfach in der Handhabung zu gestalten. Wir eliminieren die unwesentlichen Aspekte des Vermögenmanagements und passen Produktvorlagen dem deutschen Markt an.",
      "link": "Verfügbare Produkte",
      "quote": "Cras velit quis eros eget rhoncus lacus ultrices sed diam. Sit orci risus aenean curabitur donec aliquet. Mi venenatis in euismod ut.",
      "name": "Jane - Frankfurt am Main, Deutschland"
    },
    "saving": {
      "title": "Spare deine Zeit",
      "body": "Es gibt besseres zu tun, als vor einem Spreadsheet zu sitzen und stundenlang Spalten von Zahlen manuell zu integrieren, selbst wenn es um eigenes Vermögen geht. Wir kümmern uns um das Manuelle, sodass du dich auf das Wesentliche konzentrieren kannst: die richtigen Entscheidungen für die Zukunft zu treffen!",
      "link": "Mehr erfahren",
      "quote": "\"Ich verschwende keine Zeit mehr mit Excel. Sie kümmern sich um all die Aktualisierunge, sodass ich einfach gute Entscheidungen für meine Zukunft treffen kann. Es ist viel einfacher und spart mir eine Menge Zeit\"",
      "name": "Peter - Bad Homburg, Deutschland"
    }
  },
  "feedback": {
    "title": "Dein Feedback",
    "problem": "Problem",
    "idea": "Idee",
    "question": "Frage",
    "header": "Deine Mitteilung an uns:",
    "email": "Ich möchte per Email kontaktiert werden, sobald meine Anfrage bearbeitet wird.",
    "completed": {
      "success": "Vielen Dank für deine Nachricht. Falls eine erneute Anfrage dazu nötig ist, gebe bitte die folgende Referenz an: ",
      "failure": "Es tut uns leid, es ist momentan nicht möglich, deine Anfrage zu übermitteln. Unser Team wurde über dieses Problem informiert. Versuche bitte zu einem späteren Zeitpunkt wieder."
    },
    "beingUploaded": "Dein Feedback wird übermittelt. Bitte warten.",
    "uploadAttachment": "Anhang hochladen",
    "uploadSizeLimit": "Maximale Größe: 5MB"
  },
  "dashboard": {
    "accounts_header": "Deine Vermögenswerte"
  },
  "page-not-found": {
    "header": "Es tut uns leid",
    "title": "Diese Seite existiert nicht",
    "body": "Folge bitte die Menü-Links, um in weitere Bereiche zu gelangen.",
    "link": "Zurück",
    "homePage": "Startseite"
  },
  "blog": {
    "title": "Aus unserem Blog",
    "subtitle": "Stöbere durch die Artikel und finde Tipps, wie man Monestry besser nutzen kann, unsere selbstbewussten Beiträge zu diversen finanziellen Themen sowie Ankündigungen neuer Produktmerkmale.",
    "filter-header": "Nach Kategorien filtern:",
    "categories": {
      "blog": {
        "filter-title": "Alle",
        "title": "Aus unserem Blog",
        "subtitle": "Stöbere durch die Artikel und finde Tipps, wie man Monestry besser nutzen kann, unsere selbstbewussten Beiträge zu diversen finanziellen Themen sowie Ankündigungen neuer Produktmerkmale."
      },
      "guide": {
        "title": "Product guide",
        "subtitle": "Diese Artikel beschreiben alle Produktfeatures im Detail und geben Tipps, wie sie am besten genutzt werden können."
      },
      "new-features": {
        "title": "Produkt-Ankündigungen",
        "subtitle": "Bei Monestry entwickeln wir unser Produkt ständig weiter, um die Verwaltung deiner Finanzen zu erleichtern. Hier findest du Ankündigungen neuer Features."
      },
      "point-of-view": {
        "title": "Standpunkte",
        "subtitle": "Wir haben eine Meinung dazu, wie private Finanzen verwaltet werden sollten. Hier findest du unsere Gedanken zu diversen finanziellen Themen."
      },
      "market-overview": {
        "title": "Marktübersicht",
        "subtitle": "Wir behalten den Finanz-App-Markt im Auge. Hier findest du unsere Gedanken zu den neuesten Entwicklungen."
      }
    }
  },
  "article": {
    "return-prev-page": "Zurück zu voriger Seite",
    "written-by": "Verfasst von",
    "published": "Veröffentlicht am",
    "updated": "aktualisiert am",
    "related-articles": "Verwandte Beiträge",
    "fixed-articles-header": "Verwalte dein Vermögen einfach mit Monestry",
    "features-title": "Unser Produkt: was können wir für dich tun?",
    "demo-title": "Live demo: probiere es aus - ohne Anmeldung!"
  },
  "features": {
    "section1": {
      "header": "Deine Finanzen auf dem neuesten Stand",
      "header-accent": "automatisch",
      "body": "Monestry erschafft ein vollständiges Bild aller Vermögenswerte und deren Wertentwicklung. Dadurch machen wir es dir leicht, deine privaten Finanzen im Griff zu halten.",
      "one-glance": {
        "title": "Ihr gesamtes Vermögen auf einen Blick",
        "body": "Das Dashboard ist das Herzstück unserer App. Hier erfährts du, wie der aktuelle Stand deiner Finanzen ist und wie sich die einzelnen Vermögenswerte entwickeln. Sein intuitives Design führt zu allen anderen Funktionen der App.",
        "quote": "\"Das Dashboard vereinfacht die Dinge wirklich für mich. Es zeigt mir ein klares Bild meiner Finanzen, stand jetzt, und was noch wichtiger ist - was meine Anlagen gebracht haben. Alles an einem Ort.\"",
        "person": "Lukas - Frankfurt am Main, Deutschland"
      },
      "all-classes": {
        "header": "Alle Anlageklassen verwalten",
        "body": "Mache dir das ganze Ausmaß deines Vermögens bewusst und treffe die richtigen finanziellen Entscheidungen. Es kann wirklich überraschend sein, wie groß der Unterschied ist, wenn man ein Gesamtbild hat.",
        "bank": {
          "header": "Bankkonten",
          "body": "Der Klassiker. Behalte den Überblick über deine Ersparnisse bei allen Banken."
        },
        "stocks": {
          "header": "Wertpapiere",
          "body": "Ob vom Broker oder vom Arbeitgeber: mit Monestry lassen sich alle Arten von Wertpapieren und Optionen verfolgen."
        },
        "real-estate": {
          "header": "Immobilien",
          "body": "Behalte den Wert deiner Immobilien im Auge, und wenn sie vermietet werden, finde heraus, wie hoch der tatsächlicher Ertrag ist."
        },
        "pension": {
          "header": "Altersvorsorge",
          "body": "Überwache die Entwicklung deiner Altersvorsorge: gesetzliche Renten- sowie Kapitalversicherungen, Riester, Rürup, bAV & Co."
        },
        "unlisted": {
          "header": "Private Unternehmen",
          "body": "Was ist dein Unternehmen eigentlich wert und wie hoch die aktuellen Kapitalrenditen ist? Wir stellen schnelle Wertermittlungstools zu Verfügung."
        },
        "crypto": {
          "header": "Kryptowährung",
          "body": "Eine neue Klasse von Vermögenswerten, die in kurzer Zeit sehr wertvoll werden können."
        },
        "objects": {
          "header": "Wertgegenstände",
          "body": "Edelmetallen, Oldtimer, Uhren, Münzen und Schmuck können einen bedeutenden Teil des Vermögens ausmachen."
        },
        "loans": {
          "header": "Darlehen",
          "body": "Genommen und gegeben, Darlehen können einen wesentlichen Teil deiner Ihrer finanziellen Situation ausmachen."
        }
      }
    },
    "section2": {
      "lead": "Automatische Aktualisierungen",
      "header": "Wissen, was dein Vermögen wert ist",
      "body": "Wie hoch ist dein Nettowert? Monestry hilft dir dabei, den Überblick zu behalten.",
      "valuation": {
        "stocks": {
          "header": "Börsenkurse",
          "body": "Kurse aller Wertpapiere werden täglich aktualisiert, damit du genau weißt, wie sich die aktuellen Markttrends dein Vermögen auswirken."
        },
        "unlisted": {
          "header": "Anteile an privaten Unternehmen",
          "body": "Mit unseren Rechnern lässt sich der Anteilswert an einem nicht börsennotierten Unternehmen leicht schätzen, um dir den Umgang mit Partnern, Investoren und Banken zu erleichtern."
        },
        "metals": {
          "header": "Edelmetalle",
          "body": "Wir ermitteln den aktuellen Marktwert und die Spreads für die Edelmetalle in deinem Besitz."
        },
        "fx": {
          "header": "Währungskurse",
          "body": "Erinnerst du dich noch an 1€ für 0,85$ im Jahr 2000? Und an 1€ für 1,46$ nur acht Jahre später? Währungsschwankungen können den Wert deines Vermögens erheblich verändern. Monestry hält es auf dem neuesten Stand."
        },
        "real-estate": {
          "header": "Immobilien",
          "body": "Beim Start und danach alle 6 Monate schicken wir dir den aktuellen Marktwert deiner Immobilien zu, der sich aus der Analyse von mehr als 200.000 aktuellen Verkaufstransaktionen in Deutschland ergibt."
        }
      }
    },
    "section3": {
      "goals": {
        "body": "Wann kannst du endlich in den Ruhestand gehen? Solltest du dein Traumferienhaus aus den Gewinnen Ihres Aktienportfolios finanzieren oder benötigst du dafür einen Kredit? Beantworte diese und andere Fragen mit Hilfe von \"Ziele und Simulationen\".",
        "header": "Ziele und Simulationen erstellen",
        "set": {
          "header": "Finanzielle Ziele setzen",
          "body": "Lege fest, wie viel Geld zur Finanzierung künftiger Projekte benötigt wird, und plane, von welchen Konten das Geld kommen soll."
        },
        "simulate": {
          "header": "Künftiges Wachstum simulieren",
          "body": "Wie entwickelt sich dein Vermögen unter gewissen Annahmen und wieviel ist es zu verschiedenen Zeitpunkten in der Zukunft wert?"
        }
      },
      "reports": {
        "body": "Personalis Analysen und Visualisierungen, um mehr Einblick in Ihre Finanzen zu erhalten.",
        "header": "Anpassbare Reports",
        "pivot": {
          "header": "Pivot-Tabellen",
          "body": "Wenn die voreingestellten Berichte nicht ausreichen, Pivot-Tabelle stehen zu Verfügung, um genau die Daten aufzubereiten, die du sehen möchtest."
        },
        "charts": {
          "header": "Diagramme erstellen",
          "body": "Erstelle prächtige Diagramme mit der flexiblen Diagrammerstellungs-Engine von Monestry."
        },
        "keep-eye": {
          "header": "Monitoring",
          "body": "Die personalisierten Diagramme können in dem Dashboard eingebettet werden, um bei jedem Besuch die wichtigsten Informationen zu sehen."
        }
      }
    },
    "section4": {
      "sync": {
        "header": "Vermögenswerte aktuell halten",
        "auto": {
          "header": "Automatische Synchronisierung",
          "body": "Deine Daten können automatisch von mehr als 6.000 Finanzinstituten im In- und Ausland synchronisiert werden. Wir arbeiten mit einem führenden Finanzdatenanbieter zusammen, der seit mehr als 10 Jahren erfolgreich in diesem Bereich tätig ist. >>Erfahren Sie mehr.<<"
        },
        "edit": {
          "header": "Daten wie im Spreasheet anpassen",
          "body": "Verwende ein vertrautes Spreadsheet-Stil zur Eingabe und Bearbeitung aller Daten, die manuell aktualisiert werden müssen."
        },
        "uploads": {
          "header": "Intelligente Uploads",
          "body": "Für manuelle Uploads analysiert unser einfacher und intuitiver Upload-System die Daten und schlägt die Zuordnungslogik vor, die einfach per Mausklick angepasst werden kann."
        },
        "decide": {
          "header": "Du entscheidest",
          "body": "Welche Konten automatisch aktualisiert werden sollen und welche mit Dateien aktualisiert ist komplett dir überlassen. Die Einstellungen können jederzeit geändert werden."
        }
      },
      "foundations": {
        "header": "Solide Grundlagen",
        "body": "Keine Experimente. Monestry ist mit bewährten, modernen Technologien aufgebaut, wobei der Schwerpunkt auf Datenschutz, Sicherheit und Nachhaltigkeit liegt.",
        "sustain": {
          "header": "Nachhaltige IT",
          "body": "Keine Leerläufe im Serverraum - die IT-Ressourcen von Monestry werden nur dann eingeschaltet, wenn sie genutzt werden."
        },
        "anonymous": {
          "header": "Anonymer Modus",
          "body": "Nutze Monestry, ohne deinen Namen anzugeben. Wir tun alles, um dich und deine Daten zu schützen."
        },
        "control": {
          "header": "Volle Kontrolle über eigene Daten",
          "body": "Wir verpflichten uns, keinen Zugriff zu deinen Daten externen Unternehmen zu gewähren - wir schreiben das sogar in unseren AGBs. Wir möchten Geld verdienen, indem wir Mehrwert für unsere Nutzer schaffen."
        },
        "neutral": {
          "header": "Neutral",
          "body": "Wir verkaufen keine Finanzprodukte an dich, um von den Provisionen der Verkäufer zu profitieren."
        },
        "security": {
          "header": "Sicher",
          "body": "'Alle gespeicherten und übertragenen Daten sind mit privaten Schlüsseln verschlüsselt und außerhalb von Monestry nicht sichtbar."
        },
        "impartial": {
          "header": "Unabhängig",
          "body": "Monestry ist im Privatbesitz und steht somit nicht unter Verkaufsdruck oder Einfluß eines Finanzinstitutes."
        },
        "audit": {
          "header": "Regelmäßige Audits",
          "body": "Wir führen regelmäßige Sicherheitsprüfungen durch externe Berater durch, um die Sicherheit unserer Systeme zu gewährleisten."
        },
        "german": {
          "header": "Made in Germany",
          "body": "Monestry wurde in Frankfurt gegründet, wo sich auch alle unsere IT-Systeme befinden."
        }
      },
      "cta": {
        "header": "Lass dein Geld arbeiten.",
        "header2": "Nutze Monestry ab heute.",
        "subheader": "Ein Monat kostenlos. Keine Kreditkarte erforderlich. Jederzeit kündbar."
      }
    }
  },
  "pricing": {
    "header": "Wähle das richtige Paket",
    "subheader": "Wir glauben nicht an Einheitsgrößen. Deshalb haben wir vier erschwingliche Pakete geschnürt, aus denen du wählen kannst.",
    "most-popular": "Meistgekauft",
    "annually": "Jährliche Abrechnung",
    "monthly": "Monatliche Abrechnung",
    "automaticUpdatesDisclaimer1": "* Einige Finanzinstitute erlauben keine automatischen Aktualisierungen. Siehe unsere",
    "automaticUpdatesDisclaimer2": " Liste der unterstützten Institute.",
    "linkToAutoUpdates": "/de/blog/anleitung/wie-halten-wir-die-daten-aktuell",
    "products": {
      "frequency": "/Monat",
      "s": {
        "title": "Monestry S",
        "description": "Einfache, automatische Aktualisierungen deiner Assets.",
        "features": [
          "Bis zu 5 Konten",
          "Automatische Aktualisierungen für deutsche Konten*",
          "Automatische Aktualisierung für 1 ausländisches Konto*"
        ],
        "cta": "Paket wählen",
        "mostPopular": false
      },
      "m": {
        "title": "Monestry M",
        "description": "Übernimm die Kontrolle über deine finanzielle Zukunft.",
        "features": [
          "Bis zu 10 Konten",
          "Automatische Aktualisierungen für deutsche Konten*",
          "Automatische Aktualisierungen für 2 ausländische Konten*",
          "Automatische Bewertungen für 1 Immobilie"
        ],
        "cta": "Paket wählen",
        "mostPopular": false
      },
      "l": {
        "title": "Monestry L",
        "description": "Endlich ein guter Überblick über alle deine Vermögenswerte.",
        "features": [
          "Bis zu 15 Konten",
          "Automatische Aktualisierungen für deutsche Konten*",
          "Automatische Aktualisierungen für 4 ausländische Konten*",
          "Automatische Bewertungen für 2 Immobilien"
        ],
        "cta": "Paket wählen",
        "mostPopular": true
      },
      "xl": {
        "title": "Monestry XL",
        "description": "Das komplette Bild deines Vermögens.",
        "features": [
          "Bis zu 30 Konten",
          "Automatische Aktualisierungen für deutsche Konten*",
          "Automatische Aktualisierungen für 8 ausländische Konten*",
          "Automatische Bewertungen für 3 Immobilien"
        ],
        "cta": "Paket wählen",
        "mostPopular": false
      }
    },
    "faq": {
      "header": "Über unsere Pakete",
      "questions": [
        {
          "question": "Kann ich mein Paket nach dem Kauf ändern?",
          "answer": "Ja, du kannst dein Paket jederzeit ändern, aber nur zu einem größeren Paket. Die Änderung tritt sofort in Kraft. Du wirst mit deiner nächsten Zahlung anteilig für den Unterschied zwischen den beiden Paketen belastet."
        },
        {
          "question": "Wie funktioniert die jährliche Abrechnung?",
          "answer": "Das ist die kostengünstigere Zahlungsmethode. Der oben angegebene Betrag wird mit 12 multipliziert (für die Anzahl der Monate in einem Jahr) und einmal jährlich über dein bevorzugtes Zahlungsmittel abgerechnet. Das Abo ist 365/366 Tage gültig und kann an jedem Tag des Jahres beginnen. Das Abo verlängert sich automatisch. Es kann am Ende des aktuellen Abozeitraums gekündigt werden - das bedeutet, das Abo bleibt bis zum Ende des Jahres aktiv."
        },
        {
          "question": "Wie funktioniert die monatliche Abrechnung?",
          "answer": "Diese Option eignet sich gut, um Monestry auszuprobieren. Der oben angegebene Betrag wird einmal pro Monat berechnet. Das Abo verlängert sich automatisch. Es kann jederzeit zum Ende des aktuellen Abozeitraums gekündigt werden - das Abo bleibt bis zum Ende des Monatszeitraums aktiv. Ein Wechsel zur jährlichen Zahlung ist möglich und tritt in Kraft, sobald der aktuelle Monatszeitraum endet."
        }
      ]
    }
  },
  "legal": {
    "toc": "Auf dieser Seite",
    "read-full-doc": "Ganzes Dokument anzeigen",
    "close": "Dokument schliessen",
    "dataPrivacyContact": "Bei Anfrgen rund um Datenschutz, inklusive Informations- und Löschrechte, wende dich bitte an uns per email: support@monestry.de",
    "documents": [
      {
        "header": "Allgemeine Geschäftsbedingungen (AGB)",
        "subtitle": "Dieses Dokument beschreibt die Nutzungsbedingungen unserer Dienste sowie alle rechtlichen Regelungen, die Nutzerdaten betriffen.",
        "body": "### Deutsche Version \n\n Lorem markdownum metus etiam oppositas Antigonen enim Bacchei ungues et ultro Helenum artus, et me laudis fixa, et. Pariter [huius](http://www.sive.com/) precantia admonuisse quove. Nemus sed inpia templis [capere](http://gravis.com/in), mihi colit ponit: illo proles thymi pactus datis iam ranas victus. \n\n Ibi usque vi Tantalis sonarent carpunt et. Neque manes: mensuraque, gratissime materia praebet nubibus quos: perque. Ante regnat feram? Ulciscere ecquem triplex. Nullam Magnetas puerile licet; Iovis priores quoque invenies laudat spectat pectine. \n\n ### Sexque et naribus motis tauri toto est. \n\n Subire ignarus mulcet argento penetrabile curvo hic Cinyran sinus **concipit**. Generosaque quae non spumantia ossibus tulit demens igne suam, Quirini illis retorserunt parte inhibente. Orbem passa est finite pars *spoliata Melantho* foedat. Quod neque tumentem dum. \n\n ### Mihi arbore possemque \n\n Quas habet, maledicere in est genitor capitis licet; bis terra est nomine, verso. Tale ille plausu huius, thalamis Hippomenen necem vocali si. Herbas obsuntque formae ventis, iuvenes maris me aliena nec mollia cremabis tempora velut. Formae sine parsque \\[Caeneus\\](http://horrifer-an.com/fumida.aspx) quoque exercere longa profani cessantem esse. \n\n ### Crudelia et lacu amplectens nomina misisset \n\n Et iactis refundit mecum. Pennatis sic, **in ulla** Ithaco: licebit quattuor non Ceres fraxinus praesignis colore commentaque."
      },
      {
        "header": "Impressum",
        "subtitle": "Dies ist ein gesetzlich vorgeschriebenes Dokument, das die Besonderheiten dieser Website beschreibt.",
        "body": "### Deutsche Version \n\n Lorem markdownum metus etiam oppositas Antigonen enim Bacchei ungues et ultro Helenum artus, et me laudis fixa, et. Pariter [huius](http://www.sive.com/) precantia admonuisse quove. Nemus sed inpia templis [capere](http://gravis.com/in), mihi colit ponit: illo proles thymi pactus datis iam ranas victus. \n\n Ibi usque vi Tantalis sonarent carpunt et. Neque manes: mensuraque, gratissime materia praebet nubibus quos: perque. Ante regnat feram? Ulciscere ecquem triplex. Nullam Magnetas puerile licet; Iovis priores quoque invenies laudat spectat pectine. \n\n ### Sexque et naribus motis tauri toto est. \n\n Subire ignarus mulcet argento penetrabile curvo hic Cinyran sinus **concipit**. Generosaque quae non spumantia ossibus tulit demens igne suam, Quirini illis retorserunt parte inhibente. Orbem passa est finite pars *spoliata Melantho* foedat. Quod neque tumentem dum. \n\n ### Mihi arbore possemque \n\n Quas habet, maledicere in est genitor capitis licet; bis terra est nomine, verso. Tale ille plausu huius, thalamis Hippomenen necem vocali si. Herbas obsuntque formae ventis, iuvenes maris me aliena nec mollia cremabis tempora velut. Formae sine parsque \\[Caeneus\\](http://horrifer-an.com/fumida.aspx) quoque exercere longa profani cessantem esse. \n\n ### Crudelia et lacu amplectens nomina misisset \n\n Et iactis refundit mecum. Pennatis sic, **in ulla** Ithaco: licebit quattuor non Ceres fraxinus praesignis colore commentaque."
      }
    ]
  },
  "about-us": {
    "need-for-monestry": {
      "header": "Wieso braucht die Welt Monestry?*",
      "subheader": "Monestry entstand aus dem Wunsch, einen einfachen, automatischen Überblick über die gegenwärtige, aber auch vergangene und zukünftige finanzielle Situation zu haben, und aus der Erkenntnis, dass die auf dem Markt erhältlichen Tools dieser Erwartung nicht gerecht werden.",
      "paragraphs": [
        "Monestry ist für Menschen gedacht, die ihr finanzielles Schicksal selbst in die Hand nehmen wollen und sich nicht von einer App ablenken lassen wollen, die dir Finanzprodukte verkaufen will.",
        "Wir sind der Meinung, dass Geschäftsmodelle, wo als Gegenleistung für kostenlose Nutzung einer App deine Daten weiterverkauft oder dir finanzielle Produkte angeboten werden nicht in deiner besten Interesse liegen.",
        "Stattdessen machen wir dir ein transparentes Angebot: Wir helfed dir, deine Daten auf dem neuesten Stand zu halten und stellen dir diverse Tools zur Verfügung, damit du  gute finanzielle Entscheidungen treffen kannst."
      ],
      "footnote": "* Oder, in den Worten unserer Freunde zu sagen: \"Schon wieder eine neue Finanz-App?\""
    },
    "pillars": [
      {
        "key": "simplicity",
        "header": "Einfach",
        "description": "Die Welt ist auch so schon komplex genug, deshalb ist es unser oberstes Ziel, die Dinge einfach zu halten. Auch wenn manche Finanzprodukte kompliziert sind, möchten wir, dass Sie sich auf die wichtigsten Parameter konzentrieren können. \n\n Wir wollen die Oberfläche unseres Produkts so einfach wie möglich halten. Sobald Sie sich mit den Feinheiten vertraut gemacht haben, können Sie die meisten Einstellungen auf einfache Weise vornehmen.",
        "cta": "Schaue dich das an"
      },
      {
        "key": "privacy",
        "header": "Privatsphäre und Vertrauen",
        "description": "Datenschutz war schon eines unserer Hauptanliegen, lange bevor wir auch nur eine Zeile Code geschrieben haben. \n- wir haben den \"Anonymen Modus\" geschaffen, in dem Sie das Produkt nutzen können, ohne uns Ihren Namen oder Ihre Adresse mitzuteilen \n- Ihre Kontodaten sind physisch von Ihren Finanzdaten getrennt, um den Schutz zu verdoppeln \n- es gibt automatische Mechanismen, die alle Nutzerdaten auf Anfrage melden und löschen.",
        "cta": "Wie wir das in der Praxis umsetzen"
      },
      {
        "key": "convenience",
        "header": "Komfort",
        "description": "Es ist durchaus machbar, alle Bank- und Maklerkonten mit einer Spreadsheet zu verwalten, aber wir glauben, dass Sie Ihre freie Zeit lieber anders verbringen möchten. \n\n Hier kommen wir ins Spiel. Alles, was automatisch aktualisiert werden kann, wird auch automatisch aktualisiert. Wenn eine Automatisierung nicht in Frage kommt, bieten wir Ihnen verschiedene einfache Möglichkeiten, die Daten selbst zu aktualisieren. \n\n Wir möchten, dass Sie die aktuellsten Informationen in Ihrem Browser oder auf Ihrem Telefon haben. Kein mühsames manuelles Aktualisieren von Tabellenkalkulationsspalten mehr!",
        "cta": "Wie können wir dir Zeit einsparen"
      }
    ],
    "pledges": {
      "title": "Unsere Werte",
      "header": "Drei Versprechen von Monestry",
      "subheader": "Das ist unser Versprechen an dich.",
      "items": [
        {
          "key": "one",
          "header": "Wir drängen dich niemals dazu, Finanzprodukte zu kaufen, an denen wir eine Provision verdienen.",
          "subheader": "Wir glauben, dass du in der Lage bist, die für dich richtigen Finanzprodukte selbst zu wählen. Wir wollen neutral und unvoreingenommen gegenüber allen Produktanbietern sein."
        },
        {
          "key": "two",
          "header": "Die Nutzer haben die volle Kontrolle über ihre Daten. Wir werden Ihre Daten niemals an Dritte verkaufen.",
          "subheader": "So steht es sogar in unseren Allgemeinen Geschäftsbedingungen. Alle Daten, die du uns anvertraust, bleiben unter deiner vollen Kontrolle."
        },
        {
          "key": "three",
          "header": "Wir möchten die Identität unserer Nutzen nicht kennen und wir bieten ihnen die Möglichkeit, unsere Systeme anonym zu nutzen.",
          "subheader": "Wir verkaufen nicht die Daten unserer Nutzer, also müssen wir ihre Identitäten nicht kennen. Steigere die Schutz deiner Privatsphäre und bleibe anonym - erhöhe dein Komfort, indem du uns mehr Daten anvertraust."
        }
      ]
    },
    "about": {
      "header": "Über Monestry",
      "body": "Monestry ist ein Start-up-Unternehmen, das im Jahr 2021 in Frankfurt am Main von Piotr Loboda, einem Deutschen mit polnischen Wurzeln, gegründet wurde. Seit über 20 Jahren arbeitet Piotr Loboda als professioneller IT-Architekt und IT-Direktor in verschiedenen Unternehmen der Finanzdienstleistungsbranche. Mehr über Piotr können Sie hier erfahren. \n\n Das Unternehmen ist zu 100% finanziert durch private deutsche Gesellschafter.\n\n Die Monestry GmbH ist seit ihrer Gründung Mitglied der Industrie- und Handelskammer Frankfurt am Main und ist im Handelsregister Frankfurt am Main unter der Registernummer HRB 123844 eingetragen. \n\n Monestry wurde von verschiedenen öffentlichen und privaten Organisationen anerkannt und unterstützt, wie z.B. Amazon Web Services, Stripe sowie diverse KMU-Fonds der Europäischen Union \n\n Wir haben mit verschiedenen Organisationen und Unternehmen zusammengearbeitet, um unseren Kunden die besten Technologielösungen anzubieten. Zu diesen gehören:  GoCardless, FinAPI, Faireal, Deutsche Bundesbank, European Central Bank u.v.m. \n\n Medienanfragen richten Sie bitte an media@monestry.de, kommerzielle Anfragen an partners@monestry.de."
    },
    "why-monestry": {
      "header": "Wieso \"Monestry\"?",
      "body1": "Wir haben nach einem Namen gesucht, der eindeutig mit Geld als solchem in Verbindung gebracht werden kann, der in verschiedenen Ländern leicht zu verstehen, nicht zu lang und leicht zu merken ist. Wir haben zahllose Namen durchforstet (die meisten sind bereits vergeben), einige davon intern erdacht und einige mit Hilfe künstlicher Intelligenz kreiert und uns schließlich für \"Monestry\" entschieden.",
      "body2": "\"Monestry\" verbindet den Wohlstand, die Stabilität und die Diskretion eines Zisterzienserklosters in einem üppigen französischen Wald mit viel mehr weltlichem Geld, das wir alle brauchen, um unsere irdischen Träume zu erfüllen. Wir sprechen es aus",
      "body3": "MO-nes-stri."
    }
  },
  "register": {
    "select": "Wählen",
    "username": {
      "header": "Registriere dich - es ist einfach!",
      "subheader": "Schritt 1 von 3. Erstelle ein Monestry-Benutzerkonto oder verwende ein bestehendes Nutzerkonto."
    },
    "package": {
      "header": "Fast geschafft",
      "subheader": "Schritt 2 von 3. Mit welchem Paket möchtest du einsteigen?"
    },
    "payment": {
      "header": "Zahlungsmodus",
      "subheader": "Zahlungsmodus jetzt oder erst nach dem Probemonat festlegen. Heute fallen keine Kosten an!"
    },
    "processing": {
      "header": "Willkommen bei Monestry!",
      "subheader": "Bitte warte ganz kurz, wir stellen dein Dashboard bereit."
    },
    "paymentStillProcessed": "Deine Zahlung wird noch bearbeitet.",
    "pleaseWaitWhileWeAreSettingThingsUp": "Bitte warte, während wir alles für dich einrichten.",
    "goBack": "Zurück",
    "signUp1": {
      "createNewAccount": "Erstelle ein neues Konto",
      "email": {
        "label": "Deine E-Mail-Adresse",
        "errorEmpty": "Bitte gebe eine E-Mail-Adresse an.",
        "errorInvalid": "Deine E-Mail-Adresse scheint ungültig zu sein.",
        "errorLength": "Deine E-Mail-Adresse darf nicht länger als 200 Zeichen sein",
        "errorTaken": "Diese E-Mail-Adresse ist leider nicht verfügbar.",
        "tooltip": "Damit wir dich erreichen können, wenn du z.B. dein Passwort zurücksetzen musst. Du kannst deine E-Mail auch zum Anmelden verwenden."
      },
      "password": {
        "label": "Wähle ein Passwort",
        "hint": "Dein Passwort muss mindestens 8 Zeichen lang sein und muss mindestens einen der folgenden enthalten: Großbuchstaben, Kleinbuchstaben, Zahl und ein Satzzeichen.",
        "errorEmpty": "Bitte gib ein Passwort ein.",
        "errorLength": "Dein Passwort darf nicht länger als 99 Zeichen sein",
        "errorPattern": "Dein Passwort entspricht nicht den Anforderungen."
      },
      "repeatPassword": {
        "label": "Wiederhole das Passwort",
        "errorMatch": "Das wiederholte Passwort ist nicht dasselbe wie das neue Passwort."
      },
      "username": {
        "label": "Benutzername",
        "tooltip": "Bitte wähle einen Benutzernamen, damit wir wissen, wie wir dich ansprechen sollen. Später kannst du dich mit deiner E-MAIL oder deinem BENUTZERNAMEN anmelden.",
        "errorRequired": "Bitte gebe einen Benutzernamen an. Wir müssen nur wissen, wie wir dich nennen sollen.",
        "errorLength": "Dein Benutzername darf nicht länger als 40 Zeichen sein",
        "errorMinLength": "Dein Benutzername darf nicht kürzer als 5 Zeichen sein",
        "errorPattern": "Du darfst in deinem Benutzernamen nur Buchstaben, Zahlen und . - _ verwenden.",
        "errorTaken": "Dieser Benutzername ist leider nicht verfügbar."
      },
      "country": {
        "label": "Land",
        "tooltip": "Warum müssen wir das wissen? Aus steuerlichen Gründen: im Moment können wir nur Rechnungen an in Deutschland wohnhafte Kunden stellen.",
        "Germany": "Deutschland"
      },
      "baseCurrency": {
        "label": "Basiswährung",
        "tooltip": "Welcher Währung möchtest du als Basiswährung nutzen? Typischerweise ist das die Währung, in der du verdienst."
      },
      "or": "oder",
      "useExistingAccount": "... oder verwende ein bestehendes Konto.",
      "useExistingAccountDescription": "Du kannst ein bestehendes Konto bei einem Anbieter deines Vertrauens verwenden, um dich bei Monestry anzumelden. Du kannst dann jedes Mal, wenn du dich bei Monestry anmeldest, dieses Konto verwenden.",
      "signUpWith": "Anmelden mit"
    },
    "signUp3": {
      "yourOrder": "Deine Bestellung",
      "creditCard": "Kreditkarte",
      "crypto": "Kryptowährungen",
      "payNow": "Jetzt speichern",
      "payLater": "Später speichern",
      "perMonth": " /Monat",
      "freeUntil": "Kostenlos bis",
      "afterwards": "danach",
      "day": "Tag",
      "dueToday": "Heute fällig ",
      "dueIn30Days": "Fällig am ",
      "subscriptionRenewsOn": "Erster Monat gratis. Dein Abo verlängert sich automatisch. Die Zahlung erfolgt",
      "everyyear": "jedes Jahr am",
      "everymonth": "jeden Monat am",
      "yearBilling": "abgerechnet jährlich.",
      "monthBilling": "abgerechnet monatlich.",
      "reminderEmail": "Du erhälst eine Erinnerungs-E-Mail, bevor die Zahlung fällig ist.",
      "cancelAnyTime": "Kündige jederzeit. Nach der Kündigung ist dein Abo noch bis zum letzten Tag der bezahlten oder der Test-Periode.",
      "upgradeAnyTime": "Ein Upgrade auf eine höhere Produktebene ist jederzeit möglich.",
      "upgradeSwitchToAnnual": "Upgrades auf eine höhere Produktebene und der Wechsel zur ermäßigten jährlichen Zahlung sind jederzeit möglich.",
      "externalInfo": "Hinweis: aus Sicherheitsgründen muss der Zugang über {{provider}} manchmal mehr als nur einmal bestätigt werden."
    },
    "paymentSuccessful": {
      "stillProcessed": "Deine Zahlung wird noch bearbeitet.",
      "registrationSuccessful": "Registrierung erfolgreich",
      "welcome": "Willkommen!",
      "thanksForOpening": "Danke, dass du ein Konto bei Monestry eröffnet hast.",
      "weSentYouAnEmail": "Wir haben dir eine E-Mail geschickt, um deine Adresse zu bestätigen. Während du wartest, warum schaust du dir nicht unser kurzes Einführungsvideo rechts an?",
      "enterCode": "Bitte gib den 6-stelligen Code aus dieser E-Mail in das Feld ein und drücke den unten stehenden Knopf, sobald du bereit bist.",
      "verificationCode": "Verifizierungscode",
      "confirmAccount": "Konto bestätigen",
      "codeError": "Dieser Code ist nicht richtig",
      "genericError": "Es tut uns leid, es ist ein unerwarteter Fehler passiert. Bitte wende dich an support@monestry.de. Schicke uns die registrierte Email sowie diese Fehlermeldung.",
      "federated": {
        "accountReady": "Dein Konto ist bereit. Du kannst dich wie gewünscht mit {{provider}} anmelden.",
        "addPasswordLater": "Wenn du zu einem späteren Zeitpunkt ein Monestry-Passwort deinem Konto hinzufügen möchtest, kannst du dies tun, indem du dein Passwort vom Anmeldebildschirm aus zurücksetzt.",
        "proceedToDashboard": "Zum Dashboard gehen"
      },
      "errorMessage": {
        "registrationFailed": "Registrierung fehlgeschlagen",
        "error": "Fehler",
        "thankYouForOpening": "Danke, dass du ein Konto bei Monestry eröffnet hast.",
        "problemWithPayment": "Leider scheint es ein Problem mit deiner Zahlung zu geben. Bitte wende dich an unser Support-Team unter support@monestry.de."
      }
    },
    "fallback": {
      "header": "E-Mail nicht verfügbar",
      "body1": "Einige Anbieter (z.B. Facebook) teilen unter gewissen Umständen deine verifizierte E-Mail-Adresse nicht mit. Ohne sie können wir kein Konto für dich erstellen.",
      "body2": "Bitte gib eine E-Mail-Adresse und ein Passwort ein, um ein Konto bei uns zu erstellen.",
      "submit": "Konto erstellen",
      "unhandledError": {
        "header": "Es tut uns leid, die Registrierung ist fehlgeschlagen.",
        "body1": "Deine Zahlung ist höchstwahrscheinlich erfolgreich durchgeführt worden, aber wir konnten dein Konto nicht fertig einrichten.",
        "body2": "Bitte versuche sich auf unserer Homepage mit den Anmeldedaten, die du gerade verwendet hast, anzumelden. Wenn das nicht funktioniert, wende dich bitte an unser Support-Team unter support@monestry.de",
        "message": "Bitte schicke uns die folgende Fehlermeldung:"
      }
    },
    "prep": {
      "header": "Wir bereiten Demo-Daten vor",
      "body": "Wir bereiten gerade Demo-Daten für dich vor, um dir den Einstieg zu erleichtern. Das dauert nur ein paar Sekunden.",
      "account0": "[DEMO] Mein Bankkonto",
      "account1": "[DEMO] Brokerage-Bargeldkonto",
      "account2": "[DEMO] Sparkonto",
      "account3": "[DEMO] Mein Aktienmakler",
      "account4": "[DEMO] Meine Immobilie",
      "project0": "[DEMO] Living",
      "intialSaldo": "Anfangssaldo",
      "purchaseAppleStock": "Kauf von Apple-Aktien",
      "transactionFees": "Transaktionsgebühren",
      "purchaseTeslaStock": "Kauf von Tesla-Aktien",
      "accountRunningCost": "Kontobetriebskosten",
      "dividendAppleStock": "Dividende Apple-Aktien",
      "salary": "Gehalt",
      "rentPayment": "Mietzahlung",
      "creditCardBill": "Kreditkartenabrechnung",
      "carRental": "Autovermietung",
      "hotelBill": "Hotelrechnung",
      "interestPayment": "Zinszahlung",
      "otherParty": "Andere Partei"
    },
    "code": {
      "label": "Registrierungscode",
      "link": "Registrierungscode eingeben",
      "errorInvalid": "Code ist leider ungültig."
    }
  },
  "requestDemo": {
    "topheader": "Bevor du anfängst",
    "Ready to set up your demo": "Startklar?",
    "header1": "Wir möchten unsere potenziellen Benutzer besser kennenlernen.",
    "header2": "Hättest du etwas dagegen, anonym vier einfache Fragen zu beantworten?",
    "numberOfAccounts": {
      "label": "Wie viele verschiedene Finanzprodukte hast du weltweit?",
      "sublabel": "Einschließlich Bankkonten, Depots, Rentenprodukte, etc.",
      "options": [
        "0 - 5",
        "6 - 10",
        "11 - 20",
        "20+"
      ]
    },
    "incomeSaved": {
      "label": "Welchen Prozentsatz deines Einkommens kann dein Haushalt sparen oder investieren?",
      "options": [
        "<10%",
        "10% - 20%",
        "20% - 30%",
        "30% - 40%",
        "40% - 50%",
        ">50%"
      ]
    },
    "age": {
      "label": "Wie alt bist du?",
      "options": [
        "<25",
        "26-35",
        "36-45",
        "46-55",
        "56-65",
        "65+"
      ]
    },
    "workplace": {
      "label": "Wo arbeitest du?",
      "options": [
        "Großes Unternehmen (mehr als 10.000 Mitarbeiter)",
        "Kleines/Mittelständisches Unternehmen (bis zu 10.000 Mitarbeiter)",
        "Ich besitze ein Unternehmen",
        "Ich bin selbstständig"
      ]
    },
    "howCanWeHelp": {
      "label": "Wie kann dir Monestry helfen? (optional)"
    },
    "Cancel": "Abbrechen",
    "Start demo": "Demo starten",
    "weAreSettingThingsUp": "Bitte kurz warten - wir bereiten das Demo für dich vor."
  },
  "cookies": {
    "title": "Cookies",
    "description": "Wir verwenden auf unserer Website notwendige und optionale Cookies, um unsere App zu betreiben und sie ständig zu verbessern. Ihre Einwilligung umfasst alle vorausgewählten / gewählten Cookies sowie das mit ihnen verbundene Speichern und Auslesen von Informationen auf Ihrem Endgerät sowie anschließendes Auslesen und die folgende Verarbeitung personenbezogener Daten.",
    "noTracking": "Wir zeigen keine Werbung und verwenden keine Cookies zum Tracking unserer Nutzer (sie verknüpfen Daten aus dieser App mit Daten Dritter).",
    "legalBasis": "Die Rechtsgrundlage für die Einwilligung im Hinblick auf die Speicherung und das Auslesen von Informationen ist § 25 Abs. 1 TTDSG sowie im Hinblick auf die Verarbeitung personenbezogener Daten Art. 6 Abs. 1 lit. a DSGVO.",
    "settingsInfo": "Sie können nicht notwendige Cookies akzeptieren ('Alle akzeptieren') oder ablehnen ('Nur essenzielle'). Ihre Einstellungen sind jederzeit änderbar, auch nachträglich über einen Link im Fußbereich unserer Website.",
    "privacyPolicy": "Weitere Hinweise zu den verwendeten Verfahren und Ihren Rechten, erhalten Sie in unserer Datenschutzerklärung.",
    "onlyEssential": "Nur essenzielle",
    "customise": "Anpassen",
    "acceptAll": "Alle akzeptieren",
    "customiseCookiesTitle": "Cookies anpassen",
    "customiseCookiesDesc": "Sie können die Cookies, die Sie auf dieser Website zulassen möchten, anpassen.",
    "customiseTitle": "Cookies anpassen",
    "customiseSubtitle": "Sie können die Cookies, die Sie auf dieser Website zulassen möchten, anpassen.",
    "essential": {
      "label": "Notwendig",
      "description": "Diese Cookies sind für das einwandfreie Funktionieren der Website erforderlich."
    },
    "marketing": {
      "label": "Marketing",
      "description": "Diese Cookies werden verwendet, um Ihnen personalisierte Angebote und Inhalte bereitzustellen."
    },
    "analytics": {
      "label": "Analytik",
      "description": "Diese Cookies helfen uns zu verstehen, wie Nutzer unsere App verwenden, und verbessern das Benutzererlebnis."
    },
    "externalMedia": {
      "label": "Externe Medien",
      "description": "Diese Cookies ermöglichen es uns, Ihnen externe Medien wie YouTube-Videos in unserer App zu zeigen."
    },
    "save": "Speichern",
    "goBack": "Zurück"
  },
  "contact": {
    "header": "Kontaktiere uns",
    "subheader": "Wie können wir dir helfen?",
    "goToLogin": "Einloggen",
    "goToDashboard": "Dashboard",
    "emailUs": "Schick uns eine E-Mail",
    "prospects": {
      "header": "Produktfragen",
      "body": "Wenn du Fragen zu unserem Produkt hast, sende uns bitte eine E-Mail an support@monestry.de"
    },
    "users": {
      "header": "Feedback | Bugs | Ideen",
      "body": "Wenn du Feedback, Ideen oder einen Fehler gefunden hast, verwende bitte das Feedback-Link, die unten rechts auf dem Bildschirm erscheint, sobald du dich eingeloggt hast."
    },
    "dataPrivacy": {
      "header": "Account löschen | Datenanfragen",
      "body": "Wenn du deinen Account löschen möchtest oder eine datenbezogene Anfrage hast, sende uns bitte eine E-Mail an support@monestry.de"
    },
    "partners": {
      "header": "Partnerschaften",
      "body": "Wenn du eine Partnerschaftsidee mit uns besprechen möchtest, kontaktiere uns bitte unter partners@monestry.de"
    },
    "media": {
      "header": "Medienanfragen",
      "body": "Wenn du Journalist/in bist und über uns schreiben möchtest, sende uns bitte eine E-Mail an media@monestry.de"
    }
  }
}