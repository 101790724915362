import React, { useEffect, useRef } from 'react';
import { redirect, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MiniSpinner from '../misc/MiniSpinner';

// callbacks from oauth2 and stripe are intercepted by Amplify even before this page is rendered
const debugLevel = process.env.REACT_APP_MDEBUG || 3;

export default function CallbackHandler() {
  const { t, i18n } = useTranslation('site', { keyPrefix: 'register' });
  const navigate = useNavigate();
  const location = useLocation();

  const registration = useSelector((state) => state.user.registration);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const selectToursSeen = useSelector((state) => state.user.profile.attributes?.toursSeen);
  const status = registration?.status;
  const username = registration?.username;

  const queryParams = new URLSearchParams(location.search);
  const queryParamCode = queryParams.get('code');
  const queryParamState = queryParams.get('state');

  // to not display "we are setting up" during the oauth callback from Cognito, we are going to just render a div if both query params above are there
  const noInterstitial = queryParamCode && queryParamState;

  useEffect(() => {
    if (status === 'awaitingEmailConfirmation') {
      if (debugLevel > 0) console.log(`CallbackHandler: handle redirect to '/confirm/${username}' -- confirm email after internal user creation`);
      redirect(`/${i18n.language}/confirm/${username}`);
      if (debugLevel > 2) console.log('Gonna try navigate now');
      navigate(`/${i18n.language}/confirm/${username}`);
    }
  }, [status]);

  // wait for hubListener to report that Amplify is done signing user up and update isLoggedIn in state
  useEffect(() => {
    if (isLoggedIn) {
      if (debugLevel > 0) console.log("CallbackHandler: isLoggedIn is now true -- user is logged in, redirect to '/app'");
      // if there are any toursSeen, go directly to Dashboard
      if (selectToursSeen && selectToursSeen.length > 0) navigate(`/${i18n.language}/app/dashboard`);
      // otherwise go to Prep
      else navigate(`/${i18n.language}/app/getready`);
    }
  }, [isLoggedIn]);

  return (
    <div className="h-screen bg-white flex items-center justify-center">
      {noInterstitial ? <div /> : <div className="text-2xl font-bold text-gray-500 animate-pulse">{t('pleaseWaitWhileWeAreSettingThingsUp')}</div>}
    </div>
  );
}
