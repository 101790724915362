import { compressToUTF16 } from 'lz-string';
import CryptoJS from 'crypto-js';
import { saveSliceToIndexedDB } from './indexedDBUtils'; // Import the utility function

const debounceTimers = {};

/**
 * Waits for 1000 ms and if no other action is dispatched in the meantime, persists the slice to localStorage.
 * Applies compression and encryption before saving the slice.
 *
 * @param {array} sliceNames
 */

const asyncPersistSlicesMiddleware = (sliceNames) => (store) => (next) => (action) => {
  const result = next(action); // Let Redux continue with the action
  // console.log('Persists slices:', sliceNames, action);

  const state = store.getState();
  sliceNames.forEach((sliceName) => {
    const slice = state[sliceName];

    const key = state?.user?.sub;

    if (!key) return;

    if (slice) {
      // Clear any existing debounce timer for this slice
      if (debounceTimers[sliceName]) {
        clearTimeout(debounceTimers[sliceName]);
      }

      // Set a new debounce timer for the slice
      debounceTimers[sliceName] = setTimeout(async () => {
        try {
          // Step 1: Compress the slice
          const compressed = compressToUTF16(JSON.stringify(slice));

          // Step 2: Encrypt using idToken as the key
          const encrypted = CryptoJS.AES.encrypt(compressed, key).toString();

          // Step 3: Save to IndexedDB asynchronously
          await saveSliceToIndexedDB(sliceName, encrypted);
        } catch (error) {
          console.error(`Error persisting slice '${sliceName}' asynchronously`, error);
        }
      }, 1000); // 1 second debounce time
    }
  });

  return result;
};

export default asyncPersistSlicesMiddleware;
