/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import { createSelector } from '@reduxjs/toolkit';
import { createSelectorCreator, defaultMemoize } from 'reselect';
// import store from '../../store';
// eslint-disable-next-line import/no-cycle
import * as selectors from '../../redux/reducers/data';
import { objectToArray } from '../../misc/arrayHelpers';

const utc = require('dayjs/plugin/utc');

const debugLevel = 0;

dayjs.extend(utc);

const createSelectorWithDebug = createSelectorCreator(
  defaultMemoize,
  // eslint-disable-next-line arrow-body-style
  (a, b) => {
    console.log('Comparing:', a, b); // Log the values being compared
    return a === b; // Use the default equality check
  },
);

// custom equal function for useSelector
export function customEqual(oldValue, newValue) {
  // this is a rather crude way which just checks if the arrays are the same length
  // this should ideally be lodash's isEqual, should this method not work
  if (oldValue.length === newValue.length) return true;
  return false;
}

function parseIf(str) {
  if (typeof str === 'object') return str;
  return JSON.parse(str);
}

function localeStringToDate(str, locale = 'de') {
  const formatter = Intl.DateTimeFormat(locale);
  const dateFormat = formatter.formatToParts(new Date(1670799600000));
  const dateParts = dateFormat.filter((x) => x.type !== 'literal').map((x) => x.type); // returns ['year', 'month', 'day'] or similar, depending on the locale

  const strArray = str.split(/\D+/);
  return new Date(strArray[dateParts.indexOf('year')], strArray[dateParts.indexOf('month')] - 1, strArray[dateParts.indexOf('day')]);
}

const logSelectorChanges = (name, previousInputs, currentInputs) => {
  previousInputs.forEach((previousInput, index) => {
    const currentInput = currentInputs[index];

    if (previousInput !== currentInput) {
      console.log(`${name} > Input selector ${index + 1} has changed.`);
    }
  });
};

export const selectorDatascopeTransactions = createSelector(
  selectors.depositTransactions,
  selectors.globalAccountsView,
  (state) => state.projects,
  selectors.allTransactions,
  function selectorDatascopeTransactionsCallback(deposits, selectAccounts, selectProjects, allTransactions) {
    if (debugLevel > 2) console.log(dayjs().format('mm:ss.SSS'), 'beginning execution of selectorDatascopeTransactions');
    const returnedObject = {
      derivedAttributes: {
        'Date (Day)': (record) => new Date(record.date).toLocaleDateString('de', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          timeZone: 'UTC',
        }),
        'Date (Month)': (record) => `${new Date(record.date).getUTCFullYear()}-${new Date(record.date).toLocaleDateString('de', { month: '2-digit', timeZone: 'UTC' })}`,
        'Date (Year)': (record) => new Date(record.date).getUTCFullYear(),
        'Transaction sign': (record) => (record.amount >= 0 ? 'positive' : 'negative'),
        // PL 240327: this doesn't seem to be used in any pre-defined reports and seems somewhat out of place, as this seems to assign a income from capital to deposit transactions
        // PL 240327: perhaps it was used in the past before changes to selectors have been made;
        // PL 240327: in any case, disabling it for now; if it has to be enabled again, be aware that quantityOpen may be negative now (check outcome of PRD-1477)
        // 'Income from capital': ({ label, incomeFromCapital, amount }) => {
        //   const returnedValue = (label === 'investment-sale' || label === 'investment-loanPrincipal') ? incomeFromCapital : amount;
        //   return ['investment-sale', 'investment-dividend', 'investment-interest', 'investment-rent', 'investment-loanPrincipal'].includes(label) ? returnedValue : 0;
        // },
      },
      sorters: {
        'Date (Day)': (a, b) => localeStringToDate(a) - localeStringToDate(b),
      },
      hiddenAttributes: ['date', 'batchId', 'isSimulated', 'parentId', 'linkedTransactions', 'sortingOrderWithinMonth', 'tags', 'userId', 'accountId'],
      data: (deposits || []).map((item) => {
        try {
          const finalObject = {
            ...item,
            category: 'deposits',
            accountName: selectAccounts.find((acc) => acc.id === item.accountId)?.name,
            projectName: selectProjects.find((acc) => acc.id === item.projectId)?.name,
            // incomeFromCapital: null, // disabled, see comment PL 240327 above
          };

          // this deposit transaction, if marked as sale, will have a linked transaction to an asset category of tags.linkType 'sale-to-transaction'
          // get the id of that linked (asset) transaction, find it in allTransactions and read its purchaseValueBaseCurrency
          // (it will already be calculated as a sum of purchaseValue all its linked purchase transactions by applyFIFO)
          // disabled, see comment PL 240327 above
          // if (item.label === 'investment-sale' || item.label === 'investment-loanPrincipal') {
          //   const linkedAssetSaleTransactionId = item.linkedTransactions.find((lt) => lt.tags?.linkType === 'sale-to-transaction')?.id;
          //   const linkedAssetSaleTransaction = allTransactions.find((txn) => txn.id === linkedAssetSaleTransactionId);
          //   finalObject.incomeFromCapital = item.quantity * item.upbc - (linkedAssetSaleTransaction?.purchaseValueBaseCurrency || null);
          // }

          return finalObject;
        } catch (err) {
          console.error('selectorDatascopeTransactions: error in data mapping', err, 'item:', item);
        }
        return item;
      }),
    };
    if (debugLevel > 2) console.log(dayjs().format('mm:ss.SSS'), 'finished execution of selectorDatascopeTransactions');
    return returnedObject;
  },
);

// the quotes service is supposed to provide monthly quotes for all assets since their purchase date;
// this has to handle deposits as well
function getAssetPriceAtDate(quotes, assetId, targetDate) {
  if (!quotes[assetId]) {
    // console.info(`getAssetPriceAtDate: no quotes for assetId ${assetId}`);
    return 1;
  }
  if (!quotes[assetId][targetDate]) {
    // console.info(`getAssetPriceAtDate: no quotes for assetId ${assetId} on date ${targetDate}`);
    return 1;
  }
  return quotes[assetId][targetDate].quoteBaseCurrency || 1;
}

// let previousInputs2 = null;

// const getPayoutsPerTransactionSinceDate = (state) => {
//   const currentInputs = getPayoutsPerTransactionSinceDateSource.dependencies.map((selector) => selector(state));

//   if (previousInputs2) {
//     logSelectorChanges('getPayoutsPerTransactionSinceDate', previousInputs2, currentInputs);
//   }

//   previousInputs2 = currentInputs;

//   return getPayoutsPerTransactionSinceDateSource(state);
// };

// prepare list of months for the snapshots, starting at the oldest transaction
// const theOldestTransactionDate = createSelector((state) => selectors.allTransactions(state), (transactions) => transactions.sort((a, b) => a.date - b.date)[0]?.date);

const selectMonthStarts = createSelector(
  selectors.allTransactions, // this will only recalculate if allTransactions changes
  function selectMonthStartsCallback(transactions) {
    const theOldestTransactionDate = transactions.sort((a, b) => a.date - b.date)[0]?.date;

    const returnArray = [];
    if (process.env.REACT_APP_MDEBUG > 2) console.log('starting calc monthStarts');
    let newMonth = dayjs.utc(theOldestTransactionDate).startOf('month').toDate();
    // while (newMonth.valueOf() < dayjs.utc().valueOf()) {
    // ↓↓ if we add a new asset and it doesn't have any transactions before current month, it will not be shown in the snapshots, hence adding one more month
    while (newMonth.valueOf() < dayjs.utc().valueOf()) {
      returnArray.push(newMonth);
      newMonth = dayjs.utc(newMonth).add(1, 'month').startOf('month').toDate();
    }
    // returnArray.push(dayjs.utc().startOf('day').toDate()); // add current day // FIXME this probably should be current from baselineView
    // FIXME this also leads to the situation where we have the 05.2024 month (created in the while loop above) + another 05.2024 (resulting from this "current" row)
    return returnArray;
  },
);

// ----------------------------------------------------------------------------
// ATOMIC DATA (ASSET-LEVEL)
// Group data by category, account and assetId
// ----------------------------------------------------------------------------

// generate data grouped by account and assetId / figi
const atomicData = createSelector(selectors.assetBalancesArray, selectors.globalAccountsView, selectors.completeAssetView, function atomicDataCallback(aba, selectAccounts, cav) {
  if (aba.length === 0) return [];

  const atomicDataReturnObject = aba.map((item) => {
    const { category, accountId, assetId, current } = item;
    const cavCurrentRecord = cav[category][accountId][assetId].find((t) => t.rowType === 'kpi.current');

    const accountName = selectAccounts.find((a) => a.id === accountId)?.name;
    // we don't do ROI for current accounts, but we can do it for time deposits
    const purchaseValue = category === 'deposits' ? 0 : item.current.purchaseValueBaseCurrency; // logic: displaying beginning saldo as "purchase" is confusing
    const currentValue = current.valueBaseCurrency;
    const payoutsSincePurchase = cavCurrentRecord.payoutTotalOpenQuantity;
    const feesSincePurchase = cavCurrentRecord.feesTotalOpenQuantity;
    let roi = 0;
    if (category === 'deposits') {
      // this has to be adjusted in Account- and Category-level further down too
      roi = currentValue === 0 ? 0 : (payoutsSincePurchase + feesSincePurchase) / currentValue;
    } else {
      roi = purchaseValue === 0 ? 0 : (currentValue + payoutsSincePurchase + feesSincePurchase - purchaseValue) / purchaseValue;
    }
    return {
      key: item.assetId ? `${item.assetId}-${item.accountId}` : item.accountId.toString(),
      category: item.category,
      accountId: item.accountId,
      accountName,
      assetId: item.assetId,
      displayName: item.displayName ? `${item.displayName}${item.displaySymbol ? ` (${item.displaySymbol})` : ''}` : accountName,
      date: new Date().valueOf(),
      currentPrice: item.current.quoteBaseCurrency,
      purchaseValue,
      currentValue,
      payoutsSincePurchase,
      feesSincePurchase,
      roi,
      roiAnnual: cavCurrentRecord.weightedAverageAnnualisedRoi,
    };
  });

  // add annualisedRoiArray from kpi.current (the final one) to be used to calculate account and category level annualised ROI

  // console.log('atomicDataReturnObject', atomicDataReturnObject);
  return atomicDataReturnObject;
});

// for 'snapshots' it is enough to get snapshot data from each assetId/accountId combination and flatten them into a common array

const allowedLabels = ['investment-dividend', 'investment-interest', 'investment-pension', 'investment-rent']; // get sale from here or from the asset transaction itself?
// 'pension' added here on 240413, might be wrong, but from context seems to be correct here

function assetPiecesOwnedAtDate(cavAssetTransactions, { category, accountId, assetId }, date) {
  // returns: openAmount on a date (number of pieces owned at that date per accountId|assetId combination)
  // for realEstate, this is always 1 (to speed up calculations)
  if (category === 'realEstate') return 1;

  // calculated like: sum of rebasedAmount for a given assetId (e.g. figi) bought and sold before (=owned on) a given date
  return cavAssetTransactions.reduce(
    (prev, curr) => prev
      + (curr.date <= date && curr.assetId === assetId && (category === 'stocks' ? curr.transactionType === 'sale' || curr.transactionType === 'purchase' : true) && curr.accountId === accountId
        ? curr.quantity
        : 0),
    0,
  );
}

// pre-aggregate income from capital payments by accountId, assetId and calendar month to speed up calculations later
// income from capital via asset sale handled below
const incomeFromCapitalPaymentsByMonth = createSelector(selectors.depositTransactions, function incomeFromCapitalPaymentsByMonthCallback(depositTransactions) {
  return (depositTransactions || []).reduce((prev, curr) => {
    // only leave in transactions of interest
    if (!allowedLabels.includes(curr.label)) return prev;
    const month = dayjs(curr.date).format('YYYY-MM');
    const assetAccountId = parseIf(curr.tags)?.accountId;
    const assetId = parseIf(curr.tags)?.assetId || assetAccountId;
    if (!prev[month]) {
      prev[month] = {};
    }
    if (!prev[month][assetAccountId]) {
      prev[month][assetAccountId] = {};
    }
    if (!prev[month][assetAccountId][assetId]) {
      prev[month][assetAccountId][assetId] = 0;
    }
    prev[month][assetAccountId][assetId] += curr.amount;
    return prev;
  }, {});
});

// handle income from capital via asset sale
// no preaggregation here, as we don't expect a lot of data
const incomeFromCapitalAssetSalesByMonth = createSelector(selectors.allTransactions, function incomeFromCapitalAssetSalesByMonthCallback(allTransactions) {
  return (allTransactions || []).reduce((prev, x) => {
    // keep only asset sale transactions
    if (x.category === 'deposits' || x.rebasedAmount > 0) return prev;
    const purchaseValue = x.linkedTransactions?.reduce((prevLt, currLt) => prevLt + currLt.tags.rebasedPrice * currLt.tags.rebasedAmount, 0);
    const assetAccountId = x.accountId;
    const { assetId } = x;
    const month = dayjs(x.date).format('YYYY-MM');
    if (!prev[month]) {
      prev[month] = {};
    }
    if (!prev[month][assetAccountId]) {
      prev[month][assetAccountId] = {};
    }
    if (!prev[month][assetAccountId][assetId]) {
      prev[month][assetAccountId][assetId] = 0;
    }
    prev[month][assetAccountId][assetId] += -(x.rebasedAmount * x.transactionPrice) - purchaseValue;
    return prev;
  }, {});
});

// get inflows to compare with income from capital
const inflowsByMonth = createSelector(selectors.depositTransactions, function inflowsByMonthCallback(depositTransactions) {
  return (depositTransactions || []).reduce((prev, curr) => {
    // this is inflows, so only positive amount transactions which do not have any 'investment-*' label
    if (curr.label?.split('-')[0] === 'investment' || curr.amount < 0) return prev;
    const { accountId } = curr;
    const assetId = accountId;
    const month = dayjs(curr.date).format('YYYY-MM');
    if (!prev[month]) {
      prev[month] = {};
    }
    if (!prev[month][accountId]) {
      prev[month][accountId] = {};
    }
    if (!prev[month][accountId][assetId]) {
      prev[month][accountId][assetId] = 0;
    }
    prev[month][accountId][assetId] += curr.amount;
    return prev;
  }, {});
});

// ----------------------------------------------------------------------------
// SNAPSHOTS
// For every category-account-asset combination calculate
// the value of the asset at the beginning of each month since data start
// ----------------------------------------------------------------------------

const atomicDataWithSnapshotsCore = createSelector(
  atomicData,
  selectMonthStarts,
  selectors.assetBalances,
  selectors.globalQuotesView,
  incomeFromCapitalPaymentsByMonth, // static selector (not function)
  incomeFromCapitalAssetSalesByMonth, // static selector (not function)
  inflowsByMonth, // static selector (not function)
  // eslint-disable-next-line max-len
  function atomicDataWithSnapshotsCallback($atomicData, $monthStarts, ab, quotes, $incomeFromCapitalPaymentsByMonth, $incomeFromCapitalAssetSalesByMonth, $inflowsByMonth) {
    // console.log(dayjs().format('mm:ss.SSS'), 'atomicDataWithSnapshotsCore: calculating...');
    return $atomicData.map((item) => {
      // console.log('assetBalances', ab);
      const atomicDataWithSnapshotsReturnObject = {
        ...item,
        snapshots: $monthStarts.flatMap((monthStart) => {
          // this returns an array, which is then flattened; it can return one or more rows, depending on if inflows and incomeFromCapital happened in the same month
          const returnArray = [];
          const { category, accountId, assetId } = item;
          const monthStartString = dayjs(monthStart).format('YYYY-MM');

          // eslint-disable-next-line max-len
          const incomeFromCapital = ($incomeFromCapitalPaymentsByMonth[monthStartString]?.[accountId]?.[assetId] || 0) + ($incomeFromCapitalAssetSalesByMonth[monthStartString]?.[accountId]?.[assetId] || 0);

          const inflows = item.category !== 'deposits' ? 0 : $inflowsByMonth[monthStartString]?.[accountId]?.[assetId] || 0;

          const record = {
            date: monthStart,
            value: getAssetPriceAtDate(quotes, item.assetId, monthStart.valueOf()) * assetPiecesOwnedAtDate(ab[category][accountId][assetId].transactions, item, monthStart),
            // asset sale gains, dividends/interest for that month -- show it for the receiving accounts or for the asset accounts (the deposit txns will have acnt and asset id in tags)?
            incomeFromCapital,
            inflows,
          };

          // we always need at least 1 record for a monthEnd, so let it be the inflow record
          returnArray.push({ ...record, inflowType: 'inflow', inflowValue: inflows });
          // Bar chart needs a column with keys (inflowType) which it can aggregate according to those values (inflowValue)
          // so if there is incomeFromCapital, return another record for the same monthEnd
          if (incomeFromCapital > 0) {
            returnArray.push({
              ...record,
              inflowType: 'incomeFromCapital',
              inflowValue: incomeFromCapital,
              value: 0,
            });
          }
          // FIX -- 230530: both the 'inflow' snapshot as well as the 'incomeFromCapital' snapshot have 'volume' property which shows in the "last 12 months" bar chart twice
          // FIX -- 230530: removing the value from the 'incomeFromCapital' snapshot
          return returnArray;
        }),
      };

      // console.log('atomicDataWithSnapshotsReturnObject', atomicDataWithSnapshotsReturnObject);
      return atomicDataWithSnapshotsReturnObject;
    });
  },
);

const atomicDataWithSnapshotsDebounceWrapper = debounce((state) => atomicDataWithSnapshotsCore(state), 1000, { leading: true, trailing: true });
export const atomicDataWithSnapshots = (state) => atomicDataWithSnapshotsDebounceWrapper(state);

// this calculates the monthly snapshot of data aggregated on assetId level (atomicData)
// return this for snapshots
export const selectorDatascopeSnapshotsCore = createSelector(atomicDataWithSnapshots, function selectorDatascopeSnapshotsCallback($atomicDataWithSnapshots) {
  // console.log(dayjs().format('mm:ss.SSS'), 'selectorDatascopeSnapshotsCore: calculating...');
  const atomicDataWithSnapshotsReturnObject = ($atomicDataWithSnapshots || []).flatMap((item) => item.snapshots
    .filter((snapshot) => !!snapshot?.date)
    .map((snapshot) => ({
      ...snapshot,
      'Date (Month)': dayjs(snapshot.date).format('YYYY-MM'),
      'Date (Year)': dayjs(snapshot.date).format('YYYY'),
      category: item.category,
      accountId: item.accountId,
      accountName: item.accountName,
      assetId: item.assetId,
      displayName: item.displayName,
    })));

  // console.info('selectorDatascopeSnapshots', JSON.stringify(atomicDataWithSnapshotsReturnObject, null, 2));

  // send this from a debounced selector to let ChartWrapper know that it should refresh the data
  window.dispatchEvent(new CustomEvent('reportingDebouncingFinished'));

  return {
    data: atomicDataWithSnapshotsReturnObject,
    derivedAttributes: {},
    hiddenAttributes: ['accountId', 'figi', 'roiAnnualData', 'formattingLevel'],
  };
});

const selectorDatascopeSnapshotsDebounceWrapper = debounce((state) => selectorDatascopeSnapshotsCore(state), 1000, { leading: true, trailing: true });
export const selectorDatascopeSnapshots = (state) => selectorDatascopeSnapshotsDebounceWrapper(state);

// ----------------------------------------------------------------------------
// ACCOUNT LEVEL DATA
// Aggregate asset-level records to account level
// ----------------------------------------------------------------------------

const accountLevelData = createSelector(atomicData, function accountLevelDataCallback($atomicData) {
  const returnArray = $atomicData.reduce((acc, item) => {
    // see if there is already an entry for this category and account combination
    const previousRecord = acc.findIndex((x) => x.category === item.category && x.accountId === item.accountId);
    // insert data
    if (previousRecord === -1) {
      // insert new record
      acc.push({
        category: item.category,
        accountId: item.accountId,
        accountName: item.accountName,
        date: new Date().valueOf(),
        displayName: 'Σ',
        purchaseValue: item.purchaseValue,
        currentValue: item.currentValue,
        payoutsSincePurchase: item.payoutsSincePurchase,
        feesSincePurchase: item.feesSincePurchase,
        roiAnnualProduct: item.roiAnnual * item.currentValue,
        roiAnnualDenominator: item.currentValue,
        formattingLevel: 1,
      });
    } else {
      // update existing record
      const i = acc[previousRecord];
      i.purchaseValue += item.purchaseValue;
      i.currentValue += item.currentValue;
      i.payoutsSincePurchase += item.payoutsSincePurchase;
      i.feesSincePurchase += item.feesSincePurchase;
      i.roiAnnualProduct += item.roiAnnual * item.currentValue;
      i.roiAnnualDenominator += item.currentValue;
    }
    // apply some more formatting
    const i = acc[previousRecord === -1 ? acc.length - 1 : previousRecord];
    // this  will happen several times, but only the last time will be shown
    i.roi = item.category === 'deposits' // this has to be adjusted in Asset- and Category-level further down too
      ? (i.payoutsSincePurchase + i.feesSincePurchase) / i.currentValue
      : (i.currentValue + i.payoutsSincePurchase + i.feesSincePurchase - i.purchaseValue) / i.purchaseValue;
    // calculate annualised ROI as weighted average of assets by current value
    i.roiAnnual = i.roiAnnualProduct / i.roiAnnualDenominator;

    // return the whole array back at the end
    // console.log('accountLevelData', acc);
    return acc;
  }, []);
  // console.log('accountLevelData', returnArray);
  return returnArray;
});

// ----------------------------------------------------------------------------
// CATEGORY-LEVEL DATA
// Aggregate account-level records to category level
// ----------------------------------------------------------------------------

const categoryLevelData = createSelector(accountLevelData, function categoryLevelDataCallback($accountLevelData) {
  return $accountLevelData.reduce((acc, item) => {
    // see if there is already an entry for this category
    const previousRecord = acc.findIndex((x) => x.category === item.category);
    // start entry for this category
    if (previousRecord === -1) {
      acc.push({
        category: item.category,
        accountName: 'Σ',
        date: new Date().valueOf(),
        displayName: 'Σ',
        purchaseValue: item.purchaseValue,
        currentValue: item.currentValue,
        payoutsSincePurchase: item.payoutsSincePurchase,
        feesSincePurchase: item.feesSincePurchase,
        roiAnnualProduct: item.roiAnnual * item.currentValue,
        roiAnnualDenominator: item.currentValue,
        formattingLevel: 0,
      });
    } else {
      // add current account's data to existing category
      const i = acc[previousRecord];
      i.purchaseValue += item.purchaseValue;
      i.currentValue += item.currentValue;
      i.payoutsSincePurchase += item.payoutsSincePurchase;
      i.feesSincePurchase += item.feesSincePurchase;
      i.roiAnnualProduct += item.roiAnnual * item.currentValue;
      i.roiAnnualDenominator += item.currentValue;
    }
    // apply some more formatting
    const j = acc[previousRecord === -1 ? acc.length - 1 : previousRecord];
    // this will happen several times, but only the last time will be shown
    j.roi = item.category === 'deposits' // this has to be adjusted in Asset- and Account-level further down too)
      ? (j.payoutsSincePurchase + j.feesSincePurchase) / j.currentValue
      : (j.currentValue + j.payoutsSincePurchase + j.feesSincePurchase - j.purchaseValue) / j.purchaseValue;
    // calculate annualised ROI as weighted average of assets by current value
    j.roiAnnual = j.roiAnnualProduct / j.roiAnnualDenominator;

    // return the whole array back at the end
    // console.log('categoryLevelData', acc);
    return acc;
  }, []);
});

// ----------------------------------------------------------------------------
// OUTPUT SELECTORS
// ----------------------------------------------------------------------------

export const selectorDatascopeAssets = createSelector(atomicData, accountLevelData, categoryLevelData, function selectorDatascopeAssetsCallback($atomicData, $accountLevelData, $categoryLevelData) {
  return {
    derivedAttributes: {},
    hiddenAttributes: ['accountId', 'figi', 'roiAnnualData', 'formattingLevel'],
    data: $atomicData
      .concat($accountLevelData)
      .concat($categoryLevelData)
      // add formatting (the List renderer is not able to do this)
      .map((x) => ({
        ...x,
        roi: x.roi.toLocaleString('de', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        roiAnnual: x.roiAnnual.toLocaleString('de', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      })),
  };
});

// take quotes and add display names for all assets (from assetBalancesArray)
export const selectorDatascopeQuotes = createSelector(selectors.globalQuotesView, selectors.assetBalancesArray, function selectorDatascopeQuotesCallback(quotes, aba) {
  return {
    derivedAttributes: {},
    hiddenAttributes: [],
    data: objectToArray(quotes, ['assetId', 'date']).map((item) => ({ ...item, displayName: aba.find((x) => x.assetId === item.assetId)?.displayName })),
  };
});

export default function getReportingSelector(state, requestedSelector) {
  switch (requestedSelector) {
    case 'assets':
      return selectorDatascopeAssets(state);
    case 'quotes':
      return selectorDatascopeQuotes(state);
    case 'snapshots':
      return selectorDatascopeSnapshots(state);
    case 'transactions':
      return selectorDatascopeTransactions(state);
    default:
      return { data: [], derivedAttributes: {}, hiddenAttributes: [] };
  }
}
