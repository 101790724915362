import { decompressFromUTF16 } from 'lz-string';
import CryptoJS from 'crypto-js';
import { loadSliceFromIndexedDB } from './indexedDBUtils'; // Import IndexedDB utility

// Function to load persisted slices from IndexedDB
const loadPersistedSlice = async (sliceName, idToken) => {
  // console.info(`Loading persisted slice: ${sliceName}`);
  const encryptedSlice = await loadSliceFromIndexedDB(sliceName);

  if (!encryptedSlice) return null;

  try {
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedSlice, idToken);
    const decrypted = decryptedBytes.toString(CryptoJS.enc.Utf8);
    const decompressed = decompressFromUTF16(decrypted);
    return JSON.parse(decompressed);
  } catch (error) {
    console.error(`Error decrypting or decompressing slice '${sliceName}'`, error);
    return null;
  }
};

export default loadPersistedSlice;
