import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  REGISTER_CONFIRMATION_SUCCESS,
  REGISTER_CONFIRMATION_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  UPDATE_TOURS_SUCCESS,
  UPDATE_TOURS_FAIL,
  UPDATE_COGNITO_SETTINGS_SUCCESS,
  UPDATE_COGNITO_SETTINGS_FAIL,
  SET_GLOBAL_TOUR_LOCK,
  CLEAR_GLOBAL_TOUR_LOCK,
  SET_MOBILE_APP_MODE,
  PAYMENT_ERROR,
  UPDATE_CONSENT,
} from '../actions/types';

const emptyConsent = {
  essential: null,
  analytics: null,
  externalMedia: null,
};

function getConsent() {
  const consent = localStorage.getItem('consent');
  if (consent) {
    return JSON.parse(consent);
  }
  return emptyConsent;
}

// this slice will be populated by the AUTHORIZATION_SUCCESS action
const initialState = {
  isLoggedIn: null,
  mobileAppMode: false,
  profile: {
    attributes: null,
    settings: null,
  },
  tourLock: null,
  payment: {
    status: 'idle',
  },
  consent: getConsent(),
  rehydrationStatus: 'notStarted', // other values: 'inProgress', 'finished'
};

// eslint-disable-next-line default-param-last
export default function rootReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        registration: payload,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_CONFIRMATION_SUCCESS:
      return {
        ...state,
        registration: {
          ...state.registration,
          status: payload.status,
        },
      };
    case REGISTER_CONFIRMATION_FAIL:
      return {
        ...state,
        registration: {
          ...state.registration,
          status: 'error',
        },
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        profile: {
          ...state.profile,
          attributes: { toursSeen: payload.toursSeen },
          settings: {
            ...state.profile.settings,
            ...payload.settings,
          },
        },
        registration: {}, // removes the registration object, as it is no longer necessary
        sub: payload.sub,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false, // reset store
        profile: {
          attributes: null,
          settings: null,
        },
        consent: { ...state.consent }, // keep consent
      };
    case LOGOUT:
      return {
        isLoggedIn: false, // reset store
        profile: {
          attributes: null,
          settings: null,
        },
        consent: { ...state.consent }, // keep consent
      };
    case SET_MOBILE_APP_MODE:
      return {
        ...state,
        mobileAppMode: true,
      };
    case UPDATE_COGNITO_SETTINGS_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          settings: {
            ...state.profile.settings,
            ...payload.settings,
          },
          attributes: {
            ...state.profile.attributes,
            ...payload.attributes,
          },
        },
      };
    case UPDATE_COGNITO_SETTINGS_FAIL:
      return {
        ...state,
      };
    // action is going to update the tours seen in Cognito and send the updated object as payload
    case UPDATE_TOURS_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          attributes: {
            ...state.profile.attributes,
            toursSeen: payload,
          },
        },
      };
    case UPDATE_TOURS_FAIL:
      return {
        ...state,
      };
    case SET_GLOBAL_TOUR_LOCK:
      return {
        ...state,
        tourLock: true,
      };
    case CLEAR_GLOBAL_TOUR_LOCK:
      return {
        ...state,
        tourLock: null,
      };
    case PAYMENT_ERROR:
      return {
        ...state,
        payment: {
          ...state.payment,
          status: 'error',
        },
      };
    case UPDATE_CONSENT:
      return {
        ...state,
        consent: {
          ...payload,
        },
      };
    case 'viewer/getSharedReport/fulfilled':
      return {
        ...state,
        profile: {
          ...state.profile,
          settings: {
            ...state.profile.settings,
            baseCurrency: payload.baseCurrency,
          },
        },
      };
    case 'SET_REHYDRATION_STATUS':
      return {
        ...state,
        rehydrationStatus: payload,
      };
    default:
      return state;
  }
}
